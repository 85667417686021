import React from "react";
import ErrorText from "../Form/ErrorText";

const InputRequired = ({
  label,
  id,
  placeholder,
  type,
  name,
  value,
  labelStyle,
  onChange,
  onBlur,
  error,
  touch,
  disabled,
}) => {
  return (
    <div className="flex-grow basis-48">
      <label
        htmlFor={id}
        className={`block mb-1 pl-[2px] font-medium text-[14px] capitalize ${labelStyle}`}
      >
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={id}
        value={value}
        className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg focus:border-gray-800 block w-full p-2.5 "
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      {error && touch ? <ErrorText errorText={error} /> : null}
    </div>
  );
};

export default InputRequired;
