import React from "react";

const ButtonLarge = ({ text, customClasses, type, onClick }) => {
  return (
    <button
      type={type ? type : "submit"}
      onClick={onClick}
      className={`${
        customClasses ? customClasses : "bg-[--primary]  text-white"
      }
        w-full py-3  border border-[--primary] font-semibold rounded-lg`}
    >
      {text}
    </button>
  );
};

export default ButtonLarge;
