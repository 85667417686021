import React from "react";

const MediaCard = ({ customClasses, children }) => {
  return (
    <div className={`w-[245px] bg-white rounded-md ${customClasses}`}>
      {children}
    </div>
  );
};

export default MediaCard;
