import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderData from "../../components/Header/HeaderData";
import Loader from "../../components/Loading/Loader";
import { useSelector } from "react-redux";
import SmartPlanCard from "../../components/Card/SmartPlanCard";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import RowWrapper from "../../components/Wrapper/RowWrapper";
import ButtonSmall from "../../components/Buttons/ButtonSmall";

const SmartPlanDetail = () => {
  const { id } = useParams();
  const smartPlanData = useSelector((state) => state.smartPlan.data);
  const [selectedSmartPlan, setSelectedSmartPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setSelectedSmartPlan(smartPlanData.find((item) => item._id === id));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <HeaderData text="Smart Plan Detail">
        <ButtonSmall
          onClick={() => {
            navigate("/smart-plan-all");
          }}
          text="Go Back"
          type="button"
        />
      </HeaderData>
      {loading ? (
        <Loader />
      ) : (
        <>
          {selectedSmartPlan ? (
            <>
              <h5 className="mt-5 text-[18px] satoshi-700">
                {selectedSmartPlan?.name}
              </h5>
              <p className="mt-2 text-[14px] md:text-[16px] xl:text-[18px]">
                {selectedSmartPlan?.description}
              </p>
              <CardWrapper extraClasses={"!items-stretch"}>
                {selectedSmartPlan?.income && (
                  <SmartPlanCard headerText={"Personal Income"}>
                    <RowWrapper
                      title="Company Name "
                      value={selectedSmartPlan?.income?.companyName}
                    />
                    <RowWrapper
                      title="Income Amount"
                      value={`$ ${selectedSmartPlan?.income?.income}`}
                    />
                    {selectedSmartPlan?.income?.additionalIncome && (
                      <>
                        <h4 className="satoshi-700 my-2">Additional Income</h4>
                        <RowWrapper
                          title="Income Type "
                          value={
                            selectedSmartPlan?.income?.additionalIncome?.type
                          }
                        />
                        <RowWrapper
                          title="Business Type "
                          value={
                            selectedSmartPlan?.income?.additionalIncome
                              ?.typeOfBusiness
                          }
                        />

                        <RowWrapper
                          title="Industry "
                          value={
                            selectedSmartPlan?.income?.additionalIncome
                              ?.industry
                          }
                        />
                        <RowWrapper
                          title="Monthly Income "
                          value={`$ ${selectedSmartPlan?.income?.additionalIncome?.monthlyIncome}`}
                        />
                      </>
                    )}
                  </SmartPlanCard>
                )}
                {selectedSmartPlan?.financeShared && (
                  <SmartPlanCard headerText={"Spouse Information"}>
                    <RowWrapper
                      title="Gender "
                      value={selectedSmartPlan?.spouse?.gender}
                    />
                    <RowWrapper
                      title="Retirement Age"
                      value={`${selectedSmartPlan?.spouse?.retirementAge} years`}
                    />

                    <RowWrapper
                      title="Birth Month "
                      value={selectedSmartPlan?.spouse?.dob?.month}
                    />
                    <RowWrapper
                      title="Birth Year "
                      value={selectedSmartPlan?.spouse?.dob?.year}
                    />
                  </SmartPlanCard>
                )}

                {selectedSmartPlan?.spouseIncome && (
                  <SmartPlanCard headerText={"Spouse Income"}>
                    <RowWrapper
                      title="Company Name "
                      value={selectedSmartPlan?.spouseIncome?.companyName}
                    />
                    <RowWrapper
                      title="Income Amount"
                      value={`$ ${selectedSmartPlan?.spouseIncome?.income}`}
                    />
                    {selectedSmartPlan?.spouseIncome?.additionalIncome && (
                      <>
                        <h4 className="satoshi-700 my-2">Additional Income</h4>
                        <RowWrapper
                          title="Income Type "
                          value={
                            selectedSmartPlan?.spouseIncome?.additionalIncome
                              ?.type
                          }
                        />
                        <RowWrapper
                          title="Business Type "
                          value={
                            selectedSmartPlan?.spouseIncome?.additionalIncome
                              ?.typeOfBusiness
                          }
                        />

                        <RowWrapper
                          title="Industry "
                          value={
                            selectedSmartPlan?.spouseIncome?.additionalIncome
                              ?.industry
                          }
                        />
                        <RowWrapper
                          title="Monthly Income "
                          value={`$ ${selectedSmartPlan?.spouseIncome?.additionalIncome?.monthlyIncome}`}
                        />
                      </>
                    )}
                  </SmartPlanCard>
                )}

                {selectedSmartPlan?.dependentsInfp?.doHave ? (
                  <SmartPlanCard headerText={"Dependents Information"}>
                    {selectedSmartPlan?.dependentsInfp?.dependents.map(
                      (item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className={
                                selectedSmartPlan?.dependentsInfp?.dependents
                                  .length > 1
                                  ? "w-full pb-1 border-b"
                                  : "w-full"
                              }
                            >
                              <RowWrapper title="Name" value={item?.name} />
                              <RowWrapper
                                title="Age"
                                value={`${item?.age} years`}
                              />
                            </div>
                          </>
                        );
                      }
                    )}
                    {selectedSmartPlan?.dependentsInfp?.custodialAccountInfo
                      .length ? (
                      <>
                        <h4 className="satoshi-700 my-2">Custodial Account</h4>
                        {selectedSmartPlan?.dependentsInfp?.custodialAccountInfo.map(
                          (item, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className={
                                    selectedSmartPlan?.dependentsInfp
                                      ?.custodialAccountInfo.length > 1
                                      ? "w-full pb-1 border-b"
                                      : "w-full"
                                  }
                                >
                                  <RowWrapper
                                    title="Institution Name"
                                    value={item?.Institution}
                                  />
                                  <RowWrapper
                                    title="Account Number"
                                    value={`$ ${item?.Amount}`}
                                  />
                                </div>
                              </>
                            );
                          }
                        )}
                      </>
                    ) : null}
                  </SmartPlanCard>
                ) : null}

                {selectedSmartPlan?.Home && (
                  <SmartPlanCard headerText={"Home Information"}>
                    <RowWrapper
                      title="Home Type"
                      value={selectedSmartPlan?.Home?.type}
                    />
                    {selectedSmartPlan?.Home?.type === "rent" && (
                      <RowWrapper
                        title="Rent"
                        value={`$ ${selectedSmartPlan?.Home?.rent}`}
                      />
                    )}

                    {selectedSmartPlan?.Home?.mortgageDetails && (
                      <>
                        <RowWrapper
                          title="Home Value"
                          value={`$ ${selectedSmartPlan?.Home?.mortgageDetails?.homeValue}`}
                        />

                        <RowWrapper
                          title="Loan Balance"
                          value={`$ ${selectedSmartPlan?.Home?.mortgageDetails?.loanBalance}`}
                        />
                        <RowWrapper
                          title="Monthly Payment"
                          value={`$ ${selectedSmartPlan?.Home?.mortgageDetails?.monthlyPayment}`}
                        />
                        <RowWrapper
                          title="Interest Rate"
                          value={
                            selectedSmartPlan?.Home?.mortgageDetails
                              ?.interestRate
                          }
                        />
                      </>
                    )}
                  </SmartPlanCard>
                )}

                {selectedSmartPlan?.debts.length ? (
                  <SmartPlanCard headerText={"Debt Information"}>
                    {selectedSmartPlan?.debts.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            selectedSmartPlan?.debts.length > 1
                              ? "w-full pb-1 border-b"
                              : "w-full"
                          }
                        >
                          <RowWrapper
                            title="Debt Type"
                            value={item?.debtType}
                          />

                          <RowWrapper
                            title="Institution Name"
                            value={item?.institution}
                          />
                          <RowWrapper
                            title="Debt Balance"
                            value={`$ ${item?.balance}`}
                          />
                          <RowWrapper
                            title="Monthly Payment"
                            value={`$ ${item?.monthlyPayment}`}
                          />
                          <RowWrapper title="Debt Rate" value={item?.rate} />
                        </div>
                      );
                    })}

                    <RowWrapper
                      title="Total Debt"
                      value={`$ ${selectedSmartPlan?.totalDebt}`}
                    />
                    <RowWrapper
                      title="Debt Standing "
                      value={selectedSmartPlan?.debtStanding}
                    />
                  </SmartPlanCard>
                ) : null}

                {selectedSmartPlan?.accounts.length ? (
                  <SmartPlanCard headerText={"Accounts Information"}>
                    {selectedSmartPlan?.accounts.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            selectedSmartPlan?.accounts.length > 1
                              ? "w-full pb-1 border-b"
                              : "w-full"
                          }
                        >
                          <RowWrapper title="Account Type" value={item?.type} />

                          <RowWrapper
                            title="Institution Name"
                            value={item?.institution}
                          />
                          <RowWrapper
                            title="Account Balance"
                            value={`$ ${item?.balance}`}
                          />
                        </div>
                      );
                    })}
                  </SmartPlanCard>
                ) : null}

                {selectedSmartPlan?.property.length ? (
                  <SmartPlanCard headerText={"Assets Information"}>
                    {selectedSmartPlan?.property.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            selectedSmartPlan?.property.length > 1
                              ? "w-full pb-1 border-b"
                              : "w-full"
                          }
                        >
                          <RowWrapper title="Asset Name" value={item?.name} />

                          <RowWrapper
                            title="Asset Value"
                            value={`$ ${item?.value}`}
                          />
                        </div>
                      );
                    })}
                  </SmartPlanCard>
                ) : null}

                <SmartPlanCard headerText={"Other Information"}>
                  <RowWrapper
                    title="Last Will "
                    value={
                      selectedSmartPlan?.questions?.lastWill ? "Yes" : "No"
                    }
                  />

                  <RowWrapper
                    title="Living Trust "
                    value={
                      selectedSmartPlan?.questions?.livingTrust ? "Yes" : "No"
                    }
                  />

                  <RowWrapper
                    title="Health Care Power Of Attorney "
                    value={
                      selectedSmartPlan?.questions?.healthCarePowerOfAttorney
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Financial Power Of Attorney "
                    value={
                      selectedSmartPlan?.questions?.financialPowerOfAttorney
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Documents Organized "
                    value={
                      selectedSmartPlan?.questions?.documentsOrganized
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Identity Theft Protection "
                    value={
                      selectedSmartPlan?.questions?.identityTheftProtection
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Debt Free "
                    value={
                      selectedSmartPlan?.questions?.onTrackDebtFree
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Budgeting And Debt-Payoff Tool "
                    value={
                      selectedSmartPlan?.questions?.budgetingAndDebtPayoffTool
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Prepared To Manage Finances "
                    value={
                      selectedSmartPlan?.questions?.preparedToManageFinances
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Interested In Education And Services "
                    value={
                      selectedSmartPlan?.questions
                        ?.interestedInEducationAndServices
                        ? "Yes"
                        : "No"
                    }
                  />

                  <RowWrapper
                    title="Reporting Rent Payments "
                    value={
                      selectedSmartPlan?.questions?.reportingRentPayments
                        ? "Yes"
                        : "No"
                    }
                  />
                </SmartPlanCard>
              </CardWrapper>
            </>
          ) : (
            <p>No smart plan found</p>
          )}

          <div className="w-full flex justify-center items-center">
            <ButtonSmall
              onClick={() => {
                navigate("/smart-plan-all");
              }}
              text="Go Back"
              type="button"
            />
          </div>
        </>
      )}
    </>
  );
};

export default SmartPlanDetail;
