import React, { useEffect, useState } from "react";
import Loader from "../../components/Loading/Loader";
import {
  calculate,
  findIndexOfMaxMonthsToPayOff,
  totalInterest,
} from "../../Utils";
import ButtonSmall from "../../components/Buttons/ButtonSmall";
import ErrorText from "../../components/Form/ErrorText";
import { useFormik } from "formik";
import { debtPlanSchema } from "../../Schema";
import { useDispatch, useSelector } from "react-redux";
import {
  addDeptPlan,
  getDebptPlan,
  getDebptPlanResult,
} from "../../Redux/Actions";
import { isEmpty } from "lodash";
import UnavailableService from "../../components/Message/UnavailableService";
import useHasPermission from "../../Utils/permissionUtils";
import TextModal from "../../components/Modal/TextModal";

const inpuClasses =
  "satoshi-500 text-[12px] sm:text-[14px] lg:text-[16px] border border-[--primary] rounded-md py-1 px-1 w-[90%] outline-none";

const textClasses =
  " py-2 text-[12px] sm:text-[14px] md:text-[16px] xl:text-[17px]";

let count = 1;
let Once = true;

const DebtPlan = () => {
  const hasPermission = useHasPermission("Debts");
  const hasPermissionToCreate = useHasPermission("Add new debts");
  const [loading, setLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [initialSnowBall, setInitialSnowBall] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const dispatch = useDispatch();
  const { data, isInvoked } = useSelector((state) => state.debtPlan);
  const debtPlanResult = useSelector((state) => state.debtResult.data);
  const [filterOption, setFilterOption] = useState("");
  const [textModal, setTextModal] = useState(false);

  const initialState = {
    monthlyBudget: "",
    data: [
      {
        creditor: `Creditor ${count}`,
        balance: "",
        rate: "",
        payment: "",
        custom: "",
      },
    ],
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: debtPlanSchema,
    onSubmit: (values) => {
      if (hasPermissionToCreate) {
        setLoading(true);
        if (values.monthlyBudget < totalPayment) {
          formik.setErrors({
            monthlyBudget: "Monthly budget must be greater than total payment",
          });
          setLoading(false);
          return;
        }

        dispatch(addDeptPlan(values)).then(() => {
          setLoading(false);
        });
      } else {
        setTextModal(true);
      }
    },
  });

  function handleInputChange(e, index) {
    const { value, name } = e.target;
    let updatedData = formik.values.data.map((item, i) => {
      if (name === "creditor") {
        return i === index ? { ...item, [name]: value } : item;
      } else {
        return i === index ? { ...item, [name]: parseFloat(value) } : item;
      }
    });
    formik.setValues({ ...formik.values, data: updatedData });
    setTotalBalance(calculate("balance", updatedData));
    setTotalPayment(calculate("payment", updatedData));
  }

  function handleDeleteRow(dataIndex) {
    if (formik.values.data.length > 1) {
      let updatedData = formik.values.data.filter(
        (item, index) => index !== dataIndex
      );
      formik.setValues({ ...formik.values, data: updatedData });
      setTotalBalance(calculate("balance", updatedData));
      setTotalPayment(calculate("payment", updatedData));
    }
  }

  function handleInsertRow() {
    count = count + 1;
    formik.setValues({
      ...formik.values,
      data: [
        ...formik.values.data,
        {
          creditor: `creditor ${count}`,
          balance: "",
          rate: "",
          payment: "",
          custom: "",
        },
      ],
    });
  }

  useEffect(() => {
    setLoading(true);
    dispatch(getDebptPlanResult(filterOption)).then(() => {
      setLoading(false);
    });
  }, [filterOption]);

  useEffect(() => {
    setInitialSnowBall(formik.values.monthlyBudget - totalPayment);
  }, [formik.values.monthlyBudget, totalPayment]);

  useEffect(() => {
    if (data.planData.length) {
      formik.setValues({
        monthlyBudget: data?.monthlyBudget || 0,
        data: data?.planData,
      });
      setTotalBalance(calculate("balance", data?.planData));
      setTotalPayment(calculate("payment", data?.planData));
    }
  }, [data.planData]);

  useEffect(() => {
    if (Once) {
      Once = false;
      if (!isInvoked) {
        setPageLoader(true);
        dispatch(getDebptPlan())
          .then((res) => {
            setPageLoader(false);
            if (res.planData.length) {
              if (isEmpty(debtPlanResult)) {
                setLoading(true);
                dispatch(getDebptPlanResult()).then(() => {
                  setLoading(false);
                });
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, []);

  return (
    <>
      {textModal && (
        <TextModal
          open={textModal}
          isPermission={true}
          handleClose={() => {
            setTextModal(false);
          }}
        />
      )}
      {pageLoader ? (
        <Loader />
      ) : hasPermission ? (
        <>
          <div className="w-full mt-4 bg-white">
            <div className="dashboardTableWrapper">
              <table className="w-full min-w-[920px] border-collapse !overflow-x-auto">
                <thead className="border-t border-b">
                  <tr className="bg-[--primary]">
                    <th style={thStyle} className={`!pl-5 ${textClasses}`}>
                      Name
                    </th>
                    <th style={thStyle} className={textClasses}>
                      Balance
                    </th>
                    <th style={thStyle} className={textClasses}>
                      Rate
                    </th>
                    <th style={thStyle} className={textClasses}>
                      Payment
                    </th>
                    <th style={thStyle} className={textClasses}>
                      Custom
                    </th>
                    <th style={thStyle} className={textClasses}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.data.map((item, index) => (
                    <tr className="satoshi-500 tableRow" key={index}>
                      <td className="pl-2 min-w-[100px] satoshi-700 py-2">
                        <input
                          value={item.creditor}
                          name={`creditor`}
                          type="text"
                          onBlur={formik.handleBlur}
                          placeholder="Name"
                          onChange={(e) => {
                            handleInputChange(e, index);
                          }}
                          className={inpuClasses}
                        />
                        {formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].creditor &&
                        formik.errors.data &&
                        formik.errors.data[index] &&
                        formik.errors.data[index].creditor ? (
                          <ErrorText
                            errorText={formik.errors.data[index].creditor}
                          />
                        ) : (
                          <ErrorText errorText={null} />
                        )}
                      </td>
                      <td style={tdStyle}>
                        <input
                          value={item.balance}
                          name={`balance`}
                          type="number"
                          placeholder="0"
                          onChange={(e) => {
                            handleInputChange(e, index);
                          }}
                          className={inpuClasses}
                        />
                        {formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].balance &&
                        formik.errors.data &&
                        formik.errors.data[index] &&
                        formik.errors.data[index].balance ? (
                          <ErrorText
                            errorText={formik.errors.data[index].balance}
                          />
                        ) : (
                          <ErrorText errorText={null} />
                        )}
                      </td>
                      <td style={tdStyle}>
                        <input
                          type="number"
                          value={item.rate}
                          name={`rate`}
                          placeholder="0"
                          onChange={(e) => {
                            handleInputChange(e, index);
                          }}
                          className={inpuClasses}
                        />
                        {formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].rate &&
                        formik.errors.data &&
                        formik.errors.data[index] &&
                        formik.errors.data[index].rate ? (
                          <ErrorText
                            errorText={formik.errors.data[index].rate}
                          />
                        ) : (
                          <ErrorText errorText={null} />
                        )}
                      </td>
                      <td style={tdStyle}>
                        <input
                          type="number"
                          value={item.payment}
                          name={`payment`}
                          placeholder="0"
                          onChange={(e) => {
                            handleInputChange(e, index);
                          }}
                          className={inpuClasses}
                        />
                        {formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].payment &&
                        formik.errors.data &&
                        formik.errors.data[index] &&
                        formik.errors.data[index].payment ? (
                          <ErrorText
                            errorText={formik.errors.data[index].payment}
                          />
                        ) : (
                          <ErrorText errorText={null} />
                        )}
                      </td>
                      <td style={tdStyle}>
                        <input
                          type="number"
                          value={item.custom}
                          name={`custom`}
                          placeholder="0"
                          onChange={(e) => {
                            if (formik.values.data.length > 1) {
                              let unique = true;
                              formik.values.data.forEach((item, idx) => {
                                if (
                                  idx !== index &&
                                  parseInt(item.custom) ===
                                    parseInt(e.target.value)
                                ) {
                                  unique = false;
                                }
                              });
                              if (unique) {
                                handleInputChange(e, index);
                              } else {
                                return;
                              }
                            } else {
                              handleInputChange(e, index);
                            }
                          }}
                          className={inpuClasses}
                        />
                        {formik.touched.data &&
                        formik.touched.data[index] &&
                        formik.touched.data[index].custom &&
                        formik.errors.data &&
                        formik.errors.data[index] &&
                        formik.errors.data[index].custom ? (
                          <ErrorText
                            errorText={formik.errors.data[index].custom}
                          />
                        ) : (
                          <ErrorText errorText={""} />
                        )}
                      </td>
                      <td style={tdStyle}>
                        <button
                          onClick={() => {
                            handleDeleteRow(index);
                          }}
                          className="text-white text-sm py-2 px-4 bg-red-700 rounded-md"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="border-t-2 border-[--primary]">
                    <td
                      style={tdStyle}
                      className="text-[18px] bg-[--primary] text-white !pl-5"
                    >
                      Total
                    </td>
                    <td className="text-center satoshi-700 text-[--primary]">
                      $ {totalBalance}
                    </td>
                    <td></td>
                    <td className="text-center satoshi-700 text-[--primary]">
                      $ {totalPayment}
                    </td>
                    <td></td>
                    <td>
                      <button
                        onClick={handleInsertRow}
                        className="text-white text-sm py-2 px-2 bg-[--primary] rounded-md"
                      >
                        Add Row
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>

          <div className="w-full px-3 py-5">
            <div className="w-full  py-5 flex justify-between items-center gap-8 flex-wrap">
              <div className="flex-grow basis-64 flex flex-col justify-start items-stretch">
                <div className="w-full flex justify-between items-center">
                  <div className="min-w-[140px]  md:min-w-[150px] lg:min-w-[200px]">
                    <p className="satoshi-700 text-[14px] sm:text-[16px]">
                      Monthly Payment :
                    </p>
                  </div>
                  <input
                    name="monthlyBudget"
                    type="number"
                    placeholder="0"
                    value={formik.values?.monthlyBudget}
                    onChange={formik.handleChange}
                    className={inpuClasses}
                  />
                </div>
                {formik.touched.monthlyBudget && formik.errors.monthlyBudget ? (
                  <ErrorText errorText={formik.errors.monthlyBudget} />
                ) : null}
              </div>

              <div className="flex-grow basis-48 flex justify-between items-center">
                <div className="min-w-[110px] sm:min-w-[140px] md:min-w-[150px] lg:min-w-[200px]">
                  <p className="satoshi-700 text-[14px] sm:text-[16px]">
                    Initial Snowball :
                  </p>
                </div>
                <div>
                  <p className="satoshi-700 text-[14px] md:text-[16px] min-w-max">
                    $ {initialSnowBall}
                  </p>
                </div>
              </div>
            </div>
            {debtPlanResult.length ? (
              <div className="flex justify-between items-center w-full sm:w-[53%] gap-1 sm:gap-5 md:gap-8">
                <div className="min-w-[80px]  md:min-w-[130px] lg:min-w-[150px]">
                  <p className="satoshi-700 text-[14px] sm:text-[16px]">
                    Strategy :{" "}
                  </p>
                </div>
                <select
                  value={filterOption}
                  onChange={(e) => setFilterOption(e.target.value)}
                  className="w-full sm:w-[70%] border border-[--primary] rounded-md px-2 py-1 outline-none"
                >
                  {/* <option value="lowestSnow">
                      Snowball (Lowest balance first)
                    </option> */}
                  <option value="">Default</option>
                  <option value="Avalanche">
                    Avalanche (Highest Interest first)
                  </option>

                  {/* <option value="nosnowball">No Snowball</option> */}
                  {/* <option value="highestCustom">Custome (Highest first)</option>
                  <option value="lowestCustom">Custom (Lowest first)</option> */}
                </select>
              </div>
            ) : null}
          </div>

          <div className="w-full pt-1 pb-2  mx-auto sm:w-[80%] md:w-[70%] lg:w-[50%] max-w-[500px] flex justify-center items-center">
            <ButtonSmall
              text="Save"
              type="button"
              onClick={formik.handleSubmit}
              customClasses={
                "!w-1/2 !bg-white !text-[--primary] !border-[--primary]"
              }
            />
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {debtPlanResult.length ? (
                <div className="w-full bg-white mt-4 pb-3">
                  <div className="dashboardTableWrapper">
                    <table className="w-full min-w-[600px] border-collapse !overflow-x-auto">
                      <thead className="border-t border-b">
                        <tr className="bg-[--primary]">
                          <th
                            style={thStyle}
                            className="!pl-5 text-[12px] lg:text-[16px]"
                          >
                            Creditors
                          </th>
                          <th
                            style={thStyle}
                            className="text-[12px] lg:text-[16px]"
                          >
                            Origianl Balance
                          </th>
                          <th
                            style={thStyle}
                            className="text-[12px] lg:text-[16px]"
                          >
                            Interest Paid
                          </th>
                          <th
                            style={thStyle}
                            className="text-[12px] lg:text-[16px]"
                          >
                            Months To Pay Off
                          </th>
                          <th
                            style={thStyle}
                            className="text-[12px] lg:text-[16px]"
                          >
                            Month Paid Off
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {debtPlanResult.map((item, index) => {
                          return (
                            <tr key={index} className="satoshi-500 tableRow">
                              <td
                                className={`pl-2 min-w-[100px] capitalize ${textClasses}`}
                              >
                                {item.name}
                              </td>
                              <td className="pl-3 py-2 text-[12px] sm:text-[14px] md:text-[16px] xl:text-[17px]">
                                {item.balance}
                              </td>
                              <td className="pl-3 py-2 text-[12px] sm:text-[14px] md:text-[16px] xl:text-[17px]">
                                {item.interestpaid}
                              </td>
                              <td className="pl-3 py-2 text-[12px] sm:text-[14px] md:text-[16px] xl:text-[17px]">
                                {item.monthsToPayOff}
                              </td>
                              <td className="pl-3 py-2 text-[12px] sm:text-[14px] md:text-[16px] xl:text-[17px]">
                                {item.monthPaidOff}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr className="border-t-2 border-[--primary]">
                          <td></td>
                          <td className="text-left satoshi-700 text-[--primary] pt-3 text-[14px] lg:text-[16px]">
                            Total Interest Paid:
                          </td>
                          <td className="text-left satoshi-700 text-[--primary] pt-3 text-[14px] lg:text-[16px]">
                            $ {totalInterest(debtPlanResult)?.toFixed(2)}
                          </td>
                          <td className="text-left satoshi-700 text-[--primary] pt-3 text-[14px] lg:text-[16px]">
                            Debt Free:
                          </td>
                          <td className="text-left satoshi-700 text-[--primary] pt-3 text-[14px] lg:text-[16px]">
                            {
                              debtPlanResult[
                                findIndexOfMaxMonthsToPayOff(debtPlanResult)
                              ].monthPaidOff
                            }
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              ) : null}
            </>
          )}
        </>
      ) : (
        <UnavailableService />
      )}
    </>
  );
};

export default DebtPlan;

const thStyle = {
  textAlign: "start",
  padding: "12px 3px",
  color: "white",
};

const tdStyle = {
  padding: "8px 2px",
};
