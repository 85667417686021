import React, { useContext, useEffect, useState } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
// import TypoGray from "./TypoGray";
import InputWrapper from "../Wrapper/InputWrapper";
import CheckBoxLarge from "../Input/CheckBoxLarge";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import FooterButtons from "./FooterButtons";
import stepImage from "../../Assets/sm-12.png";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import FormWrapper from "./FormWrapper";
import Errortxt from "./Errortxt";

const Step12 = ({ handleNext, handleBack, direction }) => {
  const { step11Data, step12Data, setStep12Data } =
    useContext(SmartPlanContext);
  const [error, setError] = useState(false);
  function handleChange(e) {
    setError(false);
    setStep12Data(e.target.value);
  }

  function submitStep() {
    if (!step12Data) {
      setError(true);
      return;
    }

    handleNext();
  }

  useEffect(() => {
    if (step11Data === "own") return;
    else {
      if (direction === "next") handleNext();
      else handleBack();
    }
  }, []);

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Do you have mortgage on the home?" />
        <div className="py-4">
          <InputWrapper customClass={"!gap-6"}>
            <CheckBoxLarge
              icon={<MdCancel />}
              iconStyle={"text-red-400"}
              text="No, I don't have"
              id={"no"}
              value="no"
              name="mortage"
              selected={step12Data === "no"}
              onChange={handleChange}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
            />
            <CheckBoxLarge
              icon={<FaCheckCircle />}
              iconStyle={"text-green-400"}
              text="Yes, I have "
              id={"yes"}
              value="yes"
              name="mortage"
              selected={step12Data === "yes"}
              onChange={handleChange}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
            />
          </InputWrapper>
          {error && <Errortxt />}

          <FooterButtons onNext={submitStep} onBack={handleBack} />
        </div>
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step12;
