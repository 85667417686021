import React, { useState } from "react";
import Loader from "../../components/Loading/Loader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchedule } from "../../Redux/Actions";
const Schedule = () => {
  let Once = true;
  const [loading, setLoading] = useState(false);
  let scheduleData = useSelector((state) => state.schedule.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Once) {
      Once = false;
      setLoading(true);
      dispatch(getSchedule()).then(() => {
        setLoading(false);
      });
    }
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : scheduleData.length ? (
        <div className="w-full mt-4 bg-white">
          <div className="dashboardTableWrapper">
            <table className="w-full min-w-[920px] border-collapse !overflow-x-auto">
              <thead className="border-t border-b">
                <tr className="bg-[--primary]">
                  <th className="dynamicTableDescription text-[12px] sm:text-[14px] md:text-[16px] xl:text-[17px]">
                    S.No
                  </th>
                  {Object.keys(scheduleData[0]).map((item) => {
                    return (
                      <th className="dynamicTableDescription text-[12px] sm:text-[14px] md:text-[16px] xl:text-[17px]">
                        {item
                          .split(",")
                          .map((word) => {
                            return (
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                            );
                          })
                          .join(" ")}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {scheduleData.map((rowData, rowIndex) => {
                  return (
                    <tr className="satoshi-500 tableRow" key={rowIndex}>
                      <td
                        style={tdStyle}
                        className="!pl-3 text-[12px] sm:text-[14px] md:text-[16px] xl:text-[17px]"
                      >
                        {rowIndex + 1}
                      </td>
                      {Object.keys(scheduleData[0]).map(
                        (columnKey, columnIndex) => {
                          return (
                            <td
                              style={tdStyle}
                              className="text-[12px] sm:text-[14px] md:text-[16px] xl:text-[17px]"
                              key={columnIndex}
                            >
                              {rowData[columnKey] ? rowData[columnKey] : "-"}
                            </td>
                          );
                        }
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Schedule;

const tdStyle = {
  padding: "8px 2px",
};
