import React, { useContext, useState } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import TypoGray from "./TypoGray";
import InputWrapper from "../Wrapper/InputWrapper";
import CheckBoxLarge from "../Input/CheckBoxLarge";
import FooterButtons from "./FooterButtons";
import stepImage from "../../Assets/sm-7.png";
import { FaSackDollar, FaHouseChimneyWindow } from "react-icons/fa6";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import FormWrapper from "./FormWrapper";
import Errortxt from "./Errortxt";

const Step11 = ({ handleNext, handleBack }) => {
  const { step11Data, setStep11Data } = useContext(SmartPlanContext);
  const [error, setError] = useState(false);

  function handleChange(e) {
    setError(false);
    setStep11Data(e.target.value);
  }

  function submitStep() {
    if (!step11Data) {
      setError(true);
      return;
    }

    handleNext();
  }

  function handleChange(e) {
    setStep11Data(e.target.value);
  }
  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Do you rent or own your home?" />
        <TypoGray
          customClasses={"max-w-[80%]  md:text-[16px]"}
          text="Tell us about your primary residence."
        />

        <div className="py-4">
          <InputWrapper customClass={"!gap-6"}>
            <CheckBoxLarge
              id={"own"}
              value="own"
              name="hometype"
              selected={step11Data === "own"}
              onChange={handleChange}
              icon={<FaHouseChimneyWindow />}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
              text="I own my house"
            />
            <CheckBoxLarge
              icon={<FaSackDollar />}
              id={"rent"}
              value="rent"
              name="hometype"
              selected={step11Data === "rent"}
              onChange={handleChange}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
              text="I rent"
            />
          </InputWrapper>
          {error && <Errortxt />}

          <FooterButtons onNext={submitStep} onBack={handleBack} />
        </div>
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step11;
