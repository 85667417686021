import React, { useContext } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import FooterButtons from "./FooterButtons";
import stepImage from "../../Assets/sm-7.png";
import SelectBox from "../Input/SelectBox";
import InputRequired from "../Input/InputRequired";
import ButtonIcon from "../Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import CheckBox from "../Input/CheckBox";
import TypoGray from "./TypoGray";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import { assetTypes } from "../../Data/InputData";
import FormWrapper from "./FormWrapper";
import { useFormik } from "formik";
import { step18Schema } from "../../Schema";

const Step18 = ({ handleNext, handleBack }) => {
  const { step18Data, setStep18Data } = useContext(SmartPlanContext);
  const initialState = step18Data;

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: step18Schema,
    onSubmit: (values) => {
      setStep18Data(values);
      handleNext();
    },
  });

  const appendField = () => {
    formik.setValues([
      ...formik.values,
      {
        assetType: null,
        assetName: null,
        assetValue: null,
        assetInsurrance: false,
      },
    ]);
  };

  const removeInput = (targetIndex) => {
    const updatedValues = formik.values.filter(
      (_, index) => index !== targetIndex
    );
    formik.setValues(updatedValues);
  };

  function handleChange(e, index) {
    const { value, name } = e.target;
    if (name === "assetInsurrance") {
      let updatedData = formik.values.map((item, i) => {
        return i === index
          ? { ...item, assetInsurrance: value === "on" ? true : false }
          : item;
      });
      formik.setValues(updatedData);
    } else {
      let updatedData = formik.values.map((item, i) => {
        return i === index ? { ...item, [name]: value } : item;
      });
      formik.setValues(updatedData);
    }
  }

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Do you Own Properties other than Your Primary Home?" />
        <TypoGray
          customClasses={"max-w-[80%]  md:text-[16px] !uppercase"}
          text="LET’S TALK ABOUT property information."
        />
        {formik.values.map((item, index) => {
          return (
            <div key={index} className="py-2">
              <InputWrapper customClass={"!items-stretch"}>
                <SelectBox
                  multiStyle={"!mt-0 flex-grow"}
                  placeholder="Select Account Type"
                  customClasses={"!py-[9px] bg-white !text-black"}
                  label="Asset Type"
                  id="assetType"
                  options={assetTypes}
                  name="assetType"
                  value={item.assetType}
                  error={formik?.errors[index]?.assetType}
                  touch={formik?.touched[index]?.assetType}
                  onchange={(e) => {
                    handleChange(e, index);
                  }}
                />
              </InputWrapper>
              {item.assetType === "other" && (
                <InputWrapper>
                  <InputRequired
                    label="Asset Name"
                    placeholder="Name here..."
                    type="text"
                    name="assetName"
                    value={item.assetName}
                    error={formik?.errors[index]?.assetName}
                    touch={formik?.touched[index]?.assetName}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                  />
                </InputWrapper>
              )}

              <InputWrapper>
                <InputRequired
                  label="Value"
                  placeholder="$ 0"
                  type="number"
                  name="assetValue"
                  value={item.assetValue}
                  error={formik?.errors[index]?.assetValue}
                  touch={formik?.touched[index]?.assetValue}
                  onChange={(e) => {
                    handleChange(e, index);
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <CheckBox
                  name="assetInsurrance"
                  handleChange={(e) => {
                    handleChange(e, index);
                  }}
                  checked={item.assetInsurrance}
                  label="Do you have insurance for your Assests?"
                />
              </InputWrapper>

              {formik.values.length > 1 ? (
                <div className="w-full flex justify-center items-center my-3">
                  <button
                    type="button"
                    onClick={() => {
                      removeInput(index);
                    }}
                    className="px-4 py-1 satoshi-500 border border-red-600 bg-red-600 text-white transition-all ease-in-out duration-300 hover:bg-white hover:text-red-600"
                  >
                    Delete
                  </button>
                </div>
              ) : null}
            </div>
          );
        })}
        <div className="w-full flex justify-center items-center mt-4">
          <ButtonIcon onclick={appendField} icon={<IoMdAdd />} text="Add" />
        </div>

        <FooterButtons onNext={formik.handleSubmit} onBack={handleBack} />
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step18;
