import React from "react";
import HeaderData from "../../components/Header/HeaderData";
import ReportRow from "../../components/Table/ReportRow";

const Reports = () => {
  return (
    <>
      <HeaderData text="Reports" />
      <div className="w-full mt-3 bg-white rounded-lg dashboardTableWrapper">
        <table className="w-full min-w-[600px] border-collapse !overflow-x-auto">
          <thead className="border-b text-sm">
            <th style={thStyle} className="pl-2 w-[35%] lg:w-[30%]">
              Reports
            </th>
            <th style={thStyle} className="w-[15%] lg:w-[20%]">
              Owner
            </th>
            <th style={thStyle} className="w-[15%]">
              Coach
            </th>
            <th style={thStyle} className="w-[30%]">
              Last Modified
            </th>
          </thead>
          <tbody>
            <ReportRow />
            <ReportRow />
            <ReportRow />
            <ReportRow />
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Reports;

const thStyle = {
  textAlign: "start",
  paddingTop: "24px",
  paddingBottom: "10px",
  color: "var(--gray)",
};
