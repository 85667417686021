import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loading/Loader";
import { useSelector } from "react-redux";
import RowWrapper from "../../components/Wrapper/RowWrapper";
import moment from "moment";
import parse from "html-react-parser";
import ButtonSmall from "../../components/Buttons/ButtonSmall";

const ProgramDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const programData = useSelector((state) => state.programs.data);

  useEffect(() => {
    if (!programData.length) {
      navigate("/programs");
    } else {
      setLoading(true);
      const targetProgram = programData.find((item) => {
        return item._id === id;
      });
      setSelectedProgram(targetProgram);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, []);

  return (
    <>
      <HeaderData text="Course Detail" />
      {loading ? (
        <Loader />
      ) : selectedProgram ? (
        <>
          <div className="w-[95%] mt-5 bg-white">
            <div className="w-full flex justify-between items-stretch gap-3 flex-wrap">
              <div className="flex-grow basis-[330px]">
                <div className="w-full max-w-[450px] h-[250px]">
                  <img
                    className="w-full h-full"
                    src={
                      process.env.REACT_APP_IMAGE_URL + selectedProgram?.photo
                    }
                    alt="Program banner"
                  />
                </div>
              </div>

              <div className="flex-grow basis-[300px] flex justify-start items-start flex-col py-4 px-4">
                <h1 className="satoshi-700">{selectedProgram?.title}</h1>
                <RowWrapper
                  title={"Videos Included"}
                  value={`${selectedProgram?.media?.videos?.length} Vidoes`}
                />
                <RowWrapper
                  title={"Blogs Included"}
                  value={`${selectedProgram?.media?.blogs?.length} blogs`}
                />
                <RowWrapper
                  title={"Course Type"}
                  value={selectedProgram?.programType}
                />
                <RowWrapper
                  title={"Course Price"}
                  customClasses={"satoshi-900 text-green-600"}
                  value={
                    selectedProgram?.programType === "Sell"
                      ? `$ ${selectedProgram?.price}`
                      : "Free"
                  }
                />

                <RowWrapper
                  title={"Created At"}
                  value={moment(parseInt(selectedProgram?.createdAt)).format(
                    "DD MMM, YYYY"
                  )}
                />
              </div>
            </div>

            <div className="w-full mt-2 border-t pt-4 px-4">
              <h1 className="satoshi-700 mb-2 text-[14px] md:text-[16px] xl:text-[18px]">
                Course Description
              </h1>
              <div className="w-full max-h-max satoshi-500 description">
                {parse(parse(selectedProgram?.description))}
              </div>
            </div>

            {selectedProgram.media?.videos?.length ? (
              <div className="w-full mt-12 px-4 pb-4 ">
                <h1 className="satoshi-700 mb-2 text-[14px] md:text-[16px] xl:text-[18px] border-b">
                  Course Videos
                </h1>
                <div className="w-full flex justify-start items-start gap-5 px-4 flex-wrap mt-4">
                  {selectedProgram.media.videos.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="flex-grow basis-[250px] max-w-[600px] boxShadow"
                      >
                        <video className="h-[240px] w-full" controls>
                          <source
                            src={process.env.REACT_APP_IMAGE_URL + item?.video}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>

                        <div className="flex justify-start items-start flex-col py-4 px-4">
                          <h1 className="satoshi-700 text-[14px] sm:text-[16px] xl:text-[18px]">
                            {item?.title}
                          </h1>

                          <div className="w-full mt-2 border-t pt-4">
                            <h1 className="satoshi-700 mb-2 text-[14px] xl:text-[16px]">
                              Description
                            </h1>
                          </div>
                          <div className="w-full max-h-max satoshi-500 description">
                            {parse(parse(item?.description))}
                          </div>
                        </div>
                        <div className="text-right w-full px-4 pb-3">
                          <p className="text-[12px] xl:text-[14px] text-gray-500">
                            Uploaded at :{" "}
                            {moment(parseInt(item?.createdAt)).format(
                              "DD MMM, YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {selectedProgram.media?.blogs?.length ? (
              <div className="w-full mt-12 px-4 pb-4 ">
                <h1 className="satoshi-700 mb-2 text-[14px] md:text-[16px] xl:text-[18px] border-b">
                  Course Blogs
                </h1>
                <div className="w-full flex justify-start items-start gap-5 px-4 flex-wrap mt-4">
                  {selectedProgram?.media?.blogs?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="flex-grow basis-[250px] max-w-[600px] boxShadow"
                      >
                        <img
                          className="h-[240px] w-full"
                          src={process.env.REACT_APP_IMAGE_URL + item?.photo}
                          alt="Blog banner"
                        />

                        <div className="flex justify-start items-start flex-col py-4 px-4">
                          <h1 className="satoshi-700 text-[14px] sm:text-[16px] xl:text-[18px]">
                            {item?.title}
                          </h1>

                          <div className="w-full mt-2 border-t pt-4">
                            <h1 className="satoshi-700 mb-2 text-[14px] xl:text-[16px]">
                              Description
                            </h1>
                          </div>
                          <div className="w-full max-h-max satoshi-500 description">
                            {parse(parse(item?.description))}
                          </div>
                        </div>
                        <div className="text-right w-full px-4 pb-3">
                          <p className="text-[12px] xl:text-[14px] text-gray-500">
                            Uploaded at :{" "}
                            {moment(parseInt(item?.createdAt)).format(
                              "DD MMM, YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <p>No program found</p>
      )}

      <div className="flex justify-center items-center py-3">
        <ButtonSmall
          onClick={() => {
            navigate(-1);
          }}
          text="Go Back"
        />
      </div>
    </>
  );
};

export default ProgramDetail;
