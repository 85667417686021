import { combineReducers } from "redux";
import authReducer from "./authReducer";
import programReducer from "./programReducer";
import accountReducer from "./accountReducer";
import billReducer from "./billReducer";
import dashboardReducer from "./dashboardReducer";
import budgetReducer from "./budgetReducer";
import goalReducer from "./goalReducer";
import budgetTypesReducer from "./budgetTypesReducer";
import debtPlanReducer from "./debtPlanReducer";
import debtResultReducer from "./debtResultReducer";
import smartPlanReducer from "./smartPlanReducer";
import scheduleReducer from "./scheduleReducer";
import budgetHistoryReducer from "./budgetHistoryReducer";
import stripeReducer from "./stripeReducer";
import coachProgramReducer from "./coachProgramsReducer";
import packageReducer from "./packageReducer";
import chatListingReducer from "./chatListingReducer";
import chatAllUser from "./chatAllUser";
import eventReducer from "./eventReducer";

export default combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  programs: programReducer,
  accounts: accountReducer,
  bills: billReducer,
  budget: budgetReducer,
  goals: goalReducer,
  types: budgetTypesReducer,
  debtPlan: debtPlanReducer,
  debtResult: debtResultReducer,
  smartPlan: smartPlanReducer,
  schedule: scheduleReducer,
  budgetHistory: budgetHistoryReducer,
  stripe: stripeReducer,
  coachPrograms: coachProgramReducer,
  packages: packageReducer,
  chatListing: chatListingReducer,
  allChatUser: chatAllUser,
  events: eventReducer,
});
