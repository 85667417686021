import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loading/Loader";
import { useSelector } from "react-redux";
import HeaderData from "../../components/Header/HeaderData";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import SmartPlanCard from "../../components/Card/SmartPlanCard";
import RowWrapper from "../../components/Wrapper/RowWrapper";
import moment from "moment";
import DeleteAccount from "../../components/Modal/DeleteAccount";
import TextModal from "../../components/Modal/TextModal";
import useHasPermission from "../../Utils/permissionUtils";

const AccountDetail = () => {
  const hasPermissionToDelete = useHasPermission("Account /manage and delete");

  const { type } = useParams();
  const [loading, setLoading] = useState(false);
  const data = useSelector((state) => state.accounts.data);
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [deleteAccount, setDeleteAccount] = useState({
    modal: false,
    id: null,
  });
  const [textModal, setTextModal] = useState(false);

  function handleClose() {
    setDeleteAccount({
      modal: false,
      id: null,
    });
  }

  useEffect(() => {
    setLoading(true);
    setSelectedAccount(
      data.filter((item) => item.accountType.toLowerCase() === type)
    );
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {textModal && (
        <TextModal
          open={textModal}
          isPermission={true}
          handleClose={() => {
            setTextModal(false);
          }}
        />
      )}
      {deleteAccount.modal ? (
        <DeleteAccount
          open={deleteAccount.modal}
          data={deleteAccount.id}
          handleClose={handleClose}
        />
      ) : null}
      <HeaderData text="Account Details" />
      {loading ? (
        <Loader />
      ) : (
        <>
          {selectedAccount.length ? (
            <>
              <h5 className="mt-5 text-[18px] satoshi-700 capitalize">
                {selectedAccount[0].accountType}
              </h5>
              <CardWrapper extraClasses={"!items-stretch"}>
                {selectedAccount.map((account, index) => {
                  return (
                    <SmartPlanCard
                      actions={true}
                      onDelete={() => {
                        if (hasPermissionToDelete) {
                          setDeleteAccount({
                            id: account._id,
                            modal: true,
                          });
                        } else {
                          setTextModal(true);
                        }
                      }}
                      key={index}
                      headerText={account?.title}
                    >
                      <RowWrapper
                        title="Institution Name"
                        value={account?.institutionName}
                      />
                      <RowWrapper
                        title="Last 4 digit"
                        value={account?.accountNo}
                      />
                      <RowWrapper
                        title="Created At"
                        value={moment(parseInt(account?.createdAt)).format(
                          "DD MMM, YYYY"
                        )}
                      />
                    </SmartPlanCard>
                  );
                })}
              </CardWrapper>
            </>
          ) : (
            <p>No account found</p>
          )}
        </>
      )}
    </>
  );
};

export default AccountDetail;
