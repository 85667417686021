import React from "react";
// import { HiOutlineDotsVertical } from "react-icons/hi";
import myVideo from "../../Assets/video.mp4";

const MediaCardHeader = ({ image, videoUrl, text }) => {
  return (
    <>
      <div className="w-full">
        {videoUrl ? (
          <div className="w-full h-[180px] rounded-t-md">
            <video width="100%" height="100%" controls>
              <source src={myVideo} type="video/mp4" />
            </video>
            {/* <iframe
              width="100%"
              height="100%"
              src={myVideo}
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            /> */}
          </div>
        ) : (
          <img
            className="w-full h-[170px] rounded-t-md"
            src={image}
            alt="card banner"
          />
        )}
      </div>
      <div className="flex justify-between items-center px-2 mt-2">
        <h4 className="satoshi-900 text-lg ">{text}</h4>
        {/* <HiOutlineDotsVertical /> */}
      </div>
    </>
  );
};

export default MediaCardHeader;
