import { createBrowserRouter } from "react-router-dom";
import SignIn from "../Pages/Auth/SignIn";
import SignUp from "../Pages/Auth/SignUp";
import { RedirectRoute } from "./RedirectRoute";
import { PrivateRoute } from "./PrivateRoute";
import DashboardLayout from "../Layouts/DashboardLayout";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Inbox from "../Pages/Dashboard/Inbox";
import Accounts from "../Pages/Dashboard/Accounts";
import Debit from "../Pages/Dashboard/Debit";
import Bills from "../Pages/Dashboard/Bills";
import Goals from "../Pages/Dashboard/Goals";
import Programs from "../Pages/Dashboard/Programs";
import Reports from "../Pages/Dashboard/Reports";
import Saving from "../Pages/Dashboard/Saving";
import Profile from "../Pages/Dashboard/Profile";
import Budget from "../Pages/Dashboard/Budget";
import SmartPlan from "../Pages/SmartPlan/SmartPlan";
import Messages from "../Pages/Dashboard/Messages";
import NotFound from "../Pages/NotFound/NotFound";
import DebtPlan from "../Pages/Dashboard/DebtPlan";
import ProgramDetail from "../Pages/Dashboard/ProgramDetail";
import SmartPlanDetail from "../Pages/Dashboard/SmartPlanDetail";
import AccountDetail from "../Pages/Dashboard/AccountDetail";
import ForgetPassword from "../Pages/Auth/ForgetPassword";
import CoachPrograms from "../Pages/Dashboard/CoachPrograms";
import SuccessPayment from "../Pages/PaymentStatus/SuccessPayment";
import ErrorPayment from "../Pages/PaymentStatus/ErrorPayment";
import UpgradePackage from "../Pages/Dashboard/UpgradePackage";
import AllSmartPlan from "../Pages/Dashboard/AllSmartPlan";
import Meetings from "../Pages/Dashboard/Meetings";
import OtpVeri from "../components/PasswordRecoverySteps/OtpVeri";

export default createBrowserRouter([
  {
    path: "/signup/:coachurl",
    element: (
      <RedirectRoute>
        <SignUp />
      </RedirectRoute>
    ),
  },
  {
    path: "signin",
    element: (
      <RedirectRoute>
        <SignIn />
      </RedirectRoute>
    ),
  },
  {
    path: "/forget-password",
    element: (
      <RedirectRoute>
        <ForgetPassword />
      </RedirectRoute>
    ),
  },
  {
    path: "/verifyOTP",
    element: (
      <RedirectRoute>
        <OtpVeri />
      </RedirectRoute>
    ),
  },
  {
    path: "",

    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "inbox",
        element: <Inbox />,
      },
      {
        path: "budget",
        element: <Budget />,
      },
      {
        path: "events",
        element: <Meetings />,
      },
      {
        path: "smart-plan-detail/:id",
        element: <SmartPlanDetail />,
      },
      {
        path: "smart-plan-all",
        element: <AllSmartPlan />,
      },
      {
        path: "smart-plan",
        element: <SmartPlan />,
      },
      {
        path: "accounts",
        element: <Accounts />,
      },
      {
        path: "account-detail/:type",
        element: <AccountDetail />,
      },
      {
        path: "/upgrade-package",
        element: <UpgradePackage />,
      },
      {
        path: "debt",
        element: <Debit />,
      },
      {
        path: "debtPlan",
        element: <DebtPlan />,
      },
      {
        path: "bills",
        element: <Bills />,
      },
      {
        path: "goals",
        element: <Goals />,
      },
      {
        path: "allPrograms",
        element: <CoachPrograms />,
      },
      {
        path: "programs",
        element: <Programs />,
      },
      {
        path: "/program-detail/:id",
        element: <ProgramDetail />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "savings",
        element: <Saving />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "messages/:to",
        element: <Messages />,
      },
    ],
  },
  {
    path: "/success/:id",
    element: <SuccessPayment />,
  },
  {
    path: "/error/:id",
    element: <ErrorPayment />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
