import React, { useContext } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import FooterButtons from "./FooterButtons";
import InputRequired from "../Input/InputRequired";
import stepImage from "../../Assets/sm-1.png";
import { accountTypes, retirementAccountTypes } from "../../Data/InputData";
import SelectBox from "../Input/SelectBox";
import ButtonIcon from "../Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import FormWrapper from "./FormWrapper";
import { useFormik } from "formik";
import { step16Schema } from "../../Schema";

const Step16 = ({ handleNext, handleBack }) => {
  const { step16Data, setStep16Data } = useContext(SmartPlanContext);
  const initialState = step16Data;

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: step16Schema,
    onSubmit: (values) => {
      setStep16Data(values);
      handleNext();
    },
  });

  const appendField = () => {
    formik.setValues([
      ...formik.values,
      {
        accountType: null,
        currentBalance: null,
        instituteName: null,
        returnRate: null,
        type: null,
      },
    ]);
  };

  const removeInput = (targetIndex) => {
    const updatedValues = formik.values.filter(
      (_, index) => index !== targetIndex
    );
    formik.setValues(updatedValues);
  };

  function handleChange(e, index) {
    const { value, name } = e.target;
    let updatedData = formik.values.map((item, i) => {
      return i === index ? { ...item, [name]: value } : item;
    });
    formik.setValues(updatedData);
  }

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Type of account you have" />
        {formik.values.map((item, index) => {
          return (
            <div className="py-2">
              <InputWrapper customClass={"!items-stretch"}>
                <SelectBox
                  multiStyle={"!mt-0 flex-grow"}
                  placeholder="Select Account Type"
                  customClasses={"!py-[9px] bg-white !text-black"}
                  label="Account Type"
                  id="year"
                  name="accountType"
                  value={item.accountType}
                  error={formik?.errors[index]?.accountType}
                  touch={formik?.touched[index]?.accountType}
                  options={accountTypes}
                  onchange={(e) => {
                    console.log(e.target.value);
                    handleChange(e, index);
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <InputRequired
                  label="Institution Name"
                  placeholder="Name here..."
                  type="text"
                  name="instituteName"
                  value={item.instituteName}
                  error={formik?.errors[index]?.instituteName}
                  touch={formik?.touched[index]?.instituteName}
                  onChange={(e) => {
                    handleChange(e, index);
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <InputRequired
                  label="Current Balance"
                  placeholder="$ 0"
                  type="number"
                  name="currentBalance"
                  value={item.currentBalance}
                  error={formik?.errors[index]?.currentBalance}
                  touch={formik?.touched[index]?.currentBalance}
                  onChange={(e) => {
                    handleChange(e, index);
                  }}
                />
              </InputWrapper>
              {item.accountType === "Investment" && (
                <InputWrapper>
                  <InputRequired
                    label="Rate of Return"
                    placeholder="0"
                    type="number"
                    name="returnRate"
                    value={item.returnRate}
                    error={formik?.errors[index]?.returnRate}
                    touch={formik?.touched[index]?.returnRate}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                  />
                </InputWrapper>
              )}

              {item.accountType === "Retirement" && (
                <InputWrapper>
                  <SelectBox
                    multiStyle={"!mt-0 flex-grow"}
                    placeholder="Select Account Type"
                    customClasses={"!py-[9px] bg-white !text-black"}
                    label="Type"
                    id="type"
                    name="type"
                    value={item.type}
                    error={formik?.errors[index]?.type}
                    touch={formik?.touched[index]?.type}
                    options={retirementAccountTypes}
                    onchange={(e) => {
                      handleChange(e, index);
                    }}
                  />
                </InputWrapper>
              )}
              {formik.values.length > 1 ? (
                <div className="w-full flex justify-center items-center my-3">
                  <button
                    type="button"
                    onClick={() => {
                      removeInput(index);
                    }}
                    className="px-4 py-1 satoshi-500 border border-red-600 bg-red-600 text-white transition-all ease-in-out duration-300 hover:bg-white hover:text-red-600"
                  >
                    Delete
                  </button>
                </div>
              ) : null}
            </div>
          );
        })}
        <div className="w-full flex justify-center items-center mt-4">
          <ButtonIcon onclick={appendField} icon={<IoMdAdd />} text="Add" />
        </div>

        <FooterButtons onNext={formik.handleSubmit} onBack={handleBack} />
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step16;
