import React from "react";
import { MdKeyboardBackspace } from "react-icons/md";

const FooterButtons = ({ btnNext, onBack, onNext, isLast }) => {
  return (
    <div className="flex justify-between items-center mt-8">
      <button
        onClick={onBack}
        className="flex justify-center items-center text-black"
      >
        <MdKeyboardBackspace className="satoshi-500 text-xl" />
        <p className="satoshi-500 ml-2 text-[16px]">Back</p>
      </button>
      <button
        onClick={onNext}
        className="bg-[--primary] py-2 text-[16px] rounded-md px-4 satoshi-500 text-white"
      >
        {isLast ? "Submit" : "Continue"}
      </button>
    </div>
  );
};

export default FooterButtons;
