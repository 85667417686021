import React, { useState } from "react";
// import parse from "html-react-parser";
import RowWrapper from "../Wrapper/RowWrapper";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useNavigate } from "react-router-dom";
import ButtonLoading from "../Buttons/ButtonLoading";
import { assignProgramSelf } from "../../Redux/Actions";
import { useDispatch } from "react-redux";

const CoachProgramCard = ({ data, userData, onBuyPackage }) => {
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();

  function handleSubscribe() {
    setBtnLoading(true);
    dispatch(
      assignProgramSelf({
        programId: data._id,
        coachId: userData?.clientInfo?.coachId,
        clientId: [userData?._id],
      })
    ).then(() => {
      setBtnLoading(false);
    });
  }

  return (
    <div className="flex-grow max-w-[400px] basis-[300px] rounded-md mediaCardShadow pb-3 bg-white">
      <div className="w-full lg:h-[180px]">
        <img
          className="w-full h-full"
          src={process.env.REACT_APP_IMAGE_URL + data?.photo}
          alt="Program banner"
        />
      </div>

      <div className="mt-3 pl-3 pr-2 ">
        <h1 className=" border-b pb-2 satoshi-700 text-[14px] sm:text-[15px] md:text-[17px]">
          {data?.title}
        </h1>
        <RowWrapper title="Videos Included" value={data?.media?.videos} />
        <RowWrapper title="Blogs Included" value={data?.media?.blogs} />

        <RowWrapper
          customClasses={"satoshi-900 !text-green-500"}
          title="Price"
          value={data?.programType === "Sell" ? `$ ${data?.price}` : "Free"}
        />
        {/* <div className="w-full pt-2">
          <h1 className="satoshi-700 mb-2 text-[12px] md:text-[13px] xl:text-[14px]">
            Program Description
          </h1>
          <div className="safeSection relative">
            {parse(parse(data?.description))}
            <button className="absolute px-3 py-1 text-white border border-white">
              Buy Program
            </button>
          </div>
        </div> */}
      </div>
      {btnLoading ? (
        <div className="w-full flex justify-center items-center pt-4 pb-2">
          <ButtonLoading />
        </div>
      ) : (
        <div className="w-full flex justify-center items-center pt-4 pb-2">
          {data?.Buy ? (
            <ButtonSmall
              onClick={() => {
                navigate(`/program-detail/${data?._id}`);
              }}
              customClasses="!py-1"
              text="View Detail"
            />
          ) : data?.programType === "Sell" ? (
            <ButtonSmall
              onClick={onBuyPackage}
              customClasses="!py-1"
              text="Buy Program"
            />
          ) : (
            <ButtonSmall
              onClick={handleSubscribe}
              customClasses="!py-1"
              text="Subscribe Free"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CoachProgramCard;
