import React from "react";

const StepImage = ({ image, customHeight }) => {
  return (
    <div className="w-[0%] lg:w-1/2 hidden lg:flex justify-center items-center py-14">
      <img
        className={`w-[350px] h-[300px] ${customHeight}`}
        src={image}
        alt="Step avatar"
      />
    </div>
  );
};

export default StepImage;
