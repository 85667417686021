import React from "react";
import wave from "../../Assets/wave.png";
import SelectInput from "../Input/SelectInput";
import { MdDeleteForever } from "react-icons/md";
import { MdEditSquare } from "react-icons/md";
import { CgNotes } from "react-icons/cg";
import { Link } from "react-router-dom";
import { commaNumberFormat } from "../../Utils/NumberFormat";

const FluidCard = ({
  url,
  withDropdown,
  withGraph,
  text,
  value,
  withOptions,
  onEdit,
  onDelete,
}) => {
  return (
    <Link to={url} className="p-3 bg-white rounded-lg flex-grow basis-56">
      <div className="flex w-full justify-between items-center">
        <div className="w-[40px] h-[40px] bg-[--gray-light] flex justify-center items-center rounded-full text-base">
          <CgNotes />
        </div>
        {withDropdown ? <SelectInput /> : ""}
      </div>
      <div className="mt-4 mb-1">
        <p className="text-[18px] text-[--gray]">{text}</p>
      </div>
      <div className="mt-1 mb-1">
        <h1 className="text-[27px] satoshi-900 font-[900]">{commaNumberFormat(value)}</h1>
      </div>

      {withGraph ? (
        <div className="w-full h-14 mt-3">
          <img src={wave} className="w-full h-full" alt="expense" />
        </div>
      ) : null}
      {withOptions ? (
        <div className="flex justify-end items-center gap-4 mt-3">
          <MdEditSquare
            onClick={onEdit}
            className="cursor-pointer text-[20px] text-green-600 transition-all ease-in-out duration-300 hover:text-black "
          />
          <MdDeleteForever
            onClick={onDelete}
            className="cursor-pointer text-[20px] text-red-600 transition-all ease-in-out duration-300 hover:text-black"
          />
        </div>
      ) : null}
    </Link>
  );
};

export default FluidCard;
