import React from "react";
import { MdDelete } from "react-icons/md";

const SmartPlanCard = ({ headerText, actions, onDelete, children }) => {
  return (
    <div className="flex-grow basis-[400px] pb-2 bg-white px-2 rounded-md">
      <div className="py-3 border-b flex justify-between items-stretch">
        <h1 className="satoshi-700">{headerText}</h1>
        {actions ? (
          <div className="flex justify-center items-center gap-3">
            <MdDelete
              onClick={onDelete}
              className="text-red-600 text-[20px] cursor-pointer"
            />
          </div>
        ) : null}
      </div>
      <div className="w-full px-1">{children}</div>
    </div>
  );
};

export default SmartPlanCard;
