import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import Loader from "../../components/Loading/Loader";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getAllMeetings } from "../../Redux/Actions";
import Avatar from "../../components/Avatar/Avatar";
import UnavailableService from "../../components/Message/UnavailableService";
import useHasPermission from "../../Utils/permissionUtils";

let Once = true;

const Meetings = () => {
  const hasPermissionToView = useHasPermission("Meeting");
  const hasPermissionToViewDetail = useHasPermission("Meeting information");

  const [loading, setLoading] = useState(false);
  const allEvents = useSelector((state) => state.events.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(allEvents)) {
        setLoading(true);
        dispatch(getAllMeetings()).then(() => setLoading(false));
      }
    }
  }, []);
  return (
    <>
      <HeaderData text="Events & Meetings" />
      {loading ? (
        <Loader />
      ) : hasPermissionToView || hasPermissionToViewDetail ? (
        <>
          {allEvents.length ? (
            <div className="dashboardTableWrapper">
              <table className="bg-white w-full min-w-[730px] border-collapse !overflow-x-auto mt-3">
                <thead>
                  <tr className="border-b border-black">
                    <th className="text-left px-2 pl-4 py-4 text-[--gray] text-sm">
                      Host
                    </th>
                    <th className="text-left px-2 pl-3 py-4 text-[--gray] text-sm">
                      Title
                    </th>
                    <th className="text-left px-2 py-4 text-[--gray] text-sm">
                      Start Date
                    </th>
                    <th className="text-left px-2 py-4 text-[--gray] text-sm">
                      End Date
                    </th>
                    <th className="text-left px-2 py-4 text-[--gray] text-sm">
                      Created By
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allEvents.map((eventData, index) => {
                    return (
                      <tr
                        key={index}
                        className="text-[13px] tableRow cursor-pointer"
                      >
                        <td className="flex justify-start items-center py-2 pl-2 ">
                          <Avatar imageRef={eventData?.arrangedBy?.photo} />
                          <div className=" ml-2 flex justify-center items-center flex-col">
                            <h5 className="font-semibold text-[15px]">
                              {eventData?.arrangedBy?.firstName +
                                " " +
                                eventData?.arrangedBy?.lastName}
                            </h5>
                          </div>
                        </td>
                        <td className="text-[16px]">
                          <p
                            title={eventData?.title}
                            className="w-[150px] overflow-hidden text-ellipsis whitespace-nowrap"
                          >
                            {eventData?.title}
                          </p>
                        </td>

                        <td className="text-[16px]">{eventData?.start}</td>
                        <td className="text-[16px]">{eventData?.end}</td>
                        <td className="text-[16px]">{eventData?.createdBy}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No events or meeting</p>
          )}
        </>
      ) : (
        <UnavailableService />
      )}
    </>
  );
};

export default Meetings;
