const initialState = {
  data: [],
};

const billReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_BILLS":
      return {
        ...state,
        data: payload.data,
      };
    case "ADD_BILL":
      return {
        ...state,
        data: [...state.data, payload.data],
      };
    case "DELETE_BILL":
      return {
        ...state,
        data: state.data.filter((item) => item.id !== payload),
      };
    case "UPDATE_BILL":
      return {
        ...state,
        data: state.data.map((item) =>
          item._id === payload.data._id ? { ...item, ...payload.data } : item
        ),
      };
    default:
      return state;
  }
};

export default billReducer;
