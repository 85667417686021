import React from "react";
import { MdPictureAsPdf } from "react-icons/md";
import { HiOutlineDotsVertical } from "react-icons/hi";

const ReportRow = () => {
  return (
    <tr className="text-[13px] w-full tableRow">
      <td className="flex justify-start gap-4 w-max text-clip items-center satoshi-500 text-[--red] py-2 pl-2">
        <MdPictureAsPdf className="w-[20px] h-[20px]" />
        <h6 className=" text-blue-400 pr-3 md:pr-8 lg:pr-10">
          John Doe’s retirement plan
        </h6>
      </td>
      <td className="w-max">John Doe</td>
      <td className="w-max">Abner Gelin</td>
      <td className="flex justify-between items-start">
        <h6>2023-07-12 19:11:32 UTC</h6>
        <HiOutlineDotsVertical className="mr-2 " />
      </td>
    </tr>
  );
};

export default ReportRow;
