const initialState = {
  isInvoked: false,
  income: [],
  expense: [],
  expenseTypeId: null,
};

const budgetHistoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_BUDGET_HISTORY":
      return {
        ...state,
        income: payload.income,
        expense: payload.expense,
        expenseTypeId: payload.expenseType,
        isInvoked: true,
      };
    default:
      return state;
  }
};

export default budgetHistoryReducer;
