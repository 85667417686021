import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import ButtonIcon from "../components/Buttons/ButtonIcon";
import HeaderData from "../components/Header/HeaderData";
import CardWrapper from "../components/Wrapper/CardWrapper";
import ChartCard from "../components/Card/ChartCard";
import ChartRow from "../components/Table/ChartRow";
import PiChart from "../components/Charts/PieChart";
import BudgetDetail from "../components/Modal/BudgetDetail";
import { getBudgetInfo } from "../Redux/Actions";
import { generateBudgetYear, generateMonth } from "../Utils";
import Loader from "../components/Loading/Loader";
import useHasPermission from "../Utils/permissionUtils";
import TextModal from "../components/Modal/TextModal";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const BudgetCharts = ({ btnVisiblity, onClick }) => {
  const hasPermissionToManage = useHasPermission("Modify Budget");
  const years = generateBudgetYear();
  const months = generateMonth();
  const { data, totalIncome } = useSelector((state) => state.budget);
  const [isEdit, setIsEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [budgetLoad, setBudgetLoad] = useState(false);
  const [textModal, setTextModal] = useState(false);

  const dispatch = useDispatch();

  const [budgetDetail, setBudgetDetail] = useState({
    modal: false,
    data: null,
  });

  function handleDetailModal() {
    setBudgetDetail({
      modal: false,
      data: null,
    });
  }

  function handleCloseEditMode() {
    setIsEdit(false);
    setOpenModal(!openModal);
  }

  function onChangeMonth(e) {
    setBudgetLoad(true);
    setMonth(e.target.value);

    dispatch(getBudgetInfo(e.target.value, year)).then(() => {
      setBudgetLoad(false);
    });
  }

  function onChangeYear(e) {
    setBudgetLoad(true);
    setYear(e.target.value);
    dispatch(getBudgetInfo(month, e.target.value)).then(() => {
      setBudgetLoad(false);
    });
  }

  return (
    <>
      {textModal && (
        <TextModal
          open={textModal}
          handleClose={() => {
            setTextModal(false);
          }}
          isPermission={true}
        />
      )}
      {budgetDetail.modal ? (
        <BudgetDetail
          open={budgetDetail.modal}
          handleModal={handleDetailModal}
          data={budgetDetail.data}
        />
      ) : null}
      <HeaderData text="Budget">
        {years.length ? (
          <select
            name="year"
            value={year}
            onChange={onChangeYear}
            className="py-[10px] px-0 sm:px-2 md:px-4 text-[12px] sm:text-[14px] rounded-md border border-slate-400 outline-none"
          >
            {years?.map((year, index) => {
              return (
                <option value={year.value} key={index}>
                  {year.label}
                </option>
              );
            })}
          </select>
        ) : null}

        {months.length ? (
          <select
            name="month"
            value={month}
            onChange={onChangeMonth}
            className="py-[10px] px-0 sm:px-2 md:px-4 text-[12px] sm:text-[14px] rounded-md border border-slate-400 outline-none"
          >
            {months?.map((month, index) => {
              return (
                <option value={month.value} key={index}>
                  {month.label}
                </option>
              );
            })}
          </select>
        ) : null}
        {btnVisiblity ? (
          <ButtonIcon
            onclick={() => {
              if (hasPermissionToManage) {
                onClick();
              } else {
                setTextModal(true);
              }
            }}
            icon={<IoMdAdd />}
            text="Create budget"
          />
        ) : (
          <div className="btnLoader"></div>
        )}
      </HeaderData>
      {budgetLoad ? (
        <Loader />
      ) : (
        <>
          <div className="py-2 my-2 px-3 bg-white rounded-md">
            <p className="text-red-600">
              <span className="satoshi-700">Note: </span>
              Expense should be in specific ratio i-e Needs 50% , Wants 30% ,
              Investement 10% and Saving 10% of the total income.
            </p>
          </div>
          {data.length
            ? data.map((item, index) => {
                if (item.typeName === "Expense") {
                  return (
                    <CardWrapper
                      key={index}
                      extraClasses="!mb-2 !py-2 gap-4 !items-stretch"
                    >
                      <ChartCard customClasses="!bg-[--primary] !text-white !py-2">
                        <div className="flex justify-between items-center gap-2 px-2">
                          <div className="flex flex-col pl-2 pr-2 ">
                            <div className="mb-2 mt-2">
                              <h6 className="text-[14px]">Income</h6>
                              <h2 className="text-xl satoshi-700">
                                $ {Math.round(totalIncome)}
                              </h2>
                            </div>
                            <div className="mb-2">
                              <h6 className="text-[14px]">Expense</h6>
                              <h2 className="text-xl satoshi-700">
                                $ {Math.round(item.totalAmount)}
                              </h2>
                            </div>

                            <div className="mb-2 mt-1">
                              <h6 className="text-[14px]">Available Balance</h6>
                              <h2
                                className={`${
                                  item.totalAmount > totalIncome
                                    ? "text-red-500"
                                    : ""
                                }  text-xl satoshi-700`}
                              >
                                $ {Math.round(totalIncome - item.totalAmount)}
                              </h2>
                            </div>
                          </div>
                          <PiChart
                            customData={item.transactions}
                            colors={COLORS}
                          />
                        </div>
                      </ChartCard>
                      <ChartCard>
                        {item.transactions
                          .sort((a, b) => {
                            const order = [
                              "NEEDS",
                              "WANTS",
                              "SAVING",
                              "INVESTING",
                            ];

                            // Check if categories exist in the order
                            const indexA = order.indexOf(a.categoryName);
                            const indexB = order.indexOf(b.categoryName);

                            // Handle cases where category is missing
                            if (indexA === -1 && indexB === -1) return 0; // If both categories are missing, maintain order
                            if (indexA === -1) return 1; // If A is missing, prioritize B
                            if (indexB === -1) return -1; // If B is missing, prioritize A

                            // Sort based on the custom order
                            return indexA - indexB;
                          })
                          .map((entry, index) => {
                            return (
                              <ChartRow
                                onClick={() => {
                                  setBudgetDetail({
                                    data: entry,
                                    modal: true,
                                  });
                                }}
                                key={index}
                                name={entry.categoryName}
                                value={entry.totalRecordsAmount}
                                total={Math.round(
                                  item.totalAmount - entry.totalRecordsAmount
                                )}
                                percentage={
                                  (entry?.totalRecordsAmount / totalIncome) *
                                    100 || 0
                                }
                                color={COLORS[index % COLORS.length]}
                              />
                            );
                          })}
                      </ChartCard>
                    </CardWrapper>
                  );
                }
              })
            : null}
        </>
      )}
    </>
  );
};

export default BudgetCharts;
