import React from "react";

const ChartCard = ({ customClasses, children }) => {
  return (
    <div
      className={`bg-white rounded-lg flex-grow basis-[330px] py-3 ${customClasses}`}
    >
      {children}
    </div>
  );
};

export default ChartCard;
