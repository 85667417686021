import React from "react";

const InputSmall = ({
  label,
  id,
  placeholder,
  type,
  name,
  value,
  customClasses,
  onChange,
  onBlur,
  error,
  touch,
  disable,
}) => {
  return (
    <div className={`flex-grow basis-52 ${customClasses}`}>
      <label
        htmlFor={id}
        className="block mb-1 pl-[2px] text-[16px] capitalize text-[#333333]"
      >
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={id}
        value={value}
        className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-md focus:border-gray-800 block w-full px-2.5 py-2 "
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disable}
      />
      {error && touch ? (
        <p className="text-[14px] text-red-600">{error}</p>
      ) : null}
    </div>
  );
};

export default InputSmall;
