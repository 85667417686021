import React from "react";
import { HiOutlineUsers } from "react-icons/hi";
import {
  // calculate,
  calculateGoalPercentage,
  // generatePercentageInfo,
  getGoalCardText,
} from "../../Utils";
import moment from "moment";
import { MdDeleteForever } from "react-icons/md";

const CardWeek = ({
  goalData,
  handleEdit,
  onDeleteGoal,
  handleMarkComplete,
}) => {
  return (
    <div className="p-3 bg-white rounded-lg flex-grow basis-72">
      <div className="flex w-full justify-between items-center">
        <div className="w-[40px] h-[40px] bg-[--gray-light] flex justify-center items-center rounded-full text-xl">
          <HiOutlineUsers />
        </div>
        <div className="flex justify-end items-center gap-3">
          <div onClick={handleEdit} className="cursor-pointer">
            <h5 className="text-blue-400 underline">View Detail</h5>
          </div>
          {goalData.createdBy === "Client" && (
            <div
              onClick={onDeleteGoal}
              className="cursor-pointer border border-red-700 bg-red-700 text-white rounded-md px-2 py-1 transition-all ease-in-out duration-500 hover:bg-white hover:text-red-700"
            >
              <MdDeleteForever className="text-sm md:text-base lg:text-lg xl:text-xl" />
            </div>
          )}
        </div>
      </div>
      <div onClick={handleEdit} className="mt-4 mb-1 cursor-pointer">
        <h4 className="text-[16px] w-full font-semibold">{goalData?.title}</h4>
      </div>

      <div className="mt-1 mb-1">
        <h4 className="text-[16px] w-full satoshi-500 text-green-600">
          {goalData?.goalType === "Financial"
            ? "Financial Goal"
            : "Non-Financial Goal"}
        </h4>
      </div>
      <div className="mt-1 mb-1">
        <div className="text-sm satoshi-500  flex justify-start items-center gap-2 ">
          <h6 className="text-[--gray]">End Date - </h6>
          <h6>{moment(parseInt(goalData?.endDate)).format("DD MMM, YYYY")}</h6>
        </div>
      </div>
      <div className="w-full text-sm mt-3 flex justify-between items-center ">
        <h6 className="satoshi-500">
          {getGoalCardText(goalData)}
          {/* Week {goalData?.weeksPassed} of {goalData?.weeks} */}
        </h6>
        <h6>{calculateGoalPercentage(goalData)}%</h6>
        {/* <h6>{goalData?.progressPercentage?.toFixed(2)}%</h6> */}
      </div>
      <div className="w-full py-3">
        <div className="min-w-[60px] flex-grow bg-[--gray-light] rounded-2xl">
          <div
            style={{ width: `${parseInt(calculateGoalPercentage(goalData))}%` }}
            className={`py-[5px] bg-[--primary]  rounded-2xl`}
          ></div>
        </div>
      </div>
      <div className="flex justify-end items-center">
        {goalData?.completed ? (
          <button className="bg-green-200 py-1 px-2 text-black rounded-md satoshi-500">
            Completed
          </button>
        ) : (goalData?.goalType === "Nonfinancial" &&
            goalData?.stepsCompleted === goalData?.steps?.length) ||
          (goalData?.goalType === "Financial" &&
            goalData?.savedAmount === goalData.amount) ? (
          <button
            onClick={handleMarkComplete}
            className="bg-green-800 py-1 px-2 text-white rounded-md satoshi-500"
          >
            Mark as completed
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default CardWeek;
