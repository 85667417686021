import * as Yup from "yup";

// const getCharacterValidationError = (str) => {
//   return `Your password must have at least 1 ${str} `;
// };

// const minLengthError = (str, value) => {
//   return `${str} must be at least ${value} characters`;
// };

// const maxLengthError = (str, value) => {
//   return `${str} must not exceed ${value} characters`;
// };

const stringValidation = (message = "Field is required") =>
  Yup.string().required(message);

export const loginSchema = Yup.object({
  email: stringValidation().email(),
  password: stringValidation(),
});

export const emailSchema = Yup.object({
  email: loginSchema.fields.email,
});

export const otpSchema = Yup.object({
  inputCode: Yup.number()
    .typeError("OTP must be a number")
    .min(99999, "OTP must be 6 digit")
    .max(999999, "OTP must be 6 digit")
    .required("OTP is required"),
});

export const signupSchema = Yup.object({
  firstName: stringValidation(),
  lastName: stringValidation(),
  email: stringValidation().email(),
  password: stringValidation()
    .min(6, "Password must be at least 6 characters")
    .matches(/[0-9]/, "Password must contain at least one digit")
    .matches(/[a-z]/, "Password must contain at least one lowercase character")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character"),
  clientInfo: Yup.object().shape({
    coachId: Yup.string(),
    dob: Yup.string(),
    occupation: Yup.string(),
    phone: Yup.string(),
    address: Yup.string(),
  }),
});

export const passwordSchema = Yup.object({
  password: signupSchema.fields.password,
  confirmPassword: stringValidation("Confirm password is required").oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

export const personalInformationSchema = Yup.object({
  spouseInformation: Yup.object({
    name: stringValidation(),
    // dob: Yup.date().required("Date of birth is required"),
    phone: Yup.number()
      .typeError("Phone must be a number")
      .required("Phone is required"),
    email: stringValidation().email("Email is required"),
    address: stringValidation(),
  }),
  hasChildren: Yup.boolean(),
});

export const assetSchema = Yup.object().shape({
  yourAssets: Yup.array().of(
    Yup.object().shape({
      Description: stringValidation("Description is required"),
      Balance: Yup.number().required("Balance required"),
      anualContribution: Yup.number()
        .required("Field is required")
        .max(100, "Percentage must be less than or equal to 100"),
      employeeContribution: Yup.number()
        .required("Field is required")
        .max(100, "Percentage must be less than or equal to 100"),
    })
  ),
  SpouseSavings: Yup.array().of(
    Yup.object().shape({
      Description: stringValidation("Description is required"),
      Balance: stringValidation("Balance is required"),
      anualContribution: Yup.number()
        .required("Field is required")
        .max(100, "Percentage must be less than or equal to 100"),
      employeeContribution: Yup.number()
        .required("Field is required")
        .max(100, "Percentage must be less than or equal to 100"),
    })
  ),
});

export const debtSchema = Yup.object().shape({
  debtInformation: Yup.array().of(
    Yup.object().shape({
      debtType: stringValidation("Debt Type is required"),
      description: stringValidation("Description is required"),
      balance: Yup.number().required("Balance required"),
      anualContribution: Yup.number()
        .required("Annual Contribution is required")
        .max(100, "Percentage must be less than or equal to 100"),
    })
  ),
  OtherLiabilities: Yup.array().of(
    Yup.object().shape({
      text: stringValidation("Liability is required"),
    })
  ),
});

export const riskSchema = Yup.object({
  self: Yup.number()
    .required("Field is required")
    .max(10, "Risk must be less than or equal to 10"),
  spouse: Yup.number()
    .required("Field is required")
    .max(10, "Risk must be less than or equal to 10"),
  will: Yup.boolean(),
  lastUpdated: Yup.number(),
  lastAmmended: Yup.number(),
  savingForChildrens: Yup.boolean(),
  selfLifeInsurance: Yup.boolean(),
  selfAmount: Yup.number(),
  wifeLifeInsurance: Yup.boolean(),
  spouseInsuranceAmount: Yup.number(),
  questionsAndConcerns: stringValidation(),
});

export const desiredRetirementsSchema = Yup.object({
  ageToRetire: Yup.number()
    .required("Age to retire is required")
    .max(120, "Years must be less than 120"),
  acceptableAge: Yup.number()
    .required("Acceptable age is required")
    .max(120, "Years must be less than 120"),
  spouseAgeToRetire: Yup.number()
    .required("Spouse age to retire is required")
    .max(120, "Years must be less than 120"),
  spouseAcceptableAge: Yup.number()
    .required("Spouse acceptable age is required")
    .max(120, "Years must be less than 120"),
});

export const workRequirementSchema = Yup.object({
  status: Yup.boolean(),
  howManyYears: Yup.number().max(100, "Years must be less than 100"),
  expectedIncome: Yup.number().required("Expected Income is required"),
  spouseStatus: Yup.boolean(),
  spouseHowManyYears: Yup.number().max(100, "Years must be less than 100"),
  spouseExpectedIncome: Yup.number().required(
    "Spouse Expected Income is required"
  ),
  acceptableAge: Yup.number()
    .required("Acceptable Age is required")
    .max(130, "Years must be less than 100"),
  desiredIncomeInRetirement: Yup.number().required(
    "Desired Income is required"
  ),
});

export const socialSecuritySchema = Yup.object({
  monthlyBenefits: stringValidation("Monthly is required"),
  spouseMonthlyBenefits: stringValidation("Spouse Monthly benefit is required"),
  divorced: Yup.boolean(),
  widow: Yup.boolean(),
});

export const finalStepSchema = Yup.object({
  name: stringValidation("Smart Plan name is required"),
  description: stringValidation("Description is required"),
});

export const billSchema = Yup.object({
  name: stringValidation("Title is required"),
  amount: Yup.number()
    .min(1, "Amount must be greater than zero")
    .required("Amount is required"),
  dueDate: Yup.date().required("Date is required"),
});

export const accountSchema = Yup.object({
  title: stringValidation("Account Title is required"),
  accountType: stringValidation("Account Type is required"),
  institutionName: stringValidation("Institution Name is required"),
});

export const budgetSchema = Yup.object({
  type: stringValidation(),
  category: stringValidation("Please select category"),
  subCategory: Yup.string(),
  budgetPurpose: Yup.string(),
  amount: Yup.number()
    .required("Please enter amount")
    .min(1, "Amount must be greater than zero"),
  duration: stringValidation("Please enter duration"),
});

export const goalSchema = Yup.object({
  goalType: Yup.string()
    .oneOf(["Financial", "Nonfinancial"])
    .required("Goal Type is required"),
  name: stringValidation("Title is required"),
  duration: Yup.number().required("Duration is required"),
  durationType: stringValidation("Duration type is required"),
});

export const AddDebtSchema = Yup.object({
  category: stringValidation("Category is required"),
  name: stringValidation("Nickname is required"),
  currentBalance: Yup.number().required("Current balance is required"),
  annualPercentageRate: Yup.number().required("Percentage rate is required"),
  minimumPayment: Yup.number().required("Payment  is required"),
  nextPaymentDate: Yup.date().required("Next payment date is required"),
});

export const debtPlanSchema = Yup.object().shape({
  monthlyBudget: Yup.number().required("Monthly budget is required"),
  data: Yup.array().of(
    Yup.object().shape({
      creditor: stringValidation("Creditor Name is required"),
      balance: Yup.number()
        .typeError("Balance must be a number")
        .required("Balance is required"),
      rate: Yup.number()
        .typeError("Rate must be a number")
        .required("Rate is required"),
      payment: Yup.number()
        .typeError("Payment must be a number")
        .required("Payment is required"),
      custom: Yup.number()
        .typeError("custom must be a number")
        .required("Custom is required"),
    })
  ),
});

export const updateProfile = Yup.object({
  firstName: stringValidation(),
  lastName: stringValidation(),
  email: stringValidation().email(),
  clientInfo: Yup.object().shape({
    coachId: Yup.string(),
    dob: Yup.string(),
    occupation: Yup.string(),
    phone: Yup.string(),
    address: Yup.string(),
  }),
});

export const updateGoalSchema = Yup.object({
  goalType: Yup.string()
    .oneOf(["Financial", "Nonfinancial"])
    .required("Goal Type is required"),
  name: stringValidation("Title is required"),
});

export const step2Schema = Yup.object({
  gender: stringValidation("Gender is required").oneOf(["male", "female"]),
  birthMonth: stringValidation("Birth month is required"),
  birthYear: stringValidation(),
});

export const step4Schema = Yup.object({
  companyName: Yup.string().nullable(),
  incomeAmount: Yup.number().nullable(),
  frequency: Yup.string().nullable(),
});

export const step5Schema = Yup.object({
  additionalIncomeType: stringValidation("Field is required"),
  amount: Yup.number().required("Amount is required"),
});

export const step8Schema = Yup.array().of(
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    age: Yup.number()
      .required("Age is required")
      .integer("Age must be an integer"),
  })
);

export const step9Schema = Yup.array().of(
  Yup.object().shape({
    Institution: Yup.string().required("Institution name is required"),
    Amount: Yup.number()
      .required("Amount is required")
      .integer("Amount must be an integer"),
  })
);

export const step10Schema = Yup.object({
  Institution: stringValidation("Field is required"),
  Amount: Yup.number().required("Amount is required"),
});

export const step13Schema = Yup.object({
  homeValue: Yup.number().required("Field is required"),
  loanBalance: Yup.number().required("Field is required"),
  monthlyPayment: Yup.number().required("Field is required"),
  interestRate: Yup.number().required("Field is required"),
});

export const step14Schema = Yup.object({
  rentAmount: Yup.number().required("Rent amount is required"),
});

export const step16Schema = Yup.array().of(
  Yup.object().shape({
    accountType: stringValidation(),
    instituteName: stringValidation(),
    type: Yup.string().nullable(),
    currentBalance: Yup.number()
      .required("Balance is required")
      .integer("Balance must be an integer"),
    returnRate: Yup.number().nullable(),
  })
);

export const step17Schema = Yup.array().of(
  Yup.object().shape({
    accountName: Yup.string().nullable(),
  })
);

export const step18Schema = Yup.array().of(
  Yup.object().shape({
    assetType: Yup.string().nullable(),
    assetName: Yup.string().nullable(),
    assetValue: Yup.number().nullable(),
    assetInsurrance: Yup.boolean().default(false).nullable(),
  })
);

export const step20Schema = Yup.object().shape({
  debt: Yup.array().of(
    Yup.object().shape({
      debtType: stringValidation(),

      instituteName: stringValidation(),
      monthlyPayment: Yup.number()
        .required("Monthly Payment is required")
        .integer("Value must be an integer"),
      balance: Yup.number()
        .required("Balance is required")
        .integer("Value must be an integer"),
      rateOfDebt: Yup.number()
        .required("Rate of debt is required")
        .integer("Value must be an integer"),
    })
  ),
  debtCondition: stringValidation(),
});

export const step24Schema = Yup.object({
  avgMonthlyExpense: Yup.number().nullable(),
});

export const step25Schema = Yup.object({
  documentsOrganized: stringValidation(),
  identityTheftProtection: stringValidation(),
  onTrackDebtFree: stringValidation(),
  budgetingAndDebtPayoffTool: stringValidation(),
  preparedToManageFinances: stringValidation(),
  interestedInEducationAndServices: stringValidation(),
  reportingRentPayments: stringValidation(),
});

export const step26Schema = Yup.object({
  personalInfo: Yup.object({
    retirementAge: stringValidation("Retirement age is required"),
    dob: stringValidation(),
  }),
});

export const updatePasswordSchema = Yup.object({
  currentPassword: stringValidation("Please enter your current password"),
  password: passwordSchema.fields.password,
  confirmPassword: passwordSchema.fields.confirmPassword,
});

export const updateEmailSchema = Yup.object({
  newEmail: stringValidation('Email is required').email(),
})
