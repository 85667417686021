import React, { useState } from "react";
import Modal from "./Modal";
import ButtonSmall from "../Buttons/ButtonSmall";
import ButtonLoading from "../Buttons/ButtonLoading";
import { useFormik } from "formik";
import { billSchema } from "../../Schema";
import { useDispatch } from "react-redux";
import { addBillsInfo, updateBillsInfo } from "../../Redux/Actions";
import InputWrapper from "../Wrapper/InputWrapper";
import InputSmall from "../Input/InputSmall";
import moment from "moment";

const AddEditBillModal = ({ open, handleModal, isEdit, editData }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const initialState = {
    name: isEdit ? editData.name : "",
    amount: isEdit ? editData.amount : 0,
    dueDate: isEdit
      ? moment(parseInt(editData.dueDate)).format("YYYY-MM-DD")
      : "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: billSchema,
      onSubmit: (values, action) => {
        setLoading(true);
        if (isEdit) {
          values.billId = editData._id;
          values.dueDate = JSON.stringify(new Date(values.dueDate).getTime());
          dispatch(updateBillsInfo(values)).then(() => {
            action.resetForm();
            setLoading(false);
            handleModal();
          });
        } else {
          values.dueDate = JSON.stringify(new Date(values.dueDate).getTime());
          dispatch(addBillsInfo(values)).then(() => {
            action.resetForm();
            setLoading(false);
            handleModal();
          });
        }
      },
    });

  return (
    <Modal
      open={open}
      onclick={handleModal}
      headerText={isEdit ? "Update Bill" : "Add Bill"}
    >
      <InputWrapper>
        <InputSmall
          name="name"
          type="text"
          value={values.name}
          label="Title"
          placeholder="Bill title"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.name}
          touch={touched.name}
        />
      </InputWrapper>
      <InputWrapper>
        <InputSmall
          name="amount"
          value={values.amount}
          label="Amount"
          placeholder="Bill Amount"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.amount}
          touch={touched.amount}
          type="number"
        />
      </InputWrapper>
      <InputWrapper>
        <InputSmall
          name="dueDate"
          value={values.dueDate}
          label="Due Date"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.dueDate}
          touch={touched.dueDate}
          type="date"
        />
      </InputWrapper>
      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              text="Cancel"
              type="button"
              onClick={handleModal}
              customClasses={
                "!w-1/2 !bg-white !text-[--primary] !border-[--primary]"
              }
            />
            <ButtonSmall
              text={isEdit ? "Update" : "Add"}
              onClick={handleSubmit}
              customClasses={"!w-1/2"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddEditBillModal;
