import React from "react";
import Modal from "./Modal";
import ButtonSmall from "../Buttons/ButtonSmall";

const UnSeenGoal = ({ open, handleClose, count }) => {
  return (
    <Modal open={open} onclick={handleClose} headerText="Alert">
      <div className="w-full px-2 pb-3">
        <p className="text-base">
          Hey, looks like your coach/staff has added{" "}
          <span className="font-extrabold text-xl">{count}</span> new goals for
          you. Please go to goals and check new goals.
        </p>
      </div>
      <div className="flex justify-center items-center gap-4 mt-6 w-full">
        <ButtonSmall
          text="Close"
          onClick={handleClose}
          customClasses="!bg-red-600 !border-red-600"
        />
      </div>
    </Modal>
  );
};

export default UnSeenGoal;
