import React, { useEffect, useState } from "react";
import FormLayout from "../../Layouts/FormLayout";
import Form from "../../components/Form/Form";
import InputRequired from "../../components/Input/InputRequired";
import InputPassword from "../../components/Input/InputPassword";
import ButtonLarge from "../../components/Buttons/ButtonLarge";
// import ButtonWrapper from "../../components/Wrapper/ButtonWrapper";
// import ButtonImage from "../../components/Buttons/ButtonImage";
// import googleImage from "../../Assets/google.png";
// import facebookImage from "../../Assets/facebook.png";
import FormFooter from "../../components/Form/FormFooter";
import InputWrapper from "../../components/Wrapper/InputWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { getCoachInformation, myDummyCall, signup } from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import Loader from "../../components/Loading/Loader";
import { signupSchema } from "../../Schema";
import { useFormik } from "formik";
import ButtonLoading from "../../components/Buttons/ButtonLoading";
import OtpVeri from "../../components/PasswordRecoverySteps/OtpVeri";
const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  clientInfo: {
    coachId: "",
    dob: "",
    occupation: "",
    phone: "",
    address: "",
  },
  role: "Client",
};

let Once = true;

const SignUp = () => {
  const { coachurl } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showOtpStep, setShowOtpStep] = useState(false);
  const [otpData, setOtpData] = useState(null);
  const navigate = useNavigate();

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: signupSchema,
      onSubmit: (values) => {
        setBtnLoading(true);
        dispatch(signup(values)).then((res) => {
          setBtnLoading(false);
          setShowOtpStep(true);
          setOtpData(res);
        }).catch((err) => {
          setBtnLoading(false);
          console.log('Error in Signup', err.message);
        })
      },
    });

  useEffect(() => {
    if (Once) {
      if (coachurl) {
        Once = false;
        setLoading(true);
        dispatch(getCoachInformation(coachurl))
          .then((res) => {
            values.clientInfo.coachId = res?.data?.data?.id;
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            navigate("/notfound");
            setLoading(false);
          });
      }
    }
  }, []);

  return (
    <>
      {loading ? (
        <section className="min-h-screen min-w-screen flex justify-center items-center">
          <Loader />
        </section>
      ) : (
        <FormLayout>
          {showOtpStep ? (
            <OtpVeri
              stepsValues={otpData}
              onNext={() => navigate("/")}
            />
          ) : (
            <>
              <Form headerText="Create your account"
                headingText={'Create your account by entering your information below.'}
                onSubmit={handleSubmit}>
                <InputWrapper>
                  <InputRequired
                    label="First Name"
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    error={errors.firstName}
                    onBlur={handleBlur}
                    touch={touched.firstName}
                    onChange={handleChange}
                    placeholder="John"
                  />
                </InputWrapper>
                <InputWrapper customClass={"!mt-1"}>
                  <InputRequired
                    label="Last Name"
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    error={errors.lastName}
                    onBlur={handleBlur}
                    touch={touched.lastName}
                    onChange={handleChange}
                    placeholder="Doe"
                  />
                </InputWrapper>
                <InputWrapper customClass={"!mt-1"}>
                  <InputRequired
                    label="Email"
                    type="email"
                    id="email"
                    name="email"
                    value={values.email}
                    touch={touched.email}
                    error={errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="johndoe@gmail.com"
                  />
                </InputWrapper>
                <InputWrapper customClass={"!mt-1"}>
                  <InputPassword
                    id="password"
                    name="password"
                    label="Password"
                    value={values.password}
                    touch={touched.password}
                    error={errors.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter password"
                  />
                </InputWrapper>
                <InputWrapper customClass={"!mt-1"}>
                  <InputRequired
                    label="Date of birth"
                    type="date"
                    id="dob"
                    name="clientInfo.dob"
                    value={values.clientInfo?.dob}
                    onChange={handleChange}
                    error={errors.clientInfo?.dob}
                    touch={touched.clientInfo?.dob}
                    onBlur={handleBlur}
                  />
                </InputWrapper>
                <InputWrapper customClass={"!mt-1"}>
                  <InputRequired
                    label="Occupation"
                    type="text"
                    id="occupation"
                    name="clientInfo.occupation"
                    value={values.clientInfo?.occupation}
                    onChange={handleChange}
                    error={errors.clientInfo?.occupation}
                    touch={touched.clientInfo?.occupation}
                    onBlur={handleBlur}
                    placeholder="i-e Engineer"
                  />
                </InputWrapper>

                <InputWrapper customClass={"!mt-1"}>
                  <InputRequired
                    label="Contact Number"
                    type="number"
                    id="number"
                    name="clientInfo.phone"
                    value={values.clientInfo?.phone}
                    onChange={handleChange}
                    error={errors.clientInfo?.phone}
                    touch={touched.clientInfo?.phone}
                    onBlur={handleBlur}
                    placeholder="i-e 923040000000"
                  />
                </InputWrapper>
                <InputWrapper customClass={"!mt-1"}>
                  <InputRequired
                    label="Addres"
                    type="text"
                    id="address"
                    name="clientInfo.address"
                    value={values.clientInfo?.address}
                    onChange={handleChange}
                    error={errors.clientInfo?.address}
                    touch={touched.clientInfo?.address}
                    onBlur={handleBlur}
                    placeholder="i-e ABC Market, 2 street"
                  />
                </InputWrapper>
                <div className="mt-2 w-full">
                  {btnLoading ? <ButtonLoading /> : <ButtonLarge onClick={handleSubmit} text="Sign Up" />}
                </div>
                {/* <ButtonWrapper text="Or Sign up with">
          <ButtonImage img={googleImage} text="Sign Up with Google" />
          <ButtonImage img={facebookImage} text="Sign Up with Facebook" />
        </ButtonWrapper> */}
              </Form>
              <FormFooter
                text="Already have account ?"
                url="/signin"
                urltext="Sign In"
              />
            </>
          )
          }
        </FormLayout>
      )}
    </>
  );
};

export default SignUp;
