const initialState = {
  data: [],
  financialReport: [],
};

const smartPlanReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_SMARTPLAN":
      return {
        ...state,
        data: payload.data,
      };
    case "ADD_SMARTPLAN":
      return {
        ...state,
        data: [...state.data, payload.data],
      };
    case "FETCH_FINANCIAL_REPORT":
      return {
        ...state,
        financialReport: payload.data,
      };
    case "CLEAR_SMP_STATE":
      return {
        data: [],
        financialReport: [],
      };
    default:
      return state;
  }
};

export default smartPlanReducer;
