import React, { useContext, useEffect } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import FooterButtons from "./FooterButtons";
import InputRequired from "../Input/InputRequired";
import stepImage from "../../Assets/sm-4.png";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import FormWrapper from "./FormWrapper";
import { useFormik } from "formik";
import { step14Schema } from "../../Schema";

const Step14 = ({ handleNext, handleBack, direction }) => {
  const { step11Data, step14Data, setStep14Data } =
    useContext(SmartPlanContext);

  const initialState = step14Data;

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: step14Schema,
      onSubmit: (values) => {
        setStep14Data(values);
        handleNext();
      },
    });

  useEffect(() => {
    if (step11Data === "own") {
      setStep14Data({
        rentAmount: null,
      });
      if (direction === "next") handleNext();
      else handleBack();
    }
  }, []);

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Great, tell us about your rent" />
        <div className="py-2">
          <InputWrapper>
            <InputRequired
              label="Monthly Rent Amount"
              placeholder="$ 0"
              type="number"
              name="rentAmount"
              value={values.rentAmount}
              touch={touched.rentAmount}
              error={errors.rentAmount}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWrapper>

          <FooterButtons onNext={handleSubmit} onBack={handleBack} />
        </div>
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step14;
