const initialState = {
  data: [],
  isInvoked: false,
};

const goalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_GOALS":
      return {
        ...state,
        data: payload.data,
        isInvoked: true,
      };
    case "ADD_GOAL":
      return {
        ...state,
        data: payload.data,
      };
    case "DELETE_GOAL":
      return {
        ...state,
        data: state.data.filter((item) => item._id !== payload._id),
      };
    default:
      return state;
  }
};

export default goalReducer;
