import React, { useContext, useEffect, useState } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import FooterButtons from "./FooterButtons";
import stepImage from "../../Assets/sm-4.png";
import SelectBox from "../Input/SelectBox";
import ButtonIcon from "../Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import { accountTypes } from "../../Data/InputData";
import FormWrapper from "./FormWrapper";
import { useFormik } from "formik";

import { step17Schema } from "../../Schema";

const Step17 = ({ handleNext, handleBack, direction }) => {
  const { step16Data, step17Data, setStep17Data } =
    useContext(SmartPlanContext);
  const [accountsType, setAccountsType] = useState(accountTypes);
  const initialState = step17Data;

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: step17Schema,
    onSubmit: (values) => {
      console.log(values);
      setStep17Data(values);
      handleNext();
    },
  });

  const appendInputFields = () => {
    formik.setValues([
      ...formik.values,
      {
        accountName: null,
      },
    ]);
  };

  function removeInput(targetIndex) {
    formik.setValues(() => {
      return formik.values.filter((_, index) => {
        return index !== targetIndex;
      });
    });
  }

  function handleChange(e, index) {
    const { value, name } = e.target;
    let updatedData = formik.values.map((item, i) => {
      return i === index ? { ...item, [name]: value } : item;
    });
    formik.setValues(updatedData);
  }

  useEffect(() => {
    const selectedAccountTypeIds = new Set(
      step16Data.map((account) => account.accountType)
    );

    const unselectedAccountTypes = accountTypes.filter(
      (accountType) => !selectedAccountTypeIds.has(accountType.id)
    );

    if (unselectedAccountTypes.length > 0) {
      setAccountsType(unselectedAccountTypes);
    } else {
      if (direction === "next") {
        handleNext();
      } else {
        handleBack();
      }
    }
  }, []);

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary
          customClasses={"!mt-4"}
          text="Would you like information to create accounts you don’t have?"
        />

        {formik.values.map((item, index) => {
          return (
            <div className="py-4">
              <InputWrapper customClass={"!items-stretch"}>
                <SelectBox
                  multiStyle={"!mt-0 flex-grow"}
                  placeholder="Account List"
                  customClasses={"!py-[9px] bg-white !text-black "}
                  label="List of Accounts"
                  id="year"
                  options={accountsType}
                  name="accountName"
                  value={item.accountName}
                  onBlur={formik?.handleBlur}
                  touch={formik?.touched[index]?.accountName}
                  error={formik?.errors[index]?.accountName}
                  onchange={(e) => handleChange(e, index)}
                />
              </InputWrapper>
              {formik.values.length > 1 ? (
                <div className="w-full flex justify-center items-center my-3">
                  <button
                    type="button"
                    onClick={() => {
                      removeInput(index);
                    }}
                    className="px-4 py-1 satoshi-500 border border-red-600 bg-red-600 text-white transition-all ease-in-out duration-300 hover:bg-white hover:text-red-600"
                  >
                    Delete
                  </button>
                </div>
              ) : null}
            </div>
          );
        })}
        <div className="w-full flex justify-center items-center mt-4">
          <ButtonIcon
            onclick={appendInputFields}
            icon={<IoMdAdd />}
            text="Save"
          />
        </div>
        <FooterButtons onNext={formik.handleSubmit} onBack={handleBack} />
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step17;
