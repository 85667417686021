const initialState = {
  data: {
    monthlyBudget: "",
    planData: [],
  },
  isInvoked: false,
};

const debtPlanReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PLAN":
      return {
        ...state,
        data: payload,
        isInvoked: true,
      };
    case "ADD_PLAN":
      return {
        ...state,
        data: payload.data,
      };
    default:
      return state;
  }
};

export default debtPlanReducer;
