import React, { useState } from "react";
import Modal from "./Modal";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch } from "react-redux";
import { deleteAccount } from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";

const DeleteAccount = ({ open, handleClose, data }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function handleDelete() {
    setLoading(true);
    dispatch(deleteAccount(data)).then(() => {
      setLoading(false);
      navigate("/accounts");
    });
  }
  return (
    <Modal open={open} onclick={handleClose} headerText="Delete Bill">
      <div className="text-center w-full">
        <p>Are you want to delete Account ? </p>
      </div>
      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2"}
              onClick={handleClose}
            />
            <ButtonSmall
              onClick={handleDelete}
              type="button"
              text="Delete"
              customClasses={"!w-1/2 !bg-red-800 !text-white !border-red-600"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteAccount;
