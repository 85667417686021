import { MdOutlineDashboard, MdEventNote } from "react-icons/md";
import { FiMail, FiUser } from "react-icons/fi";
import { TfiPieChart } from "react-icons/tfi";
import { RiBankLine } from "react-icons/ri";
import { LiaCcAmazonPay } from "react-icons/lia";
import { PiNewspaperClippingThin } from "react-icons/pi";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import { GrNotes } from "react-icons/gr";
import { GiStabbedNote } from "react-icons/gi";

export const navdata = [
  {
    text: "Personal information",
    stepIndex: 1,
  },
  {
    text: "Assets",
    stepIndex: 2,
  },
  {
    text: "Debt",
    stepIndex: 3,
  },
  {
    text: "Risk Tolerence",
    stepIndex: 4,
  },
  {
    text: "Desired retirement age",
    stepIndex: 5,
  },
  {
    text: "Work in Retirement",
    stepIndex: 6,
  },
  {
    text: "Social security benefits",
    stepIndex: 7,
  },
  {
    text: "Desired income in retirement",
    stepIndex: 8,
  },
];

export const dashboardNavItems = [
  {
    label: "Dashboard",
    url: "/",
    icon: <MdOutlineDashboard />,
  },
  {
    label: "Inbox",
    url: "/inbox",
    icon: <FiMail />,
  },
  {
    label: "Budget",
    url: "/budget",
    icon: <TfiPieChart />,
  },
  {
    label: "Events",
    url: "/events",
    icon: <MdEventNote />,
  },
  {
    label: "Smart Plan",
    url: "/smart-plan-all",
    icon: <GiStabbedNote />,
  },
  {
    label: "Accounts",
    url: "/accounts",
    icon: <RiBankLine />,
  },
  {
    label: "Debt Plan",
    url: "/debt",
    icon: <LiaCcAmazonPay />,
  },
  {
    label: "Bills",
    url: "/bills",
    icon: <PiNewspaperClippingThin />,
  },
  {
    label: "Goals",
    url: "/goals",
    icon: <HiOutlineClipboardDocumentCheck />,
  },
  {
    label: "My Courses",
    url: "/programs",
    icon: <GrNotes />,
  },
  {
    label: "All Courses",
    url: "/allPrograms",
    icon: <GrNotes />,
  },
  // {
  //   label: "Media",
  //   url: "/media",
  //   icon: <LiaFileVideoSolid />,
  // },
  // {
  //   label: "Reports",
  //   url: "/reports",
  //   icon: <TfiStatsUp />,
  // },
  // {
  //   label: "Savings",
  //   url: "/savings",
  //   icon: <MdOutlineSavings />,
  // },
  {
    label: "Profile",
    url: "/profile",
    icon: <FiUser />,
  },
];
