import React, { useContext, useEffect } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import FooterButtons from "./FooterButtons";
import InputRequired from "../Input/InputRequired";
import stepImage from "../../Assets/sm-10.png";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import FormWrapper from "./FormWrapper";
import { useFormik } from "formik";
import { step13Schema } from "../../Schema";

const Step13 = ({ handleNext, handleBack, direction }) => {
  const { step11Data, step12Data, step13Data, setStep13Data } =
    useContext(SmartPlanContext);

  const initialState = step13Data;

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: step13Schema,
      onSubmit: (values) => {
        setStep13Data(values);
        handleNext();
      },
    });

  useEffect(() => {
    if (step11Data === "own" && step12Data === "yes") return;
    else {
      if (direction === "next") handleNext();
      else handleBack();
    }
  }, []);
  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Mortgage Details" />
        <div className="py-2">
          <InputWrapper>
            <InputRequired
              label="Home Value"
              placeholder="$ 0"
              type="number"
              name="homeValue"
              value={values.homeValue}
              touch={touched.homeValue}
              error={errors.homeValue}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>
          <InputWrapper>
            <InputRequired
              label="Loan Balance"
              placeholder="$ 0"
              type="number"
              name="loanBalance"
              value={values.loanBalance}
              touch={touched.loanBalance}
              error={errors.loanBalance}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>
          <InputWrapper>
            <InputRequired
              label="Monthly Payment"
              placeholder="$ 0"
              type="number"
              name="monthlyPayment"
              value={values.monthlyPayment}
              touch={touched.monthlyPayment}
              error={errors.monthlyPayment}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>
          <InputWrapper>
            <InputRequired
              label="Interest Rate"
              placeholder="0"
              type="number"
              name="interestRate"
              value={values.interestRate}
              touch={touched.interestRate}
              error={errors.interestRate}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>

          <FooterButtons onNext={handleSubmit} onBack={handleBack} />
        </div>
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step13;
