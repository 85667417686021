import axios from "axios";
import {
  successToast,
  errorToast,
  structureDebtPlan,
  returnTypeIndex,
  formatScheduleData,
  budgetDestructure,
  formatDateArray,
} from "../../Utils";
import { removeToken, setToken } from "../../Utils/tokenutil";
import api from "./axiosInstance";
let clientId = localStorage.getItem("clientId") || "";

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   withCredentials: true,
//   headers: {
//     "Content-type": "application/json",
//     Accept: "application/json",
//   },
// });

export const login = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/login", payload);
    clientId = response.data.data.id;
    localStorage.setItem("clientId", response.data.data.id);
    setToken(response.data);
    dispatch({ type: "LOGIN", payload: response.data });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const signup = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/signup", payload);
    return response;
  } catch (error) {
    errorToast(error?.response?.data?.message || "Something went wrong");
    throw error;
  }
};

export const logout = () => async (dispatch) => {
  try {
    const response = await api.post("/logout");
    removeToken();
    dispatch({ type: "LOGOUT" });
    return;
  } catch (error) {
    console.log(error);
  }
};

export const emailVerification = async (payload) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/forgotPassword",
      payload
    );
    return response.data;
  } catch (error) {
    if (error?.response?.data?.statusCode === 401) {
      errorToast("Invalid email address");
      throw error;
    } else {
      console.log(error);
      throw error;
    }
  }
};

export const verifyOtp = (userId, payload) => async (dispatch) => {
  // try {
  const response = await axios.post(
    process.env.REACT_APP_API_URL + `/verifyCode/${userId}`,
    payload
  );
  // console.log("Response ===>", response.data);
  return response.data;
  // } catch (error) {
  //   if (error?.response?.data?.statusCode === 400) {
  //     errorToast("Incorrect OTP");
  //     throw error;
  //   } else {
  //     console.log(error);
  //     throw error;
  //   }
  // }
};

export const changePasswordVerify = async (payload) => {
  try {
    const response = await api.patch("/changePasswordVerify", {
      userId: payload.userId,
      password: payload.password,
    });
    successToast("Password updated successfully");
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateSetting = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/updateMe", payload);
    dispatch({ type: "UPDATE_USER", payload: payload });
    successToast("Setting updated successfully");
    return;
  } catch (error) {
    console.log(error);
  }
};

export const getCoachInformation = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/fetchCoach?endpoint=${payload}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDashboardData = () => async (dispatch) => {
  try {
    const response = await api.get(`/clientDashboard/${clientId}`);
    dispatch({ type: "FETCH_DASHBOARD", payload: response.data });
    return response;
  } catch (error) {
    console.log(error);
    console.log(error);
    throw error;
  }
};

export const getAssignedProgram = () => async (dispatch) => {
  try {
    const response = await api.get(`/getClientPrograms/${clientId}`);
    dispatch({ type: "FETCH_ASSIGN_PROGRAM", payload: response.data.data });
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const getCoachAllPrograms = (coachId) => async (dispatch) => {
  try {
    const response = await api.get(`/getProgram/${coachId}`);

    dispatch({ type: "FETCH_COACH_PROGRAM", payload: response.data });
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const assignProgramSelf = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/AssignProgram", payload);
    successToast("Program subscribed successfully");
    localStorage.removeItem("programBody");
    dispatch(getAssignedProgram());

    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

// ================================
//         Budget Actions
// ================================

export const getBudgetInfo = (month, year) => async (dispatch) => {
  try {
    const response = await api.get(`/getBudget/${clientId}/${month}/${year}`);
    dispatch({ type: "FETCH_BUDGETS", payload: response.data });
    if (response.data) {
      if (response.data.data.length) {
        let checkIncome = returnTypeIndex(response.data.data, "Income");
        let checkExpense = returnTypeIndex(response.data.data, "Expense");
        if (checkIncome !== -1) {
          dispatch({
            type: "ADD_INCOME",
            payload: response.data.data[checkIncome].totalAmount,
          });
        }
        if (checkExpense !== -1) {
          dispatch({
            type: "ADD_EXPENSE",
            payload: response.data.data[checkExpense].totalAmount,
          });
        }
      }
    }
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const addBudgetInfo = (payload) => async (dispatch) => {
  try {
    const { structuredIncome, structuredExpense } = budgetDestructure(payload);
    const response = await api.post("/addBudget", {
      clientId: clientId,
      data: [...structuredIncome, ...structuredExpense],
    });
    successToast("Budget added successfully");
    return;
  } catch (error) {
    console.log(error);
  }
};

export const createSmartPlan = async (payload) => {
  try {
    payload.userId = clientId;
    const response = await api.post(`/SmartPlan`, payload);
    successToast("Smart Plan created successfully");
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const updateSmartPlan = async (payload) => {
  try {
    payload.userId = clientId;
    const smartplanId = localStorage.getItem('smartplanId')
    const response = await api.patch(`/SmartPlan?id=${smartplanId}`, payload);
    successToast("Smart Plan updated successfully");
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSmartPlan = () => async (dispatch) => {
  try {
    const response = await api.get(`/SmartPlan?userId=${clientId}`);
    dispatch({ type: "FETCH_SMARTPLAN", payload: response.data });
    localStorage.setItem("smartplanId", response.data.data[0]._id);
    return;
  } catch (error) {
    console.log(error);
  }
};

export const deleteSmartPlan = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/SmartPlan?id=${payload}`);
    dispatch({ type: "CLEAR_SMP_STATE" });
    successToast("Smart Plan deleted successfully");
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getBudgetTypes = () => async (dispatch) => {
  try {
    const response = await api.get("/BudgetAllData");
    dispatch({ type: "FETCH_TYPES", payload: response.data });
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const deleteBudgetCategory = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(
      `deleteUserCategoryBudget?name=${payload}&userId=${clientId}`
    );
    dispatch(getBudgetInfo());
  } catch (error) {
    console.error(error);
    console.log("Something went wrong");
  }
};

export const deleteBudgetSubCategory = async (payload) => {
  try {
    const response = await api.delete(`/deleteBudget/${payload.id}`);
    successToast("Budget deleted successfully");
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getCustomerBudget = () => async (dispatch) => {
  try {
    const response = await api.get(`/ClientBudget/${clientId}`);
    dispatch({
      type: "FETCH_BUDGET_HISTORY",
      payload: {
        income: response?.data?.data?.Income || [],
        expense: response?.data?.data?.Expense || [],
        expenseType: response?.data?.data?.expenseTypeId,
      },
    });
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const viewOrDownloadClientFinancialReport = async (payload) => {
  try {
    const response = await api.get(`/clientReport/${payload}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getFinancialReport = () => async (dispatch) => {
  try {
    const response = await api.get(`/getClientReport`, {
      userId: clientId,
    });
    dispatch({ type: "FETCH_FINANCIAL_REPORT", payload: response.data });
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

// ================================
//         Account Actions
// ================================

export const getAccountsInfo = () => async (dispatch) => {
  try {
    const response = await api.get(`/account/${clientId}`);
    if (response?.data?.data?.length) {
      dispatch({ type: "FETCH_ACCOUNTS", payload: response.data });
      return;
    }
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const addAccountInfo = (payload) => async (dispatch) => {
  try {
    payload.clientId = clientId;
    const response = await api.post("/account", payload);
    dispatch({ type: "ADD_ACCOUNT", payload: response.data });
    successToast("Account Added successfully");
    return;
  } catch (error) {
    console.log(error);
  }
};

export const deleteAccount = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/account?id=${payload}`);
    dispatch({ type: "DELETE_ACCOUNTS", payload: payload });
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

// ================================
//         Bills Actions
// ================================

export const getBillsInfo = () => async (dispatch) => {
  try {
    const response = await api.get(`/bills/${clientId}`);
    if (response?.data?.data?.length) {
      dispatch({ type: "FETCH_BILLS", payload: response.data });
      return;
    }
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const addBillsInfo = (payload) => async (dispatch) => {
  try {
    payload.clientId = clientId;
    const response = await api.post(`/bills`, payload);
    dispatch({ type: "ADD_BILL", payload: response.data });
    dispatch({ type: "ADD_BILL_COUNT" });
    successToast("Bill added successfully");
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const updateBillsInfo = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/bills", payload);
    dispatch({ type: "UPDATE_BILL", payload: response.data });
    successToast("Bill Updated successfully");
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const deleteBillsInfo = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/bills?id=${payload}`);
    dispatch({ type: "DELETE_BILL", payload: payload });
    successToast("Bill deleted successfully");
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

// ================================
//         Debt Actions
// ================================

export const addDeptInfo = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/debt", payload);
    console.log("Response ===>", response.data);
    dispatch({ type: "ADD_DEBT", payload: response.data });
    successToast("Debt added successfully");
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const getDebptPlan = () => async (dispatch) => {
  try {
    const response = await api.get(`/myDebtPlan?clientId=${clientId}`);
    if (response.data.data) {
      let resStructeredData = structureDebtPlan(response.data.data);
      dispatch({ type: "FETCH_PLAN", payload: resStructeredData });
      return resStructeredData;
    }
    return;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getDebptPlanResult =
  (payload = "default") =>
    async (dispatch) => {
      try {
        const response = await api.get(
          `/debtPlan?clientId=${clientId}&strategy=${payload}`
        );
        dispatch({ type: "FETCH_PLAN_RESULT", payload: response.data });
      } catch (error) {
        console.log(error);
        console.log(error);
      }
    };

export const addDeptPlan = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/debtPlan?clientId=${clientId}`, payload);
    let resStructeredData = structureDebtPlan(response.data.data);
    dispatch({ type: "FETCH_PLAN", payload: resStructeredData });
    dispatch(getDebptPlanResult());
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const getSchedule = () => async (dispatch) => {
  try {
    const response = await api.get(`/getSchedule?clientId=${clientId}`);
    if (response.data?.data?.length) {
      const structureResponse = formatScheduleData(response.data.data);
      dispatch({ type: "FETCH_SCHEDULE", payload: structureResponse });
    }
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

// ================================
//         GOALS Actions
// ================================
export const getGoalsInfo = () => async (dispatch) => {
  try {
    const response = await api.get(`/getGoal/${clientId}`);
    dispatch({ type: "FETCH_GOALS", payload: response.data });
  } catch (error) {
    console.log(error);
  }
};

export const addGoal = (payload) => async (dispatch) => {
  try {
    payload.clientId = clientId;
    const response = await api.post("/Goal", payload);
    dispatch(getGoalsInfo());
    successToast("Goal added successfully");
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const updateGoal = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/Goal", payload);
    successToast("Goal updated successfully");
    dispatch(getGoalsInfo());
    return;
  } catch (error) {
    console.log("Error add goals ===>", error);
    console.log(error);
  }
};

export const deleteGoal = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/Goal?id=${payload}`);
    successToast("Goal deleted successfully");
    dispatch({ type: "DELETE_GOAL", payload: { _id: payload } });
    return;
  } catch (error) {
    console.log("Error add goals ===>", error);
    console.log(error);
  }
};

export const completeGoal = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/completeGoal/${payload}`);
    successToast("Goal marked as completed");
    dispatch(getGoalsInfo());
    return;
  } catch (error) {
    errorToast("Something went wrong while completing goal");
    throw error;
  }
};

export const getAllMeetings = () => async (dispatch) => {
  try {
    const response = await api.get(`/getSchedule/Client/${clientId}`);
    dispatch({
      type: "FETCH_EVENTS",
      payload: formatDateArray(response?.data?.data),
    });
    return;
  } catch (error) {
    throw error;
  }
};

// api.interceptors.response.use(
//   (config) => {
//     return config;
//   },
//   async (error) => {
//     const originalRequest = error.config;

//     if (
//       error.response.status === 401 &&
//       originalRequest &&
//       !originalRequest._isRetry
//     ) {
//       originalRequest._isRetry = true;
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}/refreshToken`,
//           {
//             withCredentials: true,
//           }
//         );
//         clientId = response.data.data.id;
//         localStorage.setItem("clientId", response.data.data.id);
//         authReducer({ type: "LOGIN", payload: response.data });
//         return api.request(originalRequest);
//       } catch (error) {
//         authReducer({ type: "LOGOUT" });
//       }
//     }
//     throw error;
//   }
// );

export const getCoachPackages = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/CoachPackage/${payload}`);
    dispatch({ type: "FETCH_PACKAGES", payload: response.data });
    return;
  } catch (error) {
    console.log(error);
    console.log(error);
  }
};

export const subscribeToPackage = async (payload) => {
  try {
    const response = await api.post(`/addClientPackage/${clientId}`, payload);
    successToast("Package subscribed successfully");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStripeKey = (userId) => async (dispatch) => {
  try {
    const response = await api.get(`/StripeKey/${userId}`);
    if (response?.data) {
      if (response?.data?.data?.secretKey) {
        dispatch({
          type: "ADD_STRIPE_KEY",
          payload: {
            data: {
              publicKey: response.data.data.publicKey,
            },
          },
        });
      }
    }
    return;
  } catch (error) {
    console.log(error);
  }
};

export const checkLogin = () => async (dispatch) => {
  const refreshToken = localStorage.getItem("cu_refresh");

  if (!refreshToken) {
    throw new Error("No refresh token found. Please log in again.");
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/refreshToken`,
      {
        refreshToken: JSON.parse(refreshToken),
      }
    );
    clientId = response.data.data.id;
    localStorage.setItem("clientId", response.data.data.id);
    setToken(response.data);

    dispatch({ type: "LOGIN", payload: response.data });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadPhoto = async (payload) => {
  try {
    const formData = new FormData();
    formData.append("photo", payload.photo);
    const response = await api.post("/photo", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    errorToast("Something went wrong while uploading photo");
    throw error;
  }
};

//  ===============================================
//              Chat Api
//  ===============================================
export const getAllUsers = () => async (dispatch) => {
  try {
    const response = await api.get(`/getUsersToChat/Client/${clientId}`);
    dispatch({ type: "CHAT_ALL_USER", payload: response.data });
    return;
  } catch (error) {
    console.error(error);
  }
};

export const getListedChat = () => async (dispatch) => {
  try {
    const response = await api.get(`/getMessages/${clientId}`);
    dispatch({ type: "CHAT_LISTING", payload: response.data });
    return;
  } catch (error) {
    console.error(error);
  }
};

export const sendMessage = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/sendMessage`, payload);
    dispatch({
      type: "ADD_SEND_MSG",
      payload: response.data,
    });
    return response;
  } catch (error) {
    console.error(error);
    errorToast("Message sending failed");
    throw error;
  }
};

export const readMessage = async (payload) => {
  try {
    await api.post(`/readMessage`, payload);
    return;
  } catch (error) {
    console.error(error);
  }
};

export const deleteChat = (messages, targetChat) => async (dispatch) => {
  try {
    const response = await api.post("/deleteMessage", {
      messageId: messages,
      type: "chat",
    });
    dispatch({
      type: "DELETE_CHAT",
      payload: { id: targetChat },
    });
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong while deleting chat");
  }
};

export const deleteSingleMessage = (paylaod) => async (dispatch) => {
  try {
    const response = await api.post("/deleteMessage", {
      messageId: paylaod.message,
      type: "message",
    });
    dispatch({
      type: "DELETE_MESSAGE",
      payload: { senderId: paylaod.targetChat, messageId: paylaod.message[0] },
    });
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong while deleting message");
  }
};

export const getUserUnreadMessages = async () => {
  try {
    const response = await api.get(`getUnreadCount/${clientId}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const changeSelfPassword = async (payload) => {
  try {
    await api.patch("/changePassword", payload);
    successToast("Password changed successfully");
    return;
  } catch (error) {
    console.log(error);
    errorToast(error?.response?.data?.message || "Something went wrong");
  }
};

// coach email update
export const updateEmail = (payload) => async (dispatch) => {
  try {
    // const res = await api.patch(`/updateUser/${payload.userId}`, {
    const res = await api.patch(`/updateMe`, {
      email: payload.newEmail,
    })
    return res;
  } catch (error) {
    console.log('Error in updateEmail', error);
  }
}
