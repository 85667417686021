import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import FluidCard from "../../components/Card/FluidCard";
import TablesWrapper from "../../components/Wrapper/TablesWrapper";

import { IoMdAdd } from "react-icons/io";
// import ButtonSmall from "../../components/Buttons/ButtonSmall";
// import TableHeader from "../../components/Table/TableHeader";
// import DueRow from "../../components/Table/DueRow";
import AddEditBillModal from "../../components/Modal/AddBillModal";
import DeleteBill from "../../components/Modal/DeleteBill";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getBillsInfo } from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import UnavailableService from "../../components/Message/UnavailableService";
import useHasPermission from "../../Utils/permissionUtils";
import TextModal from "../../components/Modal/TextModal";

let Once = true;

const Bills = () => {
  const hasPermission = useHasPermission("Bill payments");
  const hasPermissionToCreate = useHasPermission("Add New Bill");
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    modal: false,
    id: null,
  });
  const dispatch = useDispatch();
  const billData = useSelector((state) => state.bills.data);
  const [textModal, setTextModal] = useState(false);

  function handleCloseModal() {
    setEditData({});
    setIsEdit(false);
    setModal(false);
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(billData)) {
        setLoading(true);
        dispatch(getBillsInfo()).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {textModal && (
            <TextModal
              open={textModal}
              isPermission={true}
              handleClose={() => {
                setTextModal(false);
              }}
            />
          )}
          {modal ? (
            <AddEditBillModal
              open={modal}
              handleModal={handleCloseModal}
              isEdit={isEdit}
              editData={editData}
            />
          ) : null}
          {deleteModal.modal ? (
            <DeleteBill
              handleClose={() => {
                setDeleteModal({ modal: false, id: null });
              }}
              open={deleteModal.modal}
              data={deleteModal.id}
            />
          ) : null}
          {hasPermission ? (
            <>
              <HeaderData text="Bills">
                <div className="flex md:justify-between items-start md:items-center flex-col md:flex-row gap-2 lg:gap-4">
                  <ButtonIcon
                    onclick={() => {
                      if (hasPermissionToCreate) {
                        setIsEdit(false);
                        setModal(true);
                      } else {
                        setTextModal(true);
                      }
                    }}
                    icon={<IoMdAdd />}
                    text="Add new bill"
                  />
                </div>
              </HeaderData>
              {billData.length ? (
                <TablesWrapper customClasses={"lg:flex-row"}>
                  <div className="w-full lg:w-[95%]">
                    <CardWrapper extraClasses="!py-0">
                      {billData.map((item, index) => {
                        return (
                          <FluidCard
                            withGraph={false}
                            text={item.name}
                            value={`$ ${item.amount}`}
                            withOptions={true}
                            onEdit={() => {
                              setEditData(item);
                              setIsEdit(true);
                              setModal(true);
                            }}
                            onDelete={() => {
                              setDeleteModal({
                                modal: true,
                                id: item._id,
                              });
                            }}
                          />
                        );
                      })}
                    </CardWrapper>
                  </div>
                  {/* <div className="w-full lg:w-[33%] bg-white rounded-lg pl-2">
                <TableHeader customClasses="text-[--red]" text="Due alerts" />
                <div className="w-full border-t">
                  <DueRow />
                  <DueRow />
                  <DueRow />
                  <DueRow />
                </div>
              </div> */}
                </TablesWrapper>
              ) : (
                <p>No bill data found</p>
              )}
            </>
          ) : (
            <UnavailableService />
          )}
        </>
      )}
    </>
  );
};

export default Bills;
