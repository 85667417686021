import React, { useState } from "react";
import Modal from "./Modal";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { deleteBudgetSubCategory, getBudgetInfo } from "../../Redux/Actions";
import { useDispatch } from "react-redux";

const DeleteSubBudget = ({ open, handleClose, data, closeParentModal }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();

  function handleDeleteSubCategory() {
    setBtnLoading(true);
    deleteBudgetSubCategory(data)
      .then((res) => {
        dispatch(getBudgetInfo()).then(() => {
          setBtnLoading(false);
          closeParentModal();
          handleClose();
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
  return (
    <Modal open={open} onclick={handleClose} headerText={data.header}>
      <p className="text-[18px] text-center">
        Do you want to delete this budget ?{" "}
      </p>
      <div className="flex justify-center items-center gap-4 pt-8">
        {btnLoading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-[30%] !bg-white !text-[--primary]"}
              onClick={handleClose}
            />
            <ButtonSmall
              onClick={handleDeleteSubCategory}
              type="button"
              text="Confirm"
              customClasses={"!w-[30%] "}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteSubBudget;
