const initialState = {
  data: [],
};

const programReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_ASSIGN_PROGRAM":
      return {
        ...state,
        data: payload.program,
      };
    default:
      return state;
  }
};

export default programReducer;
