import React from "react";
import { useSelector } from "react-redux";
import defaultProfile from "../../Assets/default-profile.png";

const DashboardHeader = () => {
  const userData = useSelector((state) => state.auth.userData);
  return (
    <header className="w-full h-[70px] p-1 border-b border-gray-300 flex justify-between items-center pr-2 md:pr-4 lg:pr-6">
      <div className="px-1 md:px-3 flex justify-start items-center ">
        <div className="h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] rounded-full">
          <img
            src={
              userData?.photo
                ? process.env.REACT_APP_IMAGE_URL + userData.photo
                : defaultProfile
            }
            className="w-full h-full rounded-full"
            alt="User Avatar"
          />
        </div>
        <div className="flex justify-center items-start flex-col ml-3">
          <h4 className="satoshi-700 text-[14px]  md:text-[16px]">
            {userData?.firstName + "  " + userData?.lastName}
          </h4>
          <p className="text-[10px] md:text-[13px] lg:text-[15px]">
            {userData?.email}
          </p>
        </div>
      </div>
      {/* <div className="relative w-[45%] md:w-[40%] lg:w-[35%] text-[--gray] bg-[#E0E0E0] border rounded-full">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          <button
            type="submit"
            className="p-1 focus:outline-none focus:shadow-outline"
          >
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              className="w-3 h-3 sm:w-5 sm:h-5 lg:w-6 lg:h-6"
            >
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </button>
        </span>
        <input
          type="search"
          name="q"
          className="py-2 text-[11px] sm:text-[13px] lg:text-sm w-[98%] bg-inherit rounded-md pl-8 sm:pl-10 lg:pl-12 outline-none text-black"
          placeholder="Search"
        />
      </div> */}
    </header>
  );
};

export default DashboardHeader;
