import React from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

const MultiAreaChart = ({ data }) => {
  return (
    <div className="w-full h-[160px] md:h-[200px] lg:h-[240px]">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          {/* <CartesianGrid /> */}
          <XAxis dataKey="month" />
          <YAxis fontSize={12} />
          {/* <Tooltip /> */}

          <Area
            type="monotone"
            dataKey="income"
            stackId="1"
            stroke="green"
            fill="rgb(22 163 74 / 35%)"
          />
          <Area
            type="monotone"
            dataKey="expense"
            stackId="2"
            stroke="red"
            fill="rgb(248 54 54 / 35%)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MultiAreaChart;
