import React from "react";

const CheckBox = ({ label, name, checked, handleChange, customClasses }) => {
  return (
    <div className="flex justify-center items-center">
      <input
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        onChange={handleChange}
      />
      <label
        htmlFor={name}
        className={`ml-2 text-[13px] cursor-pointer ${customClasses}`}
      >
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
