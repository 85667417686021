import React, { useEffect, useState } from "react";
import successIcon from "../../Assets/success.png";
import ButtonSmall from "../../components/Buttons/ButtonSmall";
import ButtonLoading from "../../components/Buttons/ButtonLoading";
import { useNavigate, useParams } from "react-router-dom";
import {
  assignProgramSelf,
  checkLogin,
  subscribeToPackage,
} from "../../Redux/Actions";
import { useDispatch } from "react-redux";
let Once = true;

const SuccessPayment = () => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleBuyProgram(programData) {
    setLoading(true);
    dispatch(
      assignProgramSelf({
        programId: id,
        coachId: programData.coachId,
        clientId: [programData.userId],
      })
    ).then(() => {
      setLoading(false);
    });
  }

  function buyPackage() {
    setLoading(true);
    subscribeToPackage({ packageId: id })
      .then((res) => {
        localStorage.removeItem("programBody");
        dispatch(checkLogin());
      })
      .catch((err) => {
        console.log(err);
        navigate("/notfound");
      });
  }

  useEffect(() => {
    if (Once) {
      const _programBody = JSON.parse(localStorage.getItem("programBody"));
      Once = false;
      if (!_programBody) {
        navigate("/");
      } else {
        if (_programBody.paymentType === "package") {
          buyPackage();
        } else {
          handleBuyProgram(_programBody);
        }
      }
    }
  }, []);

  return (
    <div className="w-screen h-screen flex justify-center items-center bg-[--bg-yellow-light]">
      <div className="py-3 px-4 mediaCardShadowm rounded-md bg-white flex justify-center items-center flex-col w-[90%] md:w-[60%] lg:w-[50%] max-w-[800px]">
        <div className=" bg-white px-3 py-6 sm:px-6 rounded-lg flex justify-center items-center flex-col">
          <div className=" w-[120px] h-[120px] sm:w-[160px] sm:h-[160px] md:w-[200px] md:h-[200px]">
            <img className="w-full h-full" src={successIcon} alt="tick here" />
          </div>
          <p className="mt-8 text-[--blue] text-center poppin-600 text-[14px] sm:text-[16px]">
            We recieved your payment. Please click below to confirm your
            package.
          </p>
          <div className="mx-auto px-3 mt-3 max-w-[500px]">
            {loading ? (
              <ButtonLoading />
            ) : (
              <ButtonSmall
                onClick={() => {
                  navigate("/");
                }}
                text="Confirm Payment"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPayment;
