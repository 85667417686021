export const genderData = [
  {
    label: "Male",
    name: "Male",
    id: "male",
  },
  {
    label: "Female",
    name: "Female",
    id: "female",
  },
];

export const monthsData = [
  {
    name: "January",
    id: "January",
  },
  {
    name: "February",
    id: "February",
  },
  {
    name: "March",
    id: "March",
  },
  {
    name: "April",
    id: "April",
  },
  {
    name: "May",
    id: "May",
  },
  {
    name: "June",
    id: "June",
  },
  {
    name: "July",
    id: "July",
  },
  {
    name: "August",
    id: "August",
  },
  {
    name: "September",
    id: "September",
  },
  {
    name: "October",
    id: "October",
  },
  {
    name: "November",
    id: "November",
  },
  {
    name: "December",
    id: "December",
  },
];

export function generateYears(min, max, optionalLabel = "Select Birth Year") {
  let yearsOptions = [];

  for (let i = min; i <= max; i++) {
    yearsOptions.push({
      name: `${i}`,
      id: i,
    });
  }

  return yearsOptions;
}

export const frequency = [
  {
    name: "Weekly",
    id: "weekly",
  },
  {
    name: "Bi Weekly",
    id: "bi-weekly",
  },
  {
    name: "Monthly",
    id: "monthly",
  },
  {
    name: "Yearly",
    id: "yearly",
  },
];

export const additionalIncome = [
  {
    name: "Business",
    id: "business",
  },
  {
    name: "Investment",
    id: "investment",
  },
  {
    name: "Property",
    id: "property",
  },
  {
    name: "Others",
    id: "others",
  },
];

export const accountTypes = [
  {
    name: "Saving Account",
    id: "Saving",
  },
  {
    name: "Checking Account",
    id: "Checkin",
  },
  {
    name: "Retirement Account",
    id: "Retirement",
  },
  {
    name: "Investment Account",
    id: "Investment",
  },
];

export const retirementAccountTypes = [
  {
    name: "401K",
    id: "401K",
  },
  {
    name: "IRA",
    id: "IRA",
  },
  {
    name: "ROTH IRA",
    id: "ROTH IRA",
  },
  {
    name: "403b",
    id: "403b",
  },
];

export const assetTypes = [
  {
    name: "House",
    id: "house",
  },
  {
    name: "Cash",
    id: "cash",
  },
  {
    name: "Gold",
    id: "gold",
  },
  {
    name: "Furniture",
    id: "furniture",
  },
  {
    name: "Collectible",
    id: "collectible",
  },
  {
    name: "Land",
    id: "land",
  },
  {
    name: "Boat",
    id: "boat",
  },
  {
    name: "Other",
    id: "other",
  },
];

export const debtType = [
  {
    name: "Credit Card",
    id: "credit_card",
  },
  {
    name: "Personal Loans",
    id: "personal_loans",
  },
  {
    name: "Student Loans",
    id: "student_loans",
  },
  {
    name: "Mortgage",
    id: "mortgage",
  },
  {
    name: "Other",
    id: "other",
  },
];
