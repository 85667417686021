import React, { useContext, useState } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import TypoGray from "./TypoGray";
import InputWrapper from "../Wrapper/InputWrapper";
import CheckBoxLarge from "../Input/CheckBoxLarge";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import FooterButtons from "./FooterButtons";
// import stepImage from "../../Assets/sm-2.png";
import stepImage from "../../Assets/sm-1.png";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import Errortxt from "./Errortxt";
import FormWrapper from "./FormWrapper";

const Step1 = ({ handleNext, handleBack }) => {
  const { step1Data, setStep1Data } = useContext(SmartPlanContext);
  const [error, setError] = useState(false);
  function handleChange(e) {
    setError(false);
    setStep1Data(e.target.value);
  }

  function submitStep() {
    if (!step1Data) {
      setError(true);
      return;
    }

    handleNext();
  }

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoGray
          customClasses={"max-w-[80%] mt-2 !uppercase  md:text-[16px]"}
          text="NICE TO MEET YOU!

"
        />

        <TypoPrimary text="Do you share your finances with someone else?" />
        <div className="py-4 w-full">
          <InputWrapper customClass={"!gap-6 !justify-start"}>
            <CheckBoxLarge
              icon={<MdCancel />}
              iconStyle={"text-red-400"}
              text="No"
              id={"no"}
              value="no"
              name="finance"
              selected={step1Data === "no"}
              onChange={handleChange}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
            />
            <CheckBoxLarge
              icon={<FaCheckCircle />}
              iconStyle={"text-green-400"}
              text="Yes"
              id={"yes"}
              value="yes"
              name="finance"
              selected={step1Data === "yes"}
              onChange={handleChange}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
            />
          </InputWrapper>
          {error && <Errortxt />}
          <FooterButtons onNext={submitStep} onBack={handleBack} />
        </div>
      </FormWrapper>
      <StepImage customHeight={"!h-[220px]"} image={stepImage} />
    </div>
  );
};

export default Step1;
