import React, { useEffect, useState } from "react";
import FormLayout from "../../Layouts/FormLayout";
import Form from "../../components/Form/Form";
import InputPassword from "../../components/Input/InputPassword";
import InputRequired from "../../components/Input/InputRequired";
import ButtonLarge from "../../components/Buttons/ButtonLarge";
import { useDispatch } from "react-redux";
import { login } from "../../Redux/Actions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputWrapper from "../../components/Wrapper/InputWrapper";
import { useFormik } from "formik";
import ButtonLoading from "../../components/Buttons/ButtonLoading";
import { loginSchema } from "../../Schema";
import TextModal from "../../components/Modal/TextModal";
import { errorToast } from "../../Utils";
import { useSocket } from "../../context/socketContext";

const initialState = {
  email: "",
  password: "",
  role: "Client",
};

const SignIn = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const socket = useSocket();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [modal, setModal] = useState({
    open: false,
    description: "",
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: loginSchema,
      onSubmit: () => {
        setLoading(true);
        dispatch(login(values))
          .then((res) => {
            if (socket.connected) {
              const data = {
                userId: res.data._id,
                socketId: socket.id,
              };
              socket.emit("create_connection", data);
            }
            setLoading(false);
            navigate(from, { replace: true });
          })
          .catch((err) => {
            console.error(err);
            if (err?.response?.data?.statusCode === 400) {
              errorToast("Incorrect email or password");
            } else if (err?.response?.data?.statusCode === 404) {
              setModal({
                open: true,
                description:
                  "Thank you for signing up! Your account is currently pending approval by the Coach. We appreciate your patience as we review your account details. Once approved, you will receive an email notification allowing you to log in and access our platform.If you have any urgent inquiries, please feel free to contact our support team. Thank you for choosing us!",
              });
            } else if (err?.response?.data?.statusCode === 403) {
              setModal({
                open: true,
                description:
                  "We regret to inform you that your account has been temporarily blocked by the Coach. If you believe this action is in error or wish to inquire about the status of your account,please contact our support team for assistance.We apologize for any inconvenience this may cause and appreciate your understanding.",
              });
            } else {
              errorToast("Something went wrong");
            }
            setLoading(false);
          });
      },
    });

  return (
    <FormLayout>
      {modal ? (
        <TextModal
          open={modal.open}
          handleClose={() => setModal(false)}
          description={modal.description}
        />
      ) : null}
      <Form headerText="Welcome back"
        headingText={'Welcome back! Sign in to access your account.'}
        onSubmit={handleSubmit}>
        <InputWrapper>
          <InputRequired
            label="Email"
            type="email"
            id="email"
            name="email"
            value={values.email}
            error={errors.email}
            touch={touched.email}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="johndoe@gmail.com"
          />
        </InputWrapper>
        <InputWrapper customClass="!mt-1">
          <InputPassword
            id="password"
            name="password"
            label="Password"
            value={values.password}
            error={errors.password}
            touch={touched.password}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Enter password"
          />
        </InputWrapper>
        <div className="w-full text-right">
          <Link
            to="/forget-password"
            className="text-blue-800 satoshi-500 underline text-[14px] lg:text-[16px] transition-all ease-in-out duration-500 hover:text-blue-400"
          >
            Forgot Password?
          </Link>
        </div>
        <div className="mt-2 w-full">
          {loading ? <ButtonLoading /> : <ButtonLarge text="Sign In" />}
        </div>
        {/* <ButtonWrapper text="Or Sign in with">
          <ButtonImage img={googleImage} text="Sign In with Google" />
          <ButtonImage img={facebookImage} text="Sign In with Facebook" />
        </ButtonWrapper> */}
      </Form>
      {/* <FormFooter text="Don't have account ?" url="/signup" urltext="Sign Up" /> */}
    </FormLayout>
  );
};

export default SignIn;
