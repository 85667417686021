import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const PiChart = ({ colors, customData }) => {
  let chartData = [];
  customData.forEach((transaction) => {
    chartData.push({
      name: transaction.categoryName,
      value: transaction.totalRecordsAmount,
    });
  });
  return (
    <PieChart
      width={180}
      height={180}
      className="flex justify-center items-center overflow-hidden"
    >
      <Pie
        data={chartData}
        innerRadius={45}
        outerRadius={80}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="value"
      >
        {chartData.map((entry, index) => (
          <Cell
            strokeWidth={0}
            key={`cell-${index}`}
            fill={colors[index % colors.length]}
          />
        ))}
      </Pie>
    </PieChart>
  );
};

export default PiChart;
