import React from "react";

const RowWrapper = ({ title, value, customClasses }) => {
  return (
    <div className="w-full flex justify-between items-stretch gap-2 mt-2">
      <h6 className="satoshi-500 text-[12px] sm:text-[14px] min-w-max">
        {title} :
      </h6>
      <h6 className={`text-[12px] sm:text-[14px] capitalize ${customClasses}`}>
        {value}
      </h6>
    </div>
  );
};

export default RowWrapper;
