import React, { useContext, useEffect, useState } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import TypoGray from "./TypoGray";
import InputWrapper from "../Wrapper/InputWrapper";
import CheckBoxLarge from "../Input/CheckBoxLarge";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import FooterButtons from "./FooterButtons";
import stepImage from "../../Assets/sm-3.png";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import Errortxt from "./Errortxt";
import FormWrapper from "./FormWrapper";

const Step3 = ({ handleNext, handleBack, direction }) => {
  const { step1Data, step3Data, setStep3Data, setStep4Data } =
    useContext(SmartPlanContext);
  const [error, setError] = useState(false);

  function handleChange(e) {
    setError(false);
    setStep3Data(e.target.value);
  }

  function submitStep() {
    if (!step3Data) {
      setError(true);
      return;
    }

    handleNext();
  }

  useEffect(() => {
    if (step1Data === "no") {
      setStep4Data({
        companyName: null,
        incomeAmount: null,
        frequency: null,
        additionalIncome: null,
      });
      if (direction === "next") handleNext();
      else handleBack();
    }
  }, []);

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      {/* <div className="w-1/2 py-2 pl-10 pr-4"> */}
      <FormWrapper>
        <TypoGray
          customClasses={"max-w-[80%] mt-2 !uppercase  md:text-[16px]"}
          text="NOW LET’S TALK ABOUT INCOME.

"
        />
        <TypoPrimary text="Are you or your spouse currently earning income from a job?" />

        <div className="py-4">
          <InputWrapper customClass={"!gap-6"}>
            <CheckBoxLarge
              icon={<MdCancel />}
              iconStyle={"text-red-400"}
              id={"no"}
              value="no"
              name="income"
              selected={step3Data === "no"}
              onChange={handleChange}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
              text="No, we don’t earn income"
            />
            <CheckBoxLarge
              icon={<FaCheckCircle />}
              id={"yes"}
              value="yes"
              name="income"
              selected={step3Data === "yes"}
              onChange={handleChange}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
              iconStyle={"text-green-400"}
              text="Yes, we earn income"
            />
          </InputWrapper>
          {error && <Errortxt />}
          <FooterButtons onNext={submitStep} onBack={handleBack} />
        </div>
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step3;
