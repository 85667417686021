import React from "react";
import errorIcon from "../../Assets/error.png";
import { useNavigate } from "react-router-dom";

const ErrorPayment = () => {
  const navigate = useNavigate();
  return (
    <div className="w-screen h-screen flex justify-center items-center bg-[--bg-yellow-light]">
      <div className="py-3 px-4 mediaCardShadowm rounded-md bg-white flex justify-center items-center flex-col w-[90%] md:w-[60%] lg:w-[50%] max-w-[800px]">
        <div className=" bg-white px-3 py-6 sm:px-6 rounded-lg flex justify-center items-center flex-col">
          <div className=" w-[120px] h-[120px] sm:w-[160px] sm:h-[160px] md:w-[200px] md:h-[200px]">
            <img className="w-full h-full" src={errorIcon} alt="tick here" />
          </div>
          <p className="mt-8 text-[--blue] text-center poppin-600 text-[14px] sm:text-[16px]">
            OOPS! your payment is not completed due to error. Please try again
            later
          </p>
          <button
            onClick={() => navigate("/")}
            className="mt-5 px-12 border border-[--primary] bg-[--primary] py-2 rounded-full text-white text-[18px] transition-all duration-300 ease-in-out hover:bg-white hover:text-[--blue]"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPayment;
