import React, { useContext, useEffect, useState } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import FooterButtons from "./FooterButtons";
import stepImage from "../../Assets/sm-3.png";
import InputRequired from "../Input/InputRequired";
import FormWrapper from "./FormWrapper";
import CheckBoxLarge from "../Input/CheckBoxLarge";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import Errortxt from "./Errortxt";

const Step22 = ({ handleNext, handleBack, direction }) => {
  const { step1Data, step22Data, setStep22Data } = useContext(SmartPlanContext);
  const [error, setError] = useState(false);
  const [scoreError, setScoreError] = useState("");

  function handleChange(e) {
    setError(false);
    setScoreError("");
    setStep22Data((pre) => {
      return {
        ...pre,
        [e.target.name]: e.target.value,
      };
    });
  }

  function submitStep() {
    if (!step22Data.spouseIsCreditScore) {
      setError(true);
      return;
    } else if (
      step22Data.spouseIsCreditScore === "yes" &&
      !step22Data.spouseCreditScoreCount
    ) {
      setScoreError("Field is required");
      return;
    } else {
      handleNext();
    }
  }

  useEffect(() => {
    if (step1Data === "no") {
      setStep22Data({
        spouseIsCreditScore: null,
        spouseCreditScoreCount: null,
      });
      if (direction === "next") handleNext();
      else handleBack();
    }
  }, []);

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary
          customClasses={"!mt-4"}
          text="Does your know your spouse credit score?"
        />

        <div className="py-4">
          <InputWrapper customClass={"!gap-6 !justify-start"}>
            <CheckBoxLarge
              icon={<MdCancel />}
              iconStyle={"text-red-400"}
              id={"no"}
              value="no"
              name="spouseIsCreditScore"
              selected={step22Data.spouseIsCreditScore === "no"}
              onChange={handleChange}
              text="No"
            />
            <CheckBoxLarge
              icon={<FaCheckCircle />}
              id={"yes"}
              value="yes"
              name="spouseIsCreditScore"
              selected={step22Data.spouseIsCreditScore === "yes"}
              onChange={handleChange}
              iconStyle={"text-green-400"}
              text="Yes"
            />
          </InputWrapper>
          {error && <Errortxt />}

          {step22Data.spouseIsCreditScore === "yes" && (
            <InputWrapper>
              <InputRequired
                label="Credit Score"
                placeholder="0"
                type="number"
                name="spouseCreditScoreCount"
                value={step22Data.spouseCreditScoreCount}
                onChange={handleChange}
                touch={true}
                error={scoreError}
              />
            </InputWrapper>
          )}

          <FooterButtons onNext={submitStep} onBack={handleBack} />
        </div>
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step22;
