import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import FluidCard from "../../components/Card/FluidCard";
import TablesWrapper from "../../components/Wrapper/TablesWrapper";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import MultiAreaChart from "../../components/Charts/AreaChart";
import Rocket from "../../Assets/Rocket.png";
import ButtonSmall from "../../components/Buttons/ButtonSmall";
import { Link } from "react-router-dom";

let Once = true;

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { isInvoked, budget, totalAmount, accounts, goals, bills, data } = useSelector(
    (state) => state.dashboard
  );
  useEffect(() => {
    if (Once) {
      Once = false;
      if (!isInvoked) {
        setLoading(true);
        dispatch(getDashboardData()).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);
  return (
    <>
      <HeaderData text="Dashboard" />
      {loading ? (
        <Loader />
      ) : (
        <>
          <CardWrapper>
            {/* <FluidCard withGraph={true} text="Net worth" value="$ 300M" /> */}
            <FluidCard
              url="/budget"
              withGraph={false}
              withDropdown={false}
              text="Budget"
              value={totalAmount}
            />
            <FluidCard
              url="/bills"
              withGraph={false}
              withDropdown={false}
              text="Bills"
              value={bills}
            />

            <FluidCard
              url="/accounts"
              withGraph={false}
              withDropdown={false}
              text="Total Accounts"
              value={accounts}
            />

            <FluidCard
              url="/goals"
              withGraph={false}
              withDropdown={false}
              text="Total Goals"
              value={goals}
            />
          </CardWrapper>
          {data.length ? (
            <TablesWrapper customClasses="!py-2 lg:flex-row !gap-4">
              <div className="w-full lg:w-[67%] bg-white rounded-md ">
                <div className="py-4 w-full flex justify-between items-center">
                  <div className="px-2 md:px-2 lg:px-4">
                    <h5 className="satoshi-700 text-[13px] sm:text-[16px]">
                      Income & Expenses
                    </h5>
                  </div>
                  <div className="flex justify-center items-center gap-p pr-3">
                    <div className="pr-2 md:pr-8 flex justify-between items-start gap-1 md:gap-3 flex-col md:flex-row md:item-center">
                      <div className="flex justify-start items-center">
                        <div className="w-[8px] sm:w-[15px] h-[10px] md:h-[15px] bg-red-600"></div>
                        <h6 className="ml-2 text-[10px] md:text-[14px] satoshi-500">
                          Expenses
                        </h6>
                      </div>
                      <div className="flex justify-start items-center ">
                        <div className="w-[8px] sm:w-[15px] h-[10px] md:h-[15px] bg-green-600"></div>
                        <h6 className="ml-2 text-[10px] md:text-[14px]  satoshi-500">
                          Income
                        </h6>
                      </div>
                    </div>
                    {/* <SelectInput /> */}
                  </div>
                </div>
                <div className="w-[96%] mx-auto">
                  <MultiAreaChart data={data} />
                </div>
              </div>

              <div className="w-full lg:w-[33%] bg-black rounded-md px-5 pt-8 pb-6 flex justify-center items-center flex-col">
                <img
                  className="w-[50px] sm:w-[70px] h-[80px] sm:h-[100px] md:h-[120px]"
                  src={Rocket}
                  alt="Upgrade Icon"
                />
                <h5 className="satoshi-700 text-[14px]  md:text-[16px] lg:text-[18px] capitalize text-white py-3">
                  Upgrade your plan
                </h5>
                <p className="text-[#BDBDBD] text-[13px] pb-4">
                  Nulla velit eget viverra aliquet viverra quis.
                </p>
                <Link to={'/upgrade-package'} className="w-full">
                  <ButtonSmall
                    text="Upgrade Now"
                    type="button"
                    customClasses="!w-full !px-1"
                  />
                </Link>
              </div>
            </TablesWrapper>
          ) : null}
        </>
      )}
    </>
  );
};

export default Dashboard;

// const thStyle = {
//   textAlign: "start",
//   padding: "5px 3px",
//   color: "var(--gray)",
// };
