import React from "react";

const SelectBox = ({
  label,
  id,
  name,
  value,
  options,
  onchange,
  customClasses,
  labelStyle,
  multiStyle,
  error,
  touch,
  onBlur,
  placeholder,
}) => {
  return (
    <div className={`mt-2 ${multiStyle}`}>
      <label
        htmlFor={id}
        className={`block mb-1 pl-[2px] font-medium text-[14px] capitalize ${labelStyle}`}
      >
        {label}
      </label>
      <select
        className={`w-full rounded-md border outline-none py-2 px-2 bg-[--gray-light] text-black ${customClasses}`}
        name={name}
        onBlur={onBlur}
        id={id}
        onChange={onchange}
        value={value}
      >
        <option className="text-gray-500" value="">
          {placeholder}
        </option>
        {options.map((opt, index) => {
          return (
            <option className="text-black" key={index} value={opt.id}>
              {opt.name}
            </option>
          );
        })}
      </select>
      {error && touch ? (
        <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
          {error}
        </span>
      ) : null}
    </div>
  );
};

export default SelectBox;
