import React, { useState } from 'react';
import Form from '../../components/Form/Form';
import InputRequired from '../../components/Input/InputRequired';
import ButtonLoading from '../../components/Buttons/ButtonLoading';
import ButtonLarge from '../../components/Buttons/ButtonLarge';
import { otpSchema } from '../../Schema';
import { useFormik } from 'formik';
import { verifyOtp } from '../../Redux/Actions';
import { useDispatch } from 'react-redux';
import { errorToast } from '../../Utils';

const OtpVeri = ({ stepsValues, onNext }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: { inputCode: "" },
        validationSchema: otpSchema,
        onSubmit: (formValues) => {
            setLoading(true);
            dispatch(verifyOtp(stepsValues.data.data._id, {
                inputCode: formValues.inputCode,
                code: stepsValues.data.code,
            })).then((res) => {
                // console.log('res', res)
                localStorage.setItem('clientId', res?.data.clientInfo.coachId);
                localStorage.setItem('cu_access', JSON.stringify(res?.token));
                localStorage.setItem('cu_refresh', JSON.stringify(res?.refreshToken));
                dispatch({
                    type: 'LOGIN',
                    payload: res
                })
                setLoading(false);
                onNext();
            }).catch((error) => {
                if (error?.response?.data?.statusCode === 400) {
                    errorToast("Incorrect OTP");
                    setLoading(false)
                    // throw error;
                } else {
                    errorToast("Something went wrong");
                    setLoading(false)
                    // throw error;
                }
                // console.log("Error in OTP verification:", err);
            });
        },
    });

    console.log('OtpVerifyClient--', stepsValues)


    return (
        <Form headerText="OTP Verification" description="We have successfully sent an OTP to your email address." onSubmit={handleSubmit}>
            <div className='w-full '>
                <InputRequired
                    label="OTP"
                    type="number"
                    id="otp"
                    name="inputCode"
                    placeholder="Please enter OTP here"
                    value={values.inputCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.inputCode}
                    touch={touched.inputCode}
                />
            </div>
            {loading ? (
                <ButtonLoading />
            ) : (
                <>
                    <ButtonLarge onClick={handleSubmit} text="Verify OTP" />
                </>
            )}
        </Form>
    );
};

export default OtpVeri;
