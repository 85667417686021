import React from "react";

const Errortxt = ({ customClasses }) => {
  return (
    <p className={`text-red-700 satoshi-500 text-[14px] mt-3 ${customClasses}`}>
      Field is required
    </p>
  );
};

export default Errortxt;
