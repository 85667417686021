import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import InputRequired from "../Input/InputRequired";
import { useFormik } from "formik";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { updateGoalSchema } from "../../Schema";
import { useDispatch, useSelector } from "react-redux";
import { getAssignedProgram, updateGoal } from "../../Redux/Actions";
import Loader from "../Loading/Loader";
import parse from "html-react-parser";
import { isEmpty } from "lodash";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";

const labelClassess = "satoshi-500 !mt-3 !text-black !text-[14px]";

let Once = true;

const EditGoal = ({ open, handleClose, data }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const programData = useSelector((state) => state.programs.data);
  const [pageLoader, setPageLoader] = useState(false);
  const [addAmount, setAddAmount] = useState(0);
  const [amountError, setAmountError] = useState("");
  const [stepDropUp, setStepDropUp] = useState({});

  const initialState = {
    goalType: data?.goalType,
    goalId: data?._id,
    name: data?.title,
    amount: data?.goalType === "Financial" ? data?.amount : 0,
    notes: data?.note && parse(data?.note),
    steps: data?.steps?.map((item) => {
      return {
        ...item,
        step: {
          ...item.step,
          description:
            item?.step?.description && parse(item?.step?.description),
        },
      };
    }),
    savedAmount: data?.savedAmount || 0,
  };

  const {
    values,
    setValues,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: initialState,
    validationSchema: updateGoalSchema,
    onSubmit: (values) => {
      if (
        parseFloat(addAmount) + parseFloat(values.savedAmount) >
        values.amount &&
        values.goalType === "Financial"
      ) {
        setAmountError(
          "The amount you saved must be equal or less than goal amount"
        );
      } else {
        setLoading(true);
        if (addAmount > 0) {
          values.savedAmount =
            parseFloat(values.savedAmount) + parseFloat(addAmount);
        }
        values.amount = values?.goalType === "Financial" ? values?.amount : 0;
        dispatch(updateGoal(values)).then(() => {
          setLoading(false);
          handleClose();
        });
      }
    },
  });
  function handleStepCheck(id) {
    const updatedSteps = values.steps.map((step) => {
      if (step._id === id) {
        return { ...step, status: true }; // Set the status of the clicked step to true
      }
      return step;
    });

    setValues({
      ...values,
      steps: updatedSteps, // Update the steps array in the form values
    });
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(programData)) {
        setPageLoader(true);
        dispatch(getAssignedProgram()).then(() => {
          setPageLoader(false);
        });
      }
    }
  }, []);

  // console.log('values--', values)

  const handleStepToggle = (stepId) => {
    setStepDropUp({
      ...stepDropUp,
      [stepId]: !stepDropUp[stepId],
    });
  }

  return (
    <Modal open={open} headerText={"Goal Details"} onclick={handleClose}>
      {pageLoader ? (
        <Loader />
      ) : (
        <>
          <div className="w-full flex justify-start items-start flex-col ">
            <div className="w-full">
              <label
                htmlFor="Category"
                className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
              >
                Goal Type
              </label>
              <select
                className="w-full rounded-md  border outline-none py-2 px-2"
                name="goalType"
                id="programType"
                onBlur={handleBlur}
                disabled={
                  data?.createdBy === "Coach" || data?.createdBy === "Staff"
                }
                value={values.goalType}
                onChange={(e) => {
                  setValues((pre) => {
                    return {
                      ...pre,
                      savedAmount: 0,
                      [e.target.name]: e.target.value,
                    };
                  });
                }}
              >
                <option>Select Goal Type</option>
                <option className="text-black" value="Financial">
                  Financial
                </option>
                <option className="text-black" value="Nonfinancial">
                  Non Financial
                </option>
              </select>
            </div>
            {touched?.goalType && errors?.goalType && (
              <p className="text-red-600 text-[14px] mt-1 text-center">
                {errors?.goalType}
              </p>
            )}
          </div>
          <InputRequired
            name="name"
            label="Title"
            placeholder="Goal Title "
            type="text"
            labelStyle={labelClassess}
            value={values.name}
            touch={touched.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.name}
            disabled={
              data?.createdBy === "Coach" || data?.createdBy === "Staff"
            }
          />

          {values?.goalType === "Financial" && (
            <>
              <InputRequired
                name="amount"
                label="Goal Amount"
                placeholder="Goal Amount"
                type="number"
                labelStyle={labelClassess}
                value={values.amount}
                touch={touched.amount}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.amount}
              />

              <InputRequired
                name="savedAmount"
                label="Saved Amount"
                placeholder="Saved Amount"
                type="number"
                labelStyle={labelClassess}
                value={values.savedAmount}
                disabled={true}
              />

              <InputRequired
                name="newAmount"
                label="Add Amount"
                placeholder="Add more amount"
                type="number"
                labelStyle={labelClassess}
                touch={true}
                error={amountError}
                onChange={(e) => {
                  setAmountError("");
                  setAddAmount(e.target.value);
                }}
              />
            </>
          )}

          {data?.note && parse(parse(data?.note)) ? (
            <div className="pt-1 pb-3 border px-3 mt-3 description">
              <h1 className="border-b px-4 py-1 satoshi-900 text-[14px] mb-2 ">
                Note
              </h1>
              {parse(parse(data?.note))}
            </div>
          ) : null}

          {values?.steps?.length ? (
            <div>
              <p className="mt-4 satoshi-700 underline">
                Steps to accomplish goal
              </p>
              {values?.steps?.map((step, index) => {
                return (
                  <div
                    key={index}
                    // onClick={() => {
                    //   handleStepCheck(step._id);
                    // }}
                    className="flex cursor-pointer hover:bg-slate-200 w-full justify-start items-start gap-3 bg-slate-50 border rounded-md px-3 py-1 my-2"
                  >
                    <input
                      // checked={step.status}
                      // onChange={() => {
                      //   handleStepCheck(step._id);
                      // }}
                        checked={step.status}
                          onChange={() => {
                            setValues((prevValues) => {
                              const updatedSteps = [...prevValues.steps];
                              updatedSteps[index].status = !step.status;
                              return { ...prevValues, steps: updatedSteps };
                            });
                          }}
                      type="checkbox"
                      className="min-w-[18px] min-h-[18px]"
                    />
                    <div>
                      <div
                        onClick={() => handleStepToggle(index)}
                        
                        className="mb-3 flex items-center gap-5">
                        <h3 className="border-b border-black satoshi-700">
                          {step?.step?.title}
                        </h3>
                        {stepDropUp[index] ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                      </div>
                      {
                        stepDropUp[index] ? (
                          <div className="text-sm satoshi-500 description">
                            {parse(step?.step?.description)}
                          </div>
                        ) : null
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          {/* {programData ? (
            <div className="mt-4">
              <h1 className="font-medium text-[14px] capitalize">
                Programs Info
              </h1>
              <div className="flex justify-start items-stretch flex-wrap gap-3 py-2">
                {programData?.map((prog, index) => {
                  return (
                    <div
                      key={index}
                      className="mediaCardShadow rounded-lg w-[200px]"
                    >
                      <div className="flex justify-end items-center py-2">
                        <input
                          type="checkbox"
                          value={prog._id}
                          className="h-[15px] w-full cursor-pointer"
                          checked={values.programs.includes(prog._id)}
                          onClick={handleProgram}
                        />
                      </div>
                      <div className="w-full">
                        <div className="flex justify-center items-center">
                          <img
                            className="h-32 w-full"
                            src={process.env.REACT_APP_IMAGE_URL + prog?.photo}
                            alt="Blog cover"
                          />
                        </div>
                        <div className="w-full pt-2">
                          <h4 className="satoshi-500 text-[14px] pl-2">
                            {prog?.title}
                          </h4>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {errors.programs && touched.programs ? (
                <span className="text-red-600 text-[14px] my-2">
                  {errors.programs}
                </span>
              ) : null}
            </div>
          ) : null} */}

          <div className="flex justify-center items-center gap-4 pt-8">
            {loading ? (
              <ButtonLoading />
            ) : (
              <>
                <ButtonSmall
                  text="Close"
                  type="button"
                  onClick={handleClose}
                  customClasses={
                    "!w-1/2 !bg-white !text-[--primary] !border-[--primary] !px-2 "
                  }
                />
                <ButtonSmall
                  onClick={handleSubmit}
                  text={"Update"}
                  customClasses={"!w-1/2"}
                />
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default EditGoal;
