import React, { useContext, useEffect, useState } from "react";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import FooterButtons from "./FooterButtons";
import StepImage from "./StepImage";
import stepImage from "../../Assets/sm-4.png";
import SelectBox from "../Input/SelectBox";
import InputRequired from "../Input/InputRequired";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import FormWrapper from "./FormWrapper";
import { useFormik } from "formik";
import { step20Schema } from "../../Schema";
import { debtType } from "../../Data/InputData";
import Errortxt from "./Errortxt";
import ButtonIcon from "../Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";

const Step20 = ({ handleNext, handleBack, direction }) => {
  const { step19Data, step20Data, setStep20Data } =
    useContext(SmartPlanContext);

  const [customError, setCustomeError] = useState(false);

  const initialState = step20Data;
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: step20Schema,
    onSubmit: (values) => {
      let valid = true;
      values.debt.forEach((item) => {
        if (item.debtType === "other" && !item.otherDebtName) {
          valid = false;
          setCustomeError(true);
        }
      });
      if (valid) {
        setStep20Data(values);
        handleNext();
      }
    },
  });

  function handleMultiInputsChange(e, index) {
    setCustomeError(false);
    const { name, value } = e.target;
    const newDebt = [...formik.values.debt];
    newDebt[index][name] = value;
    formik.setFieldValue("debt", newDebt);
  }

  const removeInput = (targetIndex) => {
    const newDebt = formik.values.debt.filter(
      (_, index) => index !== targetIndex
    );
    formik.setFieldValue("debt", newDebt);
  };

  const appendField = () => {
    const newDebt = [
      ...formik.values.debt,
      {
        debtType: null,
        instituteName: null,
        monthlyPayment: null,
        balance: null,
        rateOfDebt: null,
        otherDebtName: null,
      },
    ];
    formik.setFieldValue("debt", newDebt);
  };

  useEffect(() => {
    if (step19Data === "no") {
      if (direction === "next") handleNext();
      else handleBack();
    }
  }, []);
  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Share your debt information" />
        {formik.values.debt.map((item, index) => {
          return (
            <div className="py-2">
              <InputWrapper customClass={"!items-stretch"}>
                <SelectBox
                  multiStyle={"!mt-0 flex-grow"}
                  placeholder="Select Type"
                  customClasses={"!py-[9px] bg-white !text-black "}
                  label="Debt Type"
                  id="year"
                  options={debtType}
                  name="debtType"
                  value={item.debtType}
                  onchange={(e) => {
                    handleMultiInputsChange(e, index);
                  }}
                  error={
                    formik?.errors &&
                    formik?.errors?.debt &&
                    formik?.errors?.debt[index]?.debtType
                  }
                  touch={
                    formik?.touched &&
                    formik?.touched?.debt &&
                    formik?.touched?.debt[index]?.debtType
                  }
                />
              </InputWrapper>
              {item.debtType === "other" && (
                <InputWrapper>
                  <InputRequired
                    label="Debt Type Name"
                    placeholder="Name here..."
                    type="text"
                    name="otherDebtName"
                    value={item.otherDebtName}
                    onChange={(e) => {
                      handleMultiInputsChange(e, index);
                    }}
                    error={customError ? "Field is required" : null}
                    touch={true}
                  />
                </InputWrapper>
              )}
              <InputWrapper>
                <InputRequired
                  label="Institution Name"
                  placeholder="Name here..."
                  type="text"
                  name="instituteName"
                  value={item.instituteName}
                  onChange={(e) => {
                    handleMultiInputsChange(e, index);
                  }}
                  error={
                    formik?.errors &&
                    formik?.errors?.debt &&
                    formik?.errors?.debt[index]?.instituteName
                  }
                  touch={
                    formik?.touched &&
                    formik?.touched?.debt &&
                    formik?.touched?.debt[index]?.instituteName
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <InputRequired
                  label="Monthly Payment"
                  placeholder="$ 0"
                  type="number"
                  name="monthlyPayment"
                  value={item.monthlyPayment}
                  onChange={(e) => {
                    handleMultiInputsChange(e, index);
                  }}
                  error={
                    formik?.errors &&
                    formik?.errors?.debt &&
                    formik?.errors?.debt[index]?.monthlyPayment
                  }
                  touch={
                    formik?.touched &&
                    formik?.touched?.debt &&
                    formik?.touched?.debt[index]?.monthlyPayment
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <InputRequired
                  label="Balance"
                  placeholder="$ 0"
                  type="number"
                  name="balance"
                  value={item.balance}
                  onChange={(e) => {
                    handleMultiInputsChange(e, index);
                  }}
                  error={
                    formik?.errors &&
                    formik?.errors?.debt &&
                    formik?.errors?.debt[index]?.balance
                  }
                  touch={
                    formik?.touched &&
                    formik?.touched?.debt &&
                    formik?.touched?.debt[index]?.balance
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <InputRequired
                  label="Rate of debt"
                  placeholder="0"
                  type="number"
                  name="rateOfDebt"
                  value={item.rateOfDebt}
                  onChange={(e) => {
                    handleMultiInputsChange(e, index);
                  }}
                  error={
                    formik?.errors &&
                    formik?.errors?.debt &&
                    formik?.errors?.debt[index]?.rateOfDebt
                  }
                  touch={
                    formik?.touched &&
                    formik?.touched?.debt &&
                    formik?.touched?.debt[index]?.rateOfDebt
                  }
                />
              </InputWrapper>
              {formik.values.debt.length > 1 ? (
                <div className="w-full flex justify-center items-center my-3">
                  <button
                    type="button"
                    onClick={() => {
                      removeInput(index);
                    }}
                    className="px-4 py-1 satoshi-500 border border-red-600 bg-red-600 text-white transition-all ease-in-out duration-300 hover:bg-white hover:text-red-600"
                  >
                    Delete
                  </button>
                </div>
              ) : null}
            </div>
          );
        })}

        <div className="w-full flex justify-center items-center mt-4">
          <ButtonIcon onclick={appendField} icon={<IoMdAdd />} text="Add" />
        </div>

        <InputWrapper>
          <div className="flex justify-start items-start flex-col mt-4">
            <label>Debt is in good standing or in collection?</label>
            <div className="flex justify-start items-center mt-1">
              <input
                type="radio"
                name="debtCondition"
                value="Good Standing"
                checked={formik.values.debtCondition === "Good Standing"}
                className="w-[15px] h-[15px] mr-2"
                onChange={formik.handleChange}
              />{" "}
              <span>Good Standing</span>
            </div>
            <div className="flex justify-start items-center">
              <input
                type="radio"
                name="debtCondition"
                value="Collectin"
                checked={formik.values.debtCondition === "Collectin"}
                className="w-[15px] h-[15px] mr-2"
                onChange={formik.handleChange}
              />{" "}
              <span>Collection</span>
            </div>
          </div>
        </InputWrapper>
        {formik?.touched?.debtCondition && formik?.errors?.debtCondition && (
          <Errortxt />
        )}

        {formik.values.debtCondition === "Collectin" && (
          <InputWrapper>
            <div className="flex justify-start items-start flex-col mt-4">
              <label>
                Would you like guidance on how to pay off these debts or handle
                collections?
              </label>
              <div className="flex justify-start items-center mt-1">
                <input
                  type="radio"
                  name="guidance"
                  value="yes"
                  className="w-[15px] h-[15px] mr-2"
                  checked={formik.values.guidance === "yes"}
                  onChange={formik.handleChange}
                />{" "}
                <span>Yes</span>
              </div>
              <div className="flex justify-start items-center">
                <input
                  type="radio"
                  name="guidance"
                  value="no"
                  className="w-[15px] h-[15px] mr-2"
                  checked={formik.values.guidance === "no"}
                  onChange={formik.handleChange}
                />{" "}
                <span>No</span>
              </div>
            </div>
          </InputWrapper>
        )}

        <FooterButtons onNext={formik.handleSubmit} onBack={handleBack} />
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step20;
