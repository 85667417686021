import { useSelector } from "react-redux";

// Custom hook
function useHasPermission(permission) {
  const userData = useSelector((state) => state.auth.userData);

  // console.log("User Data ===>", userData);
  if (userData) {
    return userData?.permissions?.includes(permission);
  }
}

export default useHasPermission;
