import React, { useContext } from "react";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import FooterButtons from "./FooterButtons";
import CheckBox from "../Input/CheckBox";
import FormWrapper from "./FormWrapper";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import { useFormik } from "formik";
import { step25Schema } from "../../Schema";
import Errortxt from "./Errortxt";
// import { structureAddSmartPlan } from "../../Utils/smartPlanGenerate";

const labelClass = "satoshi-700";

const Step25 = ({ handleNext, handleBack }) => {
  const { step25Data, setStep25Data } = useContext(SmartPlanContext);

  const initiaalState = step25Data;

  const { values, setValues, errors, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initiaalState,
      validationSchema: step25Schema,
      onSubmit: (values) => {
        setStep25Data(values);
        handleNext();
      },
    });

  function handleCheckBox(e) {
    setValues({
      ...values,
      [e.target.name]: e.target.checked,
    });
  }

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Financial Readiness" />
        <div className="py-2">
          <InputWrapper>
            <div className="flex justify-start items-start flex-col mt-4">
              <label className={labelClass}>Do you have the following:</label>
              <div className="flex justify-start items-start mt-1 flex-col">
                <CheckBox
                  name="lastWill"
                  customClasses={"!text-[16px]"}
                  label="Last Will"
                  checked={values.lastWill}
                  handleChange={handleCheckBox}
                />
                <CheckBox
                  name="livingTrust"
                  customClasses={"!text-[16px]"}
                  label="Living Trust"
                  checked={values.livingTrust}
                  handleChange={handleCheckBox}
                />
                <CheckBox
                  name="healthCarePowerOfAttorney"
                  customClasses={"!text-[16px]"}
                  label="Health Care Power of Attorney"
                  handleChange={handleCheckBox}
                  checked={values.healthCarePowerOfAttorney}
                />
                <CheckBox
                  name="financialPowerOfAttorney"
                  customClasses={"!text-[16px]"}
                  label="Financial Power of Attorney"
                  handleChange={handleCheckBox}
                  checked={values.financialPowerOfAttorney}
                />
              </div>
            </div>
          </InputWrapper>
          <InputWrapper>
            <div className="flex justify-start items-start flex-col mt-4">
              <label className={labelClass}>
                Do you have all your personal finance documents organized?
              </label>
              <div className="flex justify-start items-center mt-1">
                <input
                  type="radio"
                  name="documentsOrganized"
                  className="w-[15px] h-[15px] mr-2"
                  value="yes"
                  onChange={handleChange}
                  checked={values.documentsOrganized === "yes"}
                />{" "}
                <span>Yes</span>
              </div>
              <div className="flex justify-start items-center">
                <input
                  type="radio"
                  name="documentsOrganized"
                  className="w-[15px] h-[15px] mr-2"
                  value="no"
                  onChange={handleChange}
                  checked={values.documentsOrganized === "no"}
                />{" "}
                <span>No</span>
              </div>
            </div>
          </InputWrapper>
          {touched.documentsOrganized && errors.documentsOrganized && (
            <Errortxt />
          )}

          <InputWrapper>
            <div className="flex justify-start items-start flex-col mt-4">
              <label className={labelClass}>
                Are you aware of identity theft risks and do you have
                protection?
              </label>
              <div className="flex justify-start items-center mt-1">
                <input
                  type="radio"
                  name="identityTheftProtection"
                  className="w-[15px] h-[15px] mr-2"
                  value="yes"
                  onChange={handleChange}
                  checked={values.identityTheftProtection === "yes"}
                />{" "}
                <span>Yes</span>
              </div>
              <div className="flex justify-start items-center">
                <input
                  type="radio"
                  name="identityTheftProtection"
                  className="w-[15px] h-[15px] mr-2"
                  value="no"
                  onChange={handleChange}
                  checked={values.identityTheftProtection === "no"}
                />{" "}
                <span>No</span>
              </div>
            </div>
          </InputWrapper>
          {touched.identityTheftProtection &&
            errors.identityTheftProtection && <Errortxt />}

          <InputWrapper>
            <div className="flex justify-start items-start flex-col mt-4">
              <label className={labelClass}>
                Do you feel on track to be debt-free?
              </label>
              <div className="flex justify-start items-center mt-1">
                <input
                  type="radio"
                  name="onTrackDebtFree"
                  className="w-[15px] h-[15px] mr-2"
                  value="yes"
                  onChange={handleChange}
                  checked={values.onTrackDebtFree === "yes"}
                />{" "}
                <span>Yes</span>
              </div>
              <div className="flex justify-start items-center">
                <input
                  type="radio"
                  name="onTrackDebtFree"
                  className="w-[15px] h-[15px] mr-2"
                  value="no"
                  onChange={handleChange}
                  checked={values.onTrackDebtFree === "no"}
                />{" "}
                <span>No</span>
              </div>
            </div>
          </InputWrapper>
          {touched.onTrackDebtFree && errors.onTrackDebtFree && <Errortxt />}

          <InputWrapper>
            <div className="flex justify-start items-start flex-col mt-4">
              <label className={labelClass}>
                Do you have a budgeting and debt payoff tool?
              </label>
              <div className="flex justify-start items-center mt-1">
                <input
                  type="radio"
                  name="budgetingAndDebtPayoffTool"
                  className="w-[15px] h-[15px] mr-2"
                  value="yes"
                  onChange={handleChange}
                  checked={values.budgetingAndDebtPayoffTool === "yes"}
                />{" "}
                <span>Yes</span>
              </div>
              <div className="flex justify-start items-center">
                <input
                  type="radio"
                  name="budgetingAndDebtPayoffTool"
                  className="w-[15px] h-[15px] mr-2"
                  value="no"
                  onChange={handleChange}
                  checked={values.budgetingAndDebtPayoffTool === "no"}
                />{" "}
                <span>No</span>
              </div>
            </div>
          </InputWrapper>
          {touched.budgetingAndDebtPayoffTool &&
            errors.budgetingAndDebtPayoffTool && <Errortxt />}

          <InputWrapper>
            <div className="flex justify-start items-start flex-col mt-4">
              <label className={labelClass}>
                Do you feel sufficiently prepared to manage finances and protect
                your identity, credit, assets, real estate, personal property,
                and family?
              </label>
              <div className="flex justify-start items-center mt-1">
                <input
                  type="radio"
                  name="preparedToManageFinances"
                  className="w-[15px] h-[15px] mr-2"
                  value="yes"
                  onChange={handleChange}
                  checked={values.preparedToManageFinances === "yes"}
                />{" "}
                <span>Yes</span>
              </div>
              <div className="flex justify-start items-center">
                <input
                  type="radio"
                  name="preparedToManageFinances"
                  className="w-[15px] h-[15px] mr-2"
                  value="no"
                  onChange={handleChange}
                  checked={values.preparedToManageFinances === "no"}
                />{" "}
                <span>No</span>
              </div>
            </div>
          </InputWrapper>
          {touched.preparedToManageFinances &&
            errors.preparedToManageFinances && <Errortxt />}

          <InputWrapper>
            <div className="flex justify-start items-start flex-col mt-4">
              <label className={labelClass}>
                Would you be interested in affordable education and services to
                protect yourself and your family?
              </label>
              <div className="flex justify-start items-center mt-1">
                <input
                  type="radio"
                  name="interestedInEducationAndServices"
                  className="w-[15px] h-[15px] mr-2"
                  value="yes"
                  onChange={handleChange}
                  checked={values.interestedInEducationAndServices === "yes"}
                />{" "}
                <span>Yes</span>
              </div>
              <div className="flex justify-start items-center">
                <input
                  type="radio"
                  name="interestedInEducationAndServices"
                  className="w-[15px] h-[15px] mr-2"
                  value="no"
                  onChange={handleChange}
                  checked={values.interestedInEducationAndServices === "no"}
                />{" "}
                <span>No</span>
              </div>
            </div>
          </InputWrapper>
          {touched.interestedInEducationAndServices &&
            errors.interestedInEducationAndServices && <Errortxt />}

          <InputWrapper>
            <div className="flex justify-start items-start flex-col mt-4">
              <label className={labelClass}>
                If renting, are you reporting rent payments to increase your
                credit score?
              </label>
              <div className="flex justify-start items-center mt-1">
                <input
                  type="radio"
                  name="reportingRentPayments"
                  className="w-[15px] h-[15px] mr-2"
                  value="yes"
                  onChange={handleChange}
                  checked={values.reportingRentPayments === "yes"}
                />{" "}
                <span>Yes</span>
              </div>
              <div className="flex justify-start items-center">
                <input
                  type="radio"
                  name="reportingRentPayments"
                  className="w-[15px] h-[15px] mr-2"
                  value="no"
                  onChange={handleChange}
                  checked={values.reportingRentPayments === "no"}
                />{" "}
                <span>No</span>
              </div>
            </div>
          </InputWrapper>
          {touched.reportingRentPayments && errors.reportingRentPayments && (
            <Errortxt />
          )}

          <FooterButtons onNext={handleSubmit} onBack={handleBack} />
        </div>
      </FormWrapper>
    </div>
  );
};

export default Step25;
