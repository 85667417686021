import React, { useContext, useState } from "react";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import CheckBoxLarge from "../Input/CheckBoxLarge";
import FooterButtons from "./FooterButtons";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import FormWrapper from "./FormWrapper";
import Errortxt from "./Errortxt";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";

const Step23 = ({ handleNext, handleBack }) => {
  const { step23Data, setStep23Data } = useContext(SmartPlanContext);
  const [error, setError] = useState(false);
  function handleChange(e) {
    setError(false);
    setStep23Data(e.target.value);
  }

  function submitStep() {
    if (!step23Data) {
      setError(true);
      return;
    }

    handleNext();
  }

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Do you use a budget?" />
        <div className="py-4">
          <InputWrapper customClass={"!gap-6 !justify-start"}>
            <CheckBoxLarge
              icon={<MdCancel />}
              iconStyle={"text-red-400"}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
              text="No, I don't use"
              name="budget"
              value="no"
              id="no"
              onChange={handleChange}
              selected={step23Data === "no"}
            />
            <CheckBoxLarge
              icon={<FaCheckCircle />}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
              iconStyle={"text-green-400"}
              text="Yes, I use"
              name="budget"
              value="yes"
              id="yes"
              onChange={handleChange}
              selected={step23Data === "yes"}
            />
          </InputWrapper>
          {error && <Errortxt />}

          <FooterButtons onNext={submitStep} onBack={handleBack} />
        </div>
      </FormWrapper>
      {/* <StepImage image={stepImage} /> */}
    </div>
  );
};

export default Step23;
