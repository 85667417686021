import React, { useEffect, useState } from "react";
import HeaderData from "../components/Header/HeaderData";
import ButtonSmall from "../components/Buttons/ButtonSmall";
import ButtonLoading from "../components/Buttons/ButtonLoading";
import { calculateTotalExpense, calculateTotalIncome } from "../Utils";
import { addBudgetInfo } from "../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import useHasPermission from "../Utils/permissionUtils";
import UnavailableService from "../components/Message/UnavailableService";
import commaNumber from 'comma-number'; // Import comma-number
import { duration } from "moment";

let Once = true;

const BudgetForm = ({ closeForm }) => {
  const hasPermission = useHasPermission("Create New Monthly Budget");
  const hasPermissionToUpdate = useHasPermission("Modify Budget");
  const [btnLoading, setBtnLoading] = useState(false);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const dispatch = useDispatch();
  const [budgetDuration, setBudgetDuration] = useState("Week");
  const [incomeData, setIncomeData] = useState(
    useSelector((state) => state.budgetHistory.income)
  );

  const [expenseData, setExpenseData] = useState(
    useSelector((state) => state.budgetHistory.expense)
  );
  const expenseTypeId = useSelector(
    (state) => state.budgetHistory.expenseTypeId
  );

  const formatCurrencyWithCommas = (value) => {
    if (value === null || value === undefined) return ""; // Handle null/undefined
    const number = parseFloat(value.toString().replace(/,/g, "")).toFixed(2); // Ensure value is a string
    return commaNumber(number); // Add commas using commaNumber
  };

  const handleIncomeChange = (e, targetIndex) => {
    const rawValue = e.target.value.replace(/,/g, ""); // Remove commas for calculation
    const parsedValue = parseFloat(rawValue).toFixed(2); // Ensure the value has two decimal places

    const newIncomeData = incomeData?.map((item, index) =>
      index === targetIndex
        ? {
          ...item,
          amount: isNaN(parsedValue) ? 0 : parsedValue, // Handle NaN
        }
        : item
    );

    setIncomeData(newIncomeData);
    setTimeout(() => {
      e.target.setSelectionRange(e.target.value.length - 3, e.target.value.length - 3); // Set cursor before ".00"
    }, 0);
  };

  const handleExpenseChange = (e, subcategoryIndex, dataIndex) => {
    const rawValue = e.target.value.replace(/,/g, ""); // Remove commas for calculation
    const parsedValue = parseFloat(rawValue).toFixed(2); // Ensure the value has two decimal places

    const newExpenseData = expenseData.map((subcategory, index) => {
      if (index === subcategoryIndex) {
        return {
          ...subcategory,
          data: subcategory.data.map((item, index) => {
            if (index === dataIndex) {
              return { ...item, amount: isNaN(parsedValue) ? 0 : parsedValue };
            }
            return item;
          }),
        };
      }
      return subcategory;
    });

    setExpenseData(newExpenseData);
    setTimeout(() => {
      e.target.setSelectionRange(e.target.value.length - 3, e.target.value.length - 3); // Set cursor before ".00"
    }, 0);
  };

  function handleSubmit(e) {
    e.preventDefault();
    setBtnLoading(true);
    dispatch(
      addBudgetInfo({ incomeData, expenseData, expenseTypeId, budgetDuration })
    ).then(() => {
      window.location.reload();
    });
  }

  useEffect(() => {
    // Use unformatted values for calculating totals
    setTotalIncome(calculateTotalIncome(incomeData));
    setTotalExpense(calculateTotalExpense(expenseData));
  }, [incomeData, expenseData]);

  return hasPermission || hasPermissionToUpdate ? (
    <>
      <HeaderData text="Budget Form">
        {btnLoading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall onClick={handleSubmit} text="Save " />
            <ButtonSmall
              customClasses={
                "!bg-red-600 !border-red-700 hover:!bg-white hover:!text-red-700"
              }
              text="Close"
              onClick={closeForm}
            />
          </>
        )}
      </HeaderData>
      {incomeData.length ? (
        <>
          <table className="w-[100%] mx-auto  min-w-[450px] border-collapse !overflow-x-auto bg-white rounded-md mt-3">
            <thead>
              <tr className="bg-emerald-800">
                <th
                  colSpan={3}
                  className="border-b py-3 text-white text-base md:text-lg"
                >
                  Income
                </th>
              </tr>
              <tr>
                <th style={headerStyle} className="w-[50%] !pl-5">
                  Name
                </th>
                <th style={headerStyle} className="w-[25%] !pl-6">
                  Amount
                </th>
                <th style={headerStyle} className="w-[25%] !pl-6">
                  Duration
                </th>
              </tr>
            </thead>
            <tbody>
              {incomeData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td
                      style={descriptionStyle}
                      className="!pl-3 text-[12px] sm:text-[14px] md:text-[16px] xl:text-[17px]"
                    >
                      {item.name}
                    </td>
                    <td
                      style={descriptionStyle}
                      className="!pl-3 text-[12px] sm:text-[14px] md:text-[16px] xl:text-[17px] border-l !text-center"
                    >
                      <input
                        name="amount"
                        value={formatCurrencyWithCommas(item.amount)}
                        type="text"
                        onChange={(e) => {
                          handleIncomeChange(e, index);
                        }}
                        placeholder="$ 0.00"
                        className="w-[90%] border rounded-md outline-none px-4 py-[4px] text-[14px]"
                      />
                    </td>

                    <td
                      style={descriptionStyle}
                      className="!pl-3 text-[12px] sm:text-[14px] md:text-[16px] xl:text-[17px] border-l !text-center"
                    >
                      <select
                        name="duration"
                        value={item?.duration}
                        onChange={(e) => {
                          setBudgetDuration(e.target.value);

                          // Update the specific incomeData item where _id matches the item's _id
                          const updatedIncomeData = incomeData?.map((ele) => {
                            if (ele._id === item._id) {
                              return {
                                ...ele,
                                duration: e.target.value // Update the duration
                              };
                            }
                            return ele; // Return unchanged items
                          });

                          setIncomeData(updatedIncomeData); // Update state with new array
                        }}
                        className="w-[90%] border rounded-md outline-none px-4 py-[4px] text-[14px]"
                      >
                        <option value="Week">Weekly</option>
                        <option value="BiWeek">BiWeekly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Yearly">Yearly</option>
                      </select>
                    </td>

                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr className="bg-teal-700 text-yellow-400 ">
                <td
                  colSpan={2}
                  className=" !pr-2 !text-right satoshi-700 py-1 "
                >
                  Total Income :
                </td>
                <td className=" satoshi-700 pl-6 ">
                  $ {formatCurrencyWithCommas(totalIncome)}
                </td>
              </tr>
            </tfoot>
          </table>
        </>
      ) : null}
      {expenseData.length ? (
        <table className="w-[100%] mx-auto  min-w-[450px] border-collapse !overflow-x-auto bg-white rounded-md">
          <thead>
            <tr className="bg-emerald-800">
              <th
                colSpan={3}
                className="border-b py-3 text-white text-base md:text-lg"
              >
                Expense
              </th>
            </tr>
            <tr>
              <th
                style={headerStyle}
                className="min-w-max w-[40%] !text-left !pl-5"
              >
                Name
              </th>
              <th style={headerStyle} className="w-[40%]">
                Purpose
              </th>
              <th style={headerStyle} className="w-[20%]">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {expenseData.map((subCategory, index) => {
              return (
                <>
                  <tr key={index} className="bg-slate-200">
                    <td colSpan={3} className="text-center py-2 satoshi-700">
                      {subCategory?.subcategoryName}
                    </td>
                  </tr>
                  {subCategory?.data?.map((item, subIndex) => {
                    return (
                      <tr key={subIndex}>
                        <td style={descriptionStyle} className="!pl-3">
                          {item?.categoryName}
                        </td>
                        <td style={descriptionStyle}>{item?.purposeName}</td>
                        <td style={descriptionStyle} className="border-l">
                          <input
                            name="expense_amount"
                            value={formatCurrencyWithCommas(item?.amount)}
                            type="text"
                            onChange={(e) => {
                              handleExpenseChange(e, index, subIndex);
                            }}
                            placeholder="$ 0.00"
                            className="w-[90%] border rounded-md outline-none px-4 py-[4px] text-[14px]"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </>
              );
            })}
          </tbody>
          <tfoot>
            <tr className="bg-teal-700 text-yellow-400 ">
              <td
                colSpan={2}
                className="w-[65%] !pr-2 !text-right satoshi-700 py-3 "
              >
                Total Expense
              </td>
              <td className="satoshi-700 py-3 pl-6 ">
                $ {formatCurrencyWithCommas(totalExpense)}
              </td>
            </tr>
          </tfoot>
        </table>
      ) : null}

      <div className="w-[90%] sm:w-[70%] md:w-[40%] max-w-[350px] mx-auto  mt-4 mb-3 text-center">
        {btnLoading ? (
          <ButtonLoading />
        ) : (
          <ButtonSmall onClick={handleSubmit} text="Save & Exit" />
        )}
      </div>
    </>
  ) : (
    <UnavailableService />
  );
};

export default BudgetForm;
const headerStyle = {
  textAlign: "left",
  fontSize: "16px",
  padding: "10px 8px",
  color: "rgba(130, 130, 130, 1)",
  borderBottom: "1px solid #b6b6b6",
};

const descriptionStyle = {
  fontSize: "14px",
  padding: "10px 3px",
  borderBottom: "1px solid #b6b6b6",
};

/////////////////////////////////////////////////////
// import React, { useEffect, useState } from "react";
// import HeaderData from "../components/Header/HeaderData";
// import ButtonSmall from "../components/Buttons/ButtonSmall";
// import ButtonLoading from "../components/Buttons/ButtonLoading";
// import { addBudgetInfo } from "../Redux/Actions";
// import { useDispatch, useSelector } from "react-redux";
// import useHasPermission from "../Utils/permissionUtils";
// import UnavailableService from "../components/Message/UnavailableService";

// // Helper function to format number with commas and exactly 2 decimal places
// const formatCurrencyWithCommas = (value) => {
//   if (!value) return '';
//   const number = parseFloat(value.replace(/,/g, '')).toFixed(2);
//   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// };

// // Helper function to remove commas and return float
// // const parseCurrency = (value) => {
// //   if (!value) return 0;
// //   return parseFloat(value.replace(/,/g, ''));
// // };

// const parseCurrency = (value) => {
//   if (typeof value !== "string") {
//     return parseFloat(value) || 0;  // Return 0 if the value is not a valid number or string
//   }
//   return parseFloat(value.replace(/,/g, '')); // Remove commas and convert to float
// };


// // Validate input for up to two decimal places and only one decimal point
// const validateInput = (value) => {
//   const regex = /^\d*\.?\d{0,2}$/; // Allow numbers with up to 2 decimal places
//   if (regex.test(value)) {
//     return value;
//   }
//   return value.slice(0, -1); // Trim the last invalid character
// };

// const BudgetForm = ({ closeForm }) => {
//   const hasPermission = useHasPermission("Create New Monthly Budget");
//   const hasPermissionToUpdate = useHasPermission("Modify Budget");
//   const [btnLoading, setBtnLoading] = useState(false);
//   const [totalIncome, setTotalIncome] = useState(0);
//   const [totalExpense, setTotalExpense] = useState(0);
//   const dispatch = useDispatch();
//   const [budgetDuration, setBudgetDuration] = useState("Week");

//   const [incomeData, setIncomeData] = useState(
//     useSelector((state) => state.budgetHistory.income)
//   );
//   const [expenseData, setExpenseData] = useState(
//     useSelector((state) => state.budgetHistory.expense)
//   );
//   const expenseTypeId = useSelector(
//     (state) => state.budgetHistory.expenseTypeId
//   );

//   // Handle changes to income fields (during typing)
//   function handleIncomeChange(e, targetIndex) {
//     const { name, value } = e.target;

//     // Validate user input to allow only valid numbers
//     const validatedValue = validateInput(value);

//     setIncomeData(
//       incomeData.map((item, index) => {
//         if (index === targetIndex) {
//           return {
//             ...item,
//             [name]: validatedValue,
//           };
//         }
//         return item;
//       })
//     );
//   }

//   // Apply formatting when the user leaves the input field
//   function handleIncomeBlur(e, targetIndex) {
//     const { name, value } = e.target;

//     setIncomeData(
//       incomeData.map((item, index) => {
//         if (index === targetIndex) {
//           return {
//             ...item,
//             [name]: formatCurrencyWithCommas(value),
//           };
//         }
//         return item;
//       })
//     );
//   }

//   // Handle changes to expense fields (during typing)
//   function handleExpenseChange(e, subcategoryIndex, dataIndex) {
//     const { value } = e.target;

//     // Validate user input to allow only valid numbers
//     const validatedValue = validateInput(value);

//     setExpenseData(
//       expenseData.map((subcategory, index) => {
//         if (index === subcategoryIndex) {
//           return {
//             ...subcategory,
//             data: subcategory.data.map((item, idx) => {
//               if (idx === dataIndex) {
//                 return {
//                   ...item,
//                   amount: validatedValue,
//                 };
//               }
//               return item;
//             }),
//           };
//         }
//         return subcategory;
//       })
//     );
//   }

//   // Apply formatting when the user leaves the expense input field
//   function handleExpenseBlur(e, subcategoryIndex, dataIndex) {
//     const { value } = e.target;

//     setExpenseData(
//       expenseData.map((subcategory, index) => {
//         if (index === subcategoryIndex) {
//           return {
//             ...subcategory,
//             data: subcategory.data.map((item, idx) => {
//               if (idx === dataIndex) {
//                 return {
//                   ...item,
//                   amount: formatCurrencyWithCommas(value),
//                 };
//               }
//               return item;
//             }),
//           };
//         }
//         return subcategory;
//       })
//     );
//   }

//   // Calculate total income
//   useEffect(() => {
//     const totalIncomeValue = incomeData.reduce((acc, item) => {
//       return acc + parseCurrency(item.amount || "0");
//     }, 0);
//     setTotalIncome(totalIncomeValue);
//   }, [incomeData]);

//   // Calculate total expense
//   useEffect(() => {
//     const totalExpenseValue = expenseData.reduce((acc, subcategory) => {
//       return (
//         acc +
//         subcategory.data.reduce((subAcc, item) => {
//           return subAcc + parseCurrency(item.amount || "0");
//         }, 0)
//       );
//     }, 0);
//     setTotalExpense(totalExpenseValue);
//   }, [expenseData]);

//   function handleSubmit(e) {
//     e.preventDefault();
//     setBtnLoading(true);
//     dispatch(
//       addBudgetInfo({ incomeData, expenseData, expenseTypeId, budgetDuration })
//     ).then(() => {
//       window.location.reload();
//     });
//   }

//   return hasPermission || hasPermissionToUpdate ? (
//     <>
//       <HeaderData text="Budget Form">
//         {btnLoading ? (
//           <ButtonLoading />
//         ) : (
//           <>
//             <ButtonSmall onClick={handleSubmit} text="Save " />
//             <ButtonSmall
//               customClasses={
//                 "!bg-red-600 !border-red-700 hover:!bg-white hover:!text-red-700"
//               }
//               text="Close"
//               onClick={closeForm}
//             />
//           </>
//         )}
//       </HeaderData>
//       {incomeData.length ? (
//         <>
//           <table className="w-[100%] mx-auto min-w-[450px] border-collapse !overflow-x-auto bg-white rounded-md mt-3">
//             <thead>
//               <tr className="bg-emerald-800">
//                 <th colSpan={3} className="border-b py-3 text-white text-base md:text-lg">
//                   Income
//                 </th>
//               </tr>
//               <tr>
//                 <th style={headerStyle} className="w-[50%] !pl-5">Name</th>
//                 <th style={headerStyle} className="w-[25%] !pl-6">Amount</th>
//                 <th style={headerStyle} className="w-[25%] !pl-6">Duration</th>
//               </tr>
//             </thead>
//             <tbody>
//               {incomeData?.map((item, index) => (
//                 <tr key={index}>
//                   <td style={descriptionStyle} className="!pl-3 text-[12px] sm:text-[14px]">
//                     {item.name}
//                   </td>
//                   <td style={descriptionStyle} className="!pl-3 text-center border-l">
//                     <input
//                       name="amount"
//                       value={item.amount}
//                       type="text"
//                       onChange={(e) => handleIncomeChange(e, index)}
//                       onBlur={(e) => handleIncomeBlur(e, index)}
//                       placeholder="$ 0.00"
//                       className="w-[90%] border rounded-md outline-none px-4 py-[4px] text-[14px]"
//                     />
//                   </td>
//                   <td style={descriptionStyle} className="!pl-3 text-center border-l">
//                     <select
//                       name="duration"
//                       value={item.duration}
//                       onChange={(e) => handleIncomeChange(e, index)}
//                       className="w-[90%] border rounded-md outline-none px-4 py-[4px] text-[14px]"
//                     >
//                       <option value="Week">Weekly</option>
//                       <option value="BiWeek">BiWeekly</option>
//                       <option value="Monthly">Monthly</option>
//                       <option value="Yearly">Yearly</option>
//                     </select>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//             <tfoot>
//               <tr className="bg-teal-700 text-yellow-400 ">
//                 <td colSpan={2} className="!pr-2 !text-right satoshi-700 py-1">
//                   Total Income :
//                 </td>
//                 <td className="satoshi-700 pl-6">${formatCurrencyWithCommas(totalIncome.toFixed(2))}</td>
//               </tr>
//             </tfoot>
//           </table>
//         </>
//       ) : null}
//       {expenseData?.length ? (
//         <table className="w-[100%] mx-auto min-w-[450px] border-collapse !overflow-x-auto bg-white rounded-md">
//           <thead>
//             <tr className="bg-emerald-800">
//               <th colSpan={3} className="border-b py-3 text-white text-base md:text-lg">Expense</th>
//             </tr>
//             <tr>
//               <th style={headerStyle} className="min-w-max w-[40%] !text-left !pl-5">Name</th>
//               <th style={headerStyle} className="w-[40%]">Purpose</th>
//               <th style={headerStyle} className="w-[20%]">Amount</th>
//             </tr>
//           </thead>
//           <tbody>
//             {expenseData?.map((subCategory, index) => (
//               <>
//                 <tr key={index} className="bg-slate-200">
//                   <td colSpan={3} className="text-center py-2 satoshi-700">
//                     {subCategory.subcategoryName}
//                   </td>
//                 </tr>
//                 {subCategory?.data.map((item, subIndex) => (
//                   <tr key={subIndex}>
//                     <td style={descriptionStyle} className="!pl-3">{item.categoryName}</td>
//                     <td style={descriptionStyle}>{item.purposeName}</td>
//                     <td style={descriptionStyle} className="border-l">
//                       <input
//                         name="expense_amount"
//                         value={item.amount}
//                         type="text"
//                         onChange={(e) => handleExpenseChange(e, index, subIndex)}
//                         onBlur={(e) => handleExpenseBlur(e, index, subIndex)}
//                         placeholder="$ 0.00"
//                         className="w-[90%] border rounded-md outline-none px-4 py-[4px] text-[14px]"
//                       />
//                     </td>
//                   </tr>
//                 ))}
//               </>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr className="bg-teal-700 text-yellow-400 ">
//               <td colSpan={2} className="w-[65%] !pr-2 !text-right satoshi-700 py-3">Total Expense</td>
//               <td className="satoshi-700 py-3 pl-6">${formatCurrencyWithCommas(totalExpense.toFixed(2))}</td>
//             </tr>
//           </tfoot>
//         </table>
//       ) : null}

//       <div className="w-[90%] sm:w-[70%] md:w-[40%] max-w-[350px] mx-auto mt-4 mb-3 text-center">
//         {btnLoading ? (
//           <ButtonLoading />
//         ) : (
//           <ButtonSmall onClick={handleSubmit} text="Save & Exit" />
//         )}
//       </div>
//     </>
//   ) : (
//     <UnavailableService />
//   );
// };

// export default BudgetForm;

// const headerStyle = {
//   textAlign: "left",
//   fontSize: "16px",
//   padding: "10px 8px",
//   color: "rgba(130, 130, 130, 1)",
//   borderBottom: "1px solid #b6b6b6",
// };

// const descriptionStyle = {
//   fontSize: "14px",
//   padding: "10px 3px",
//   borderBottom: "1px solid #b6b6b6",
// };


// /////////////////////////////////////////////////////////////
