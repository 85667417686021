const initialState = {
  isInvoked: false,
  bills: 0,
  budget: 0,
  accounts: 0,
  totalAmount: 0,
  goals: 0,
  data: [],
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_DASHBOARD":
      return {
        ...state,
        isInvoked: true,
        bills: payload.bills,
        budget: payload.budget,
        totalAmount: payload.totalAmount,
        accounts: payload?.accounts,
        goals: payload?.goals,
        data: payload.dataStatistics,
      };
    case "ADD_BILL_COUNT":
      return {
        ...state,
        bills: state.bills + 1,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
