import React from "react";

const InputWrapper = ({ children, customClass }) => {
  return (
    <div
      className={`w-full flex justify-between items-stretch flex-wrap gap-2 mt-3 ${customClass}`}
    >
      {children}
    </div>
  );
};

export default InputWrapper;
