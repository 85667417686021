import React, { useContext, useState } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import FooterButtons from "./FooterButtons";
import stepImage from "../../Assets/sm-3.png";
import InputRequired from "../Input/InputRequired";
import FormWrapper from "./FormWrapper";
import CheckBoxLarge from "../Input/CheckBoxLarge";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import Errortxt from "./Errortxt";

const Step21 = ({ handleNext, handleBack }) => {
  const { step21Data, setStep21Data } = useContext(SmartPlanContext);
  const [error, setError] = useState(false);
  const [scoreError, setScoreError] = useState("");

  function handleChange(e) {
    setError(false);
    setScoreError("");
    setStep21Data((pre) => {
      return {
        ...pre,
        [e.target.name]: e.target.value,
      };
    });
  }

  function submitStep() {
    if (!step21Data.isCreditScore) {
      setError(true);
      return;
    } else if (
      step21Data.isCreditScore === "yes" &&
      !step21Data.creditScoreCount
    ) {
      setScoreError("Field is required");
      return;
    } else {
      handleNext();
    }
  }

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary
          customClasses={"!mt-4"}
          text="Do you Know your credit score?"
        />

        <div className="py-4">
          <InputWrapper customClass={"!gap-6 !justify-start"}>
            <CheckBoxLarge
              icon={<MdCancel />}
              iconStyle={"text-red-400"}
              id={"no"}
              value="no"
              name="isCreditScore"
              selected={step21Data.isCreditScore === "no"}
              onChange={handleChange}
              text="No"
            />
            <CheckBoxLarge
              icon={<FaCheckCircle />}
              id={"yes"}
              value="yes"
              name="isCreditScore"
              selected={step21Data.isCreditScore === "yes"}
              onChange={handleChange}
              iconStyle={"text-green-400"}
              text="Yes"
            />
          </InputWrapper>
          {error && <Errortxt />}

          {step21Data.isCreditScore === "yes" && (
            <InputWrapper>
              <InputRequired
                label="Credit Score"
                placeholder="0"
                type="number"
                name="creditScoreCount"
                value={step21Data.creditScoreCount}
                onChange={handleChange}
                touch={true}
                error={scoreError}
              />
            </InputWrapper>
          )}

          <FooterButtons onNext={submitStep} onBack={handleBack} />
        </div>
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step21;
