import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const ChartRow = ({ color, name, total, value, onClick, percentage }) => {
  const chartData = [
    { name: name, value: value },
    { name: "Other", value: total },
  ];
  // Use the passed color for the current ChartRow
  const chartColors = [color, "rgb(209, 209, 209)"];

  return (
    <div
      onClick={onClick}
      className="cursor-pointer flex justify-between py-1 items-center gap-2 mb-2 hover:bg-slate-200"
    >
      <div className="w-[60px] ">
        <ResponsiveContainer width="100%" height={35}>
          <PieChart>
            <Pie
              data={chartData}
              innerRadius={5}
              outerRadius={15}
              fill="#8884d8"
              paddingAngle={0}
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell
                  strokeWidth={0}
                  key={`cell-${index}`}
                  fill={chartColors[index]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="w-[90%] flex justify-between items-center px-1 ">
        <h5 className="satoshi-500">
          {name} ({percentage.toFixed(2)})%
        </h5>
        <h4 className="satoshi-700 pr-3">${Math.round(value)}</h4>
      </div>
    </div>
  );
};

export default ChartRow;
