import React, { useState } from 'react';
import Form from '../Form/Form';
import { useFormik } from 'formik';
import { passwordSchema } from '../../Schema';
import InputPassword from '../Input/InputPassword';
import ButtonLoading from '../Buttons/ButtonLoading';
import ButtonLarge from '../Buttons/ButtonLarge';
import { changePasswordVerify } from '../../Redux/Actions';


const initialState = {
    password: '',
    confirmPassword: '',
    userId:""
}
const NewPassword = ({stepsValues}) => {
const [loading, setLoading] = useState(false);
    
  const {values,errors,touched,handleBlur,handleChange,handleSubmit} = useFormik({
    initialValues: initialState,
    validationSchema:passwordSchema,
    onSubmit:(values,{resetForm})=>{
      values.userId = stepsValues.userId;
      setLoading(true);
      changePasswordVerify(values).then((res)=>{
        resetForm();
        setLoading(false);
        setTimeout(()=>{
          window.location.reload();
        },1000)
      }).catch((err)=>{
        console.error(err);
        setLoading(false);
      })
    }
});

    return (
       <Form headerText="Update Password" description='You can update your password. Please enter new password to update' onSubmit={handleSubmit}>
<InputPassword
          id="password"
          name="password"
          label="Password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.password}
          touch={touched.password}
          placeholder="Enter password"
        />
        <InputPassword
          id="cPassword"
          name="confirmPassword"
          label="Confirm Password"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.confirmPassword}
          touch={touched.confirmPassword}
          placeholder="Confirm password"
        />
            <div className="mt-2 w-full">
          {loading ? <ButtonLoading /> : <ButtonLarge text="Update Password" />}
        </div>
       </Form>
    );
}

export default NewPassword;
