import React, { useContext, useEffect, useState } from "react";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import CheckBoxLarge from "../Input/CheckBoxLarge";
import FooterButtons from "./FooterButtons";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import TypoGray from "./TypoGray";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import Errortxt from "./Errortxt";

const Step9 = ({ handleNext, handleBack, direction }) => {
  const { step7Data, step9Data, setStep9Data } = useContext(SmartPlanContext);
  const [error, setError] = useState(false);
  const [buildWealthError, setBuildWealthError] = useState(false);

  function handleChange(e) {
    setError(false);
    setBuildWealthError(false);
    setStep9Data((pre) => {
      return {
        ...pre,
        [e.target.name]: e.target.value,
      };
    });
  }

  function submitStep() {
    if (!step9Data.custodialAccount) {
      setError(true);
      return;
    } else if (step9Data.custodialAccount === "no" && !step9Data.buildWealth) {
      setBuildWealthError(true);
      return;
    } else {
      handleNext();
    }
  }

  useEffect(() => {
    if (step7Data === "no") {
      if (direction === "next") handleNext();
      else handleBack();
    }
  }, []);
  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <div className="w-full py-2 pl-10 pr-4">
        <TypoGray
          customClasses={"max-w-[80%] mt-2 !uppercase  md:text-[16px]"}
          text="financial account managed by an adult on behalf of a minor
until they reach legal adulthood."
        />
        <TypoPrimary
          text="Do you have a custodial investment account
for your dependents?"
        />
        <div className="py-4">
          <InputWrapper customClass={"!gap-6 !justify-start"}>
            <CheckBoxLarge
              icon={<MdCancel />}
              iconStyle={"text-red-400"}
              id={"no"}
              value="no"
              name="custodialAccount"
              selected={step9Data.custodialAccount === "no"}
              onChange={handleChange}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
              text="No, I don’t have"
            />
            <CheckBoxLarge
              icon={<FaCheckCircle />}
              id={"yes"}
              value="yes"
              name="custodialAccount"
              selected={step9Data.custodialAccount === "yes"}
              onChange={handleChange}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
              iconStyle={"text-green-400"}
              text="Yes, I have"
            />
          </InputWrapper>
          {error && <Errortxt />}
          {step9Data.custodialAccount === "no" && (
            <InputWrapper>
              <div className="flex justify-start items-start flex-col mt-4">
                <label>
                  Would you like to learn how to help your children build wealth
                  while in school?
                </label>
                <div className="flex justify-start items-center mt-1">
                  <input
                    type="radio"
                    name="buildWealth"
                    className="w-[15px] h-[15px] mr-2"
                    value="yes"
                    onChange={handleChange}
                    checked={step9Data.buildWealth === "yes"}
                  />{" "}
                  <span>Yes</span>
                </div>
                <div className="flex justify-start items-center">
                  <input
                    type="radio"
                    name="buildWealth"
                    className="w-[15px] h-[15px] mr-2"
                    value="no"
                    onChange={handleChange}
                    checked={step9Data.buildWealth === "no"}
                  />
                  <span>No</span>
                </div>
              </div>
            </InputWrapper>
          )}
          {buildWealthError && <Errortxt />}

          <FooterButtons onNext={submitStep} onBack={handleBack} />
        </div>
      </div>
      {/* <StepImage image={stepImage} /> */}
    </div>
  );
};

export default Step9;
