import React, { useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import Schedule from "./Schedule";
import DebtReport from "./DebtReport";
import DebtPlan from "./DebtPlan";
import UnavailableService from "../../components/Message/UnavailableService";
import useHasPermission from "../../Utils/permissionUtils";

const Debit = () => {
  const hasPermission = useHasPermission("Debts");
  const [active, setActive] = useState("creditor");

  return (
    <>
      <HeaderData text="Debt Plan" />
      {hasPermission ? (
        <>
          <div className="mt-4 mb-4 py-2 px-3 w-full flex justify-center items-center bg-gray-200 rounded-md gap-3">
            {["creditor", "schedule"].map((item) => {
              return (
                <button
                  onClick={() => {
                    setActive(item);
                  }}
                  className={`${
                    active === item
                      ? "text-white bg-[--primary]"
                      : "hover:bg-[--primaryLight]"
                  } flex-grow rounded-md py-3 satoshi-700 capitalize transition-all ease-in-out duration-500 text-[12px] sm:text-[14px] md:text-[17px] xl:text-[19px]`}
                >
                  {item}
                </button>
              );
            })}
          </div>
          {active === "schedule" ? (
            <Schedule />
          ) : active === "report" ? (
            <DebtReport />
          ) : (
            <DebtPlan />
          )}
        </>
      ) : (
        <UnavailableService />
      )}
    </>
  );
};

export default Debit;
