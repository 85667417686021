import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import Loader from "../../components/Loading/Loader";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getCoachAllPrograms, getStripeKey } from "../../Redux/Actions";
import CoachProgramCard from "../../components/Card/CoachProgramCard";
import { loadStripe } from "@stripe/stripe-js";
import api from "../../Redux/Actions/axiosInstance";
import { errorToast } from "../../Utils";

let Once = true;

const CoachPrograms = () => {
  const [laoding, setLoading] = useState(false);
  const allPrograms = useSelector((state) => state.coachPrograms.data);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);
  const { data, isInvoked } = useSelector((state) => state.stripe);

  async function buyProgram(programId, title, price) {
    try {
      // admin public key here
      const stripe = await loadStripe(data?.publicKey);
      const body = {
        packageName: title,
        id: programId,
        price: price,
        userId: userData.userData._id,
        role: "Client",
        coachId: userData.userData.clientInfo.coachId,
        paymentType: "program",
      };

      localStorage.setItem("programBody", JSON.stringify(body));
      const response = await api.post("/createPaymentIntent", body);
      const result = stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });
    } catch (error) {
      errorToast("Something went wrong");
    } finally {
      setLoading(false);
    }
  }

  function getCoachStripeKeys() {
    if (!isInvoked) {
      setLoading(true);
      dispatch(getStripeKey(userData.userData.clientInfo.coachId)).then(() => {
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(allPrograms)) {
        setLoading(true);
        dispatch(
          getCoachAllPrograms(userData.userData.clientInfo.coachId)
        ).then(() => {
          getCoachStripeKeys();
        });
      }
    }
  }, []);

  return (
    <>
      <HeaderData text="Coach Courses" />
      {laoding ? (
        <Loader />
      ) : (
        <section className="w-full mt-4">
          {allPrograms?.length ? (
            <div className="w-full flex justify-start items-stretch gap-6 flex-wrap">
              {allPrograms.map((item, index) => (
                <CoachProgramCard
                  onBuyPackage={() => {
                    buyProgram(item?._id, item?.title, item?.price);
                  }}
                  userData={userData.userData}
                  key={index}
                  data={item}
                />
              ))}
            </div>
          ) : (
            <p>
              The coach has not added any programs yet. Please contact your
              coach or try again later when they have added a program.
            </p>
          )}
        </section>
      )}
    </>
  );
};

export default CoachPrograms;
