import React from "react";

const SelectInput = ({ options }) => {
  return (
    <div className="text-[14px] max-w-[200px]">
      <select
        className="px-[5px] py-1 border outline-none rounded-md overflow-hidden"
        name="data"
        id="he"
      >
        <option value="">This Month</option>
        <option value="">January</option>
        <option value="">January</option>
        <option value="">January</option>
        <option value="">Decemeber</option>
      </select>
    </div>
  );
};

export default SelectInput;
