const initialState = {
  data: [],
};

const debtResultReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PLAN_RESULT":
      return {
        ...state,
        data: payload.data,
      };
    default:
      return state;
  }
};

export default debtResultReducer;
