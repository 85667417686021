const assetTypes = [
  "house",
  "cash",
  "gold",
  "furniture",
  "collectible",
  "land",
  "boat",
];

const debtTypes = [
  "mortgage",
  "student_loans",
  "personal_loans",
  "credit_card",
];

export function structureAddSmartPlan(data, lastData) {
  const {
    step1Data,
    step2Data,
    step3Data,
    step4Data,
    step5Data,
    step6Data,
    step7Data,
    step8Data,
    step9Data,
    step10Data,
    step11Data,
    step12Data,
    step13Data,
    step14Data,
    step15Data,
    step16Data,
    step17Data,
    step18Data,
    step19Data,
    step20Data,
    step21Data,
    step22Data,
    step23Data,
    step24Data,
    step25Data,
  } = data;

  let generatedData = {
    financeShared: step1Data === "no" ? false : true,
    ...(step1Data === "yes" && {
      spouse: {
        dob: {
          month: step2Data.birthMonth,
          year: step2Data.birthYear,
        },
        gender: step2Data.gender,
        retirementAge: step2Data.retirementYear,
      },
    }),
    ...(step1Data === "yes" &&
      step3Data === "yes" && {
        income: {
          companyName: step4Data.companyName,
          income: step4Data.incomeAmount,
          frequency: step4Data.frequency,
          ...(step4Data.additionalIncome && {
            additionalIncome: {
              type: step5Data.additionalIncomeType,
              typeOfBusiness: step5Data.businessType,
              industry: step5Data.industry,
              monthlyIncome: step5Data.amount,
            },
          }),
        },
      }),

    ...(step1Data === "yes" &&
      step3Data === "yes" && {
        spouseIncome: {
          companyName: step6Data.companyName,
          income: step6Data.incomeAmount,
          frequency: step6Data.frequency,
          ...(step6Data.additionalIncome && {
            additionalIncome: {
              type: step6Data.additionalIncomeType,
              typeOfBusiness: step6Data.businessType,
              industry: step6Data.industry,
              monthlyIncome: step6Data.amount,
            },
          }),
        },
      }),

    dependentsInfp: {
      doHave: step7Data === "no" ? false : true,
      ...(step7Data === "yes" && {
        dependents: step8Data,
        custodialAccount: step9Data.custodialAccount === "yes" ? true : false,
        ...(step9Data.custodialAccount === "no" && {
          Teach_Them_Build_Wealth:
            step9Data.buildWealth === "yes" ? true : false,
        }),

        ...(step7Data === "yes" &&
          step9Data.custodialAccount === "yes" && {
            custodialAccountInfo: step10Data,
          }),
      }),
    },
    
    Home: {
      type: step11Data,
      ...(step11Data === "own" && {
        mortgage: step12Data === "yes" ? true : false,
        ...(step12Data === "yes" && {
          mortgageDetails: {
            homeValue: step13Data.homeValue,
            loanBalance: step13Data.loanBalance,
            monthlyPayment: step13Data.monthlyPayment,
            interestRate: step13Data.interestRate,
          },
        }),
      }),

      ...(step11Data === "rent" && {
        rent: step14Data.rentAmount,
      }),
      howToBuyOwnProperty: step15Data === "no" ? false : true,
    },

    dontHaveAccounts: step17Data.map((item) => {
      return item.accountName;
    }),

    accounts: step16Data.map((item) => {
      return {
        type: item.accountType,
        institution: item.instituteName,
        balance: item.currentBalance,
        rateOfReturn: item.returnRate,
        typeOfRetirement: item.type,
      };
    }),

    property: step18Data.map((item) => {
      return {
        name: item.assetType === "Other" ?  item.assetName : item.assetType,
        value: item.assetValue,
        insuranceOnAssets: true,
      };
    }),

    ...(step19Data === "yes" && {
      debts: step20Data.debt.map((item) => {
        return {
          debtType:
            item.debtType === "other" ? item.otherDebtName : item.debtType,
          institution: item.instituteName,
          monthlyPayment: item.monthlyPayment,
          balance: item.balance,
          rate: item.rateOfDebt,
        };
      }),
      debtStanding: step20Data.debtCondition,
      ...(step20Data.debtCondition === "Collectin" && {
        debtGuidance: step20Data?.guidance === "yes" ? true : false,
      }),
    }),

    ...(step21Data.isCreditScore === "yes" && {
      creditScore: step21Data.creditScoreCount,
    }),

    ...(step22Data.spouseIsCreditScore === "yes" && {
      spouseCreditScore: step22Data.spouseCreditScoreCount,
    }),

    budget: step23Data === "yes" ? true : false,
    expense: step24Data.avgMonthlyExpense,
    questions: {
      lastWill: step25Data.lastWill || false,
      livingTrust: step25Data.livingTrust || false,
      healthCarePowerOfAttorney: step25Data.healthCarePowerOfAttorney || false,
      financialPowerOfAttorney: step25Data.financialPowerOfAttorney || false,
      documentsOrganized:
        step25Data.documentsOrganized === "yes" ? true : false,
      identityTheftProtection:
        step25Data.identityTheftProtection === "yes" ? true : false,
      onTrackDebtFree: step25Data.onTrackDebtFree === "yes" ? true : false,
      budgetingAndDebtPayoffTool:
        step25Data.budgetingAndDebtPayoffTool === "yes" ? true : false,
      preparedToManageFinances:
        step25Data.preparedToManageFinances === "yes" ? true : false,
      interestedInEducationAndServices:
        step25Data.interestedInEducationAndServices === "yes" ? true : false,
      reportingRentPayments:
        step25Data.reportingRentPayments === "yes" ? true : false,
    },
    userId: null,
    ...lastData,
  };
  return generatedData;
}

export function generateStateData(
  setStep1Data,
  setStep2Data,
  setStep3Data,
  setStep4Data,
  setStep5Data,
  setStep6Data,
  setStep7Data,
  setStep8Data,
  setStep9Data,
  setStep10Data,
  setStep11Data,
  setStep12Data,
  setStep13Data,
  setStep14Data,
  setStep15Data,
  setStep16Data,
  setStep17Data,
  setStep18Data,
  setStep19Data,
  setStep20Data,
  setStep21Data,
  setStep22Data,
  setStep23Data,
  setStep24Data,
  setStep25Data,
  setStep26Data,
  data
) {
  setStep1Data(data?.financeShared ? "yes" : "no");
  setStep2Data({
    gender: data?.spouse?.gender || null,
    birthMonth: data?.spouse?.dob?.month || null,
    birthYear: data?.spouse?.dob?.year || null,
    retirementYear: data?.spouse?.retirementYear || null,
  });
  setStep3Data(data?.income ? "yes" : "no");
  setStep4Data({
    companyName: data?.income?.companyName || null,
    incomeAmount: data?.income?.income ? parseInt(data?.income?.income) : null,
    frequency: data?.income?.frequency || null,
    additionalIncome: data?.income?.additionalIncome ? true : false || null,
  });

  setStep5Data({
    additionalIncomeType: data?.income?.additionalIncome?.type || null,
    businessType: data?.income?.additionalIncome?.typeOfBusiness || null,
    industry: data?.income?.additionalIncome?.industry || null,
    amount: data?.income?.additionalIncome?.monthlyIncome || false,
  });

  setStep6Data({
    companyName: data?.spouseIncome?.companyName || null,
    incomeAmount: data?.spouseIncome?.income || null,
    frequency: data?.spouseIncome?.frequency || null,
    additionalIncome: data?.spouseIncome?.additionalIncome
      ? true
      : false || false,
    additionalIncomeType: data?.spouseIncome?.additionalIncome?.type || null,
    businessType: data?.spouseIncome?.additionalIncome?.typeOfBusiness || null,
    industry: data?.spouseIncome?.additionalIncome?.industry || null,
    amount: data?.spouseIncome?.additionalIncome?.monthlyIncome || null,
  });

  setStep7Data(data?.dependentsInfp?.doHave ? "yes" : "no");
  setStep8Data(
    data?.dependentsInfp?.doHave
      ? data?.dependentsInfp?.dependents
      : [
          {
            name: null,
            age: null,
          },
        ]
  );
  setStep9Data({
    custodialAccount: data?.dependentsInfp?.custodialAccount ? "yes" : "no",
    buildWealth: data?.dependentsInfp?.Teach_Them_Build_Wealth ? "yes" : "no",
  });
  setStep10Data(
    data?.dependentsInfp?.custodialAccountInfo || [
      {
        Institution: null,
        Amount: null,
      },
    ]
  );

  setStep11Data(data?.Home?.type || null);
  setStep12Data(data?.Home?.mortgageDetails ? "yes" : "no");
  setStep13Data({
    homeValue: data?.Home?.mortgageDetails?.homeValue || null,
    loanBalance: data?.Home?.mortgageDetails?.loanBalance  || null,
    monthlyPayment: data?.Home?.mortgageDetails?.monthlyPayment || null,
    interestRate: data?.Home?.mortgageDetails?.interestRate || null,
  });

  setStep14Data({
    rentAmount: data?.Home?.rent || null,
  });

  setStep15Data(data?.Home?.howToBuyOwnProperty ? "yes" : "no");
  const accountsData = data?.accounts?.map((item) => {
    return {
      accountType: item?.type || null,
      currentBalance: item?.balance || null,
      instituteName: item?.institution || null,
      returnRate: item?.rateOfReturn || null,
      type: item?.typeOfRetirement || null,
    };
  });
  setStep16Data(accountsData);
  const notHavedAccount = data?.dontHaveAccounts?.map((item) => {
    return {
      accountName: item || null,
    };
  });
  setStep17Data(notHavedAccount);
  const properties = data?.property?.map((item) => {
    return {
      assetType: assetTypes.includes(item?.name) ? item.name : "other",
      assetName: item.name,
      assetValue: item?.value,
      assetInsurrance: item?.insuranceOnAssets,
    };
  });
  setStep18Data(properties);

  setStep19Data(data?.debts?.length ? "yes" : "no");
  const debts = data?.debts?.map((item) => {
    return {
      debtType: debtTypes.includes(item?.debtType) ? item.debtType : "other",
      instituteName: item?.institution,
      monthlyPayment: item?.monthlyPayment,
      balance: item?.balance,
      rateOfDebt: item?.rate,
      otherDebtName: item?.debtType,
    };
  });
  setStep20Data({
    debt: data?.debts?.length
      ? debts
      : [
          {
            debtType: null,
            instituteName: null,
            monthlyPayment: null,
            balance: null,
            rateOfDebt: null,
            otherDebtName: null,
          },
        ],
    debtCondition: data?.debtStanding,
    guidance: data?.debtGuidance ? "yes" : "no",
  });
  setStep21Data({
    isCreditScore: data?.creditScore ? "yes" : "no",
    creditScoreCount: data?.creditScore || null,
  });

  setStep22Data({
    spouseIsCreditScore: data?.spouseCreditScore ? "yes" : "no",
    spouseCreditScoreCount: data?.spouseCreditScore || null,
  });

  setStep23Data(data?.budget ? "yes" : "no");
  setStep24Data({ avgMonthlyExpense: data?.expense || null });
  setStep25Data({
    lastWill: data?.questions?.lastWill,
    livingTrust: data?.questions?.livingTrust,
    healthCarePowerOfAttorney: data?.questions?.healthCarePowerOfAttorney,
    financialPowerOfAttorney: data?.questions?.financialPowerOfAttorney,
    documentsOrganized: data?.questions?.documentsOrganized ? "yes" : "no",
    identityTheftProtection: data?.questions?.identityTheftProtection
      ? "yes"
      : "no",
    onTrackDebtFree: data?.questions?.onTrackDebtFree ? "yes" : "no",
    budgetingAndDebtPayoffTool: data?.questions?.budgetingAndDebtPayoffTool
      ? "yes"
      : "no",
    preparedToManageFinances: data?.questions?.preparedToManageFinances
      ? "yes"
      : "no",
    interestedInEducationAndServices: data?.questions
      ?.interestedInEducationAndServices
      ? "yes"
      : "no",
    reportingRentPayments: data?.questions?.reportingRentPayments
      ? "yes"
      : "no",
  });

  setStep26Data({
    personalInfo: {
      retirementAge: data?.personalInfo?.retirementAge,
      dob: data?.personalInfo?.dob,
    },
  });
}
