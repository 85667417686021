import React, { useContext, useEffect } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
// import TypoGray from "./TypoGray";
import SelectBox from "../Input/SelectBox";
import InputWrapper from "../Wrapper/InputWrapper";
import { genderData, generateYears, monthsData } from "../../Data/InputData";
// import { useState } from "react";
import RetirementInput from "../Input/RetirementInput";
import FooterButtons from "./FooterButtons";
import smartPlanImage from "../../Assets/sm-2.png";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import FormWrapper from "./FormWrapper";
import { useFormik } from "formik";
import { step2Schema } from "../../Schema";

const Step2 = ({ handleNext, handleBack, direction }) => {
  const { step1Data, step2Data, setStep2Data } = useContext(SmartPlanContext);

  const initialState = step2Data;

  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: step2Schema,
      onSubmit: (values) => {
        setStep2Data(values);
        handleNext();
      },
    });

  // const calculateRetiredAge = (retiredYearMonth, birthMonth, birthYear) => {
  //   const birthDate = new Date(`${birthYear}-${birthMonth}-01`);
  //   const retiredDate = new Date(`${retiredYearMonth}-01`);

  //   let ageInMonths =
  //     (retiredDate.getFullYear() - birthDate.getFullYear()) * 12;
  //   ageInMonths -= birthDate.getMonth() + 1;
  //   ageInMonths += retiredDate.getMonth() + 1;

  //   const calculatedYears = Math.floor(ageInMonths / 12);
  //   const calculatedMonths = ageInMonths % 12;

  //   const ageString = `${calculatedYears} year${
  //     calculatedYears !== 1 ? "s" : ""
  //   } ${calculatedMonths} month${calculatedMonths !== 1 ? "s" : ""}`;
  //   setData((pre) => {
  //     return {
  //       ...pre,
  //       retiredAge: ageString,
  //     };
  //   });
  // };

  useEffect(() => {
    if (step1Data === "no") {
      setStep2Data({
        gender: null,
        birthMonth: null,
        birthYear: null,
        retirementYear: null,
        // longevityAge: null,
      });
      if (direction === "next") handleNext();
      else handleBack();
    }
  }, []);

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Tell us a little about your spouse" />

        <div className="py-4">
          <InputWrapper customClass={"!mt-1 "}>
            <SelectBox
              label="Sex"
              placeholder="Please select gender"
              id="gender"
              name="gender"
              labelStyle={"!font-normal"}
              customClasses={"bg-white !text-black"}
              multiStyle={"flex-grow basis-36 !mt-1"}
              options={genderData}
              onchange={handleChange}
              onBlur={handleBlur}
              value={values.gender}
              error={errors.gender}
              touch={touched.gender}
            />
          </InputWrapper>
          <InputWrapper customClass={"!mt-1"}>
            <SelectBox
              label="Birth Month"
              id="month"
              name="birthMonth"
              labelStyle={"!font-normal"}
              customClasses={"bg-white !text-black"}
              multiStyle={"flex-grow basis-36 !mt-1"}
              options={monthsData}
              placeholder="Select Birth Month"
              value={values.birthMonth}
              onchange={handleChange}
              onBlur={handleBlur}
              error={errors.birthMonth}
              touch={touched.birthMonth}
            />

            <SelectBox
              label="Birth year"
              id="year"
              name="birthYear"
              labelStyle={"!font-normal"}
              customClasses={"bg-white !text-black"}
              multiStyle={"flex-grow basis-36 !mt-1"}
              options={generateYears(1950, 2005)}
              placeholder="Please select birth year"
              onchange={handleChange}
              onBlur={handleBlur}
              value={values.birthYear}
              error={errors.birthYear}
              touch={touched.birthYear}
            />
          </InputWrapper>
          <SelectBox
            multiStyle={"!mt-0 flex-grow"}
            placeholder="Select Age"
            customClasses={"!py-[9px] bg-white !text-black "}
            label="Retirement Age"
            id="year"
            options={generateYears(18, 130)}
            name="retirementYear"
            onchange={handleChange}
            onBlur={handleBlur}
            value={values.retirementYear}
            error={errors.retirementYear}
            touch={touched.retirementYear}
          />

          <FooterButtons onNext={handleSubmit} onBack={handleBack} />
        </div>
      </FormWrapper>
      <StepImage image={smartPlanImage} />
    </div>
  );
};

export default Step2;
