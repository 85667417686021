import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import FluidCard from "../../components/Card/FluidCard";
import { IoMdAdd } from "react-icons/io";
// import Modal from "../../components/Modal/Modal";
// import ButtonSmall from "../../components/Buttons/ButtonSmall";
// import InputRequired from "../../components/Input/InputRequired";
// import RadioInputWrapper from "../../components/Wrapper/RadioInputWrapper";
import { useDispatch, useSelector } from "react-redux";
// import { isEmpty } from "lodash";
import { getAccountsInfo } from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import AddEditAccount from "../../components/Modal/AddEditAccount";

let Once = true;

const Accounts = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const { data, isInvoked } = useSelector((state) => state.accounts);

  // function handleOpenEdit(data) {
  //   setEditData(data);
  //   setIsEdit(true);
  //   setOpenModal(true);
  // }

  function handleAddEdit() {
    setEditData({});
    setIsEdit(false);
    setOpenModal(!openModal);
  }

  function countQuantity(type) {
    return data.filter((item) => item.accountType.toLowerCase() === type)
      .length;
  }

  function returnUrl(type) {
    const filteredData = data.find(
      (item) => item.accountType.toLowerCase() === type
    );
    if (filteredData) {
      return `/account-detail/${type}`;
    } else {
      return "/accounts";
    }
  }

  function countOther() {
    return data.filter((item) => {
      const type = item.accountType.toLowerCase();
      return !["saving", "checking", "investment", "retirement"].includes(type);
    }).length;
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (!isInvoked) {
        setLoading(true);
        dispatch(getAccountsInfo()).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);

  return (
    <>
      {openModal ? (
        <AddEditAccount
          open={openModal}
          handleClose={handleAddEdit}
          isEdit={isEdit}
          editData={editData}
        />
      ) : null}
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderData text="Accounts">
            <ButtonIcon
              onclick={handleAddEdit}
              icon={<IoMdAdd />}
              text="Add new account"
            />
          </HeaderData>
          <CardWrapper>
            <FluidCard
              url={returnUrl("saving")}
              withGraph={false}
              withDropdown={false}
              text="Saving Accounts"
              value={countQuantity("saving")}
            />
            <FluidCard
              url={returnUrl("checking")}
              withGraph={false}
              withDropdown={false}
              text="Checking Accounts"
              value={countQuantity("checking")}
            />
            <FluidCard
              url={returnUrl("investment")}
              withGraph={false}
              withDropdown={false}
              text="Investment Accounts"
              value={countQuantity("investment")}
            />
            <FluidCard
              url={returnUrl("retirement")}
              withGraph={false}
              withDropdown={false}
              text="Retirement accounts"
              value={countQuantity("retirement")}
            />
            <FluidCard
              url={returnUrl("others")}
              withGraph={false}
              withDropdown={false}
              text="Other Accounts"
              value={countOther()}
            />
          </CardWrapper>
        </>
      )}
    </>
  );
};

export default Accounts;
