const initialState = {
  packagesData: [],
  isPackageInvoked: false,
};

const packageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PACKAGES":
      return {
        ...state,
        packagesData: payload.data,
        isPackageInvoked: true,
      };
    default:
      return state;
  }
};

export default packageReducer;
