import React, { useContext, useEffect } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import FooterButtons from "./FooterButtons";
import SelectBox from "../Input/SelectBox";
import { additionalIncome } from "../../Data/InputData";
import InputRequired from "../Input/InputRequired";
import stepImage from "../../Assets/sm-5.png";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import FormWrapper from "./FormWrapper";
import { useFormik } from "formik";
import { step5Schema } from "../../Schema";

const Step5 = ({ handleNext, handleBack, direction }) => {
  const { step4Data, step5Data, setStep5Data } = useContext(SmartPlanContext);
  const initialState = step5Data;
  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: step5Schema,
      onSubmit: (values) => {
        setStep5Data(values);
        handleNext();
      },
    });

  useEffect(() => {
    if (!step4Data.additionalIncome) {
      if (direction === "next") handleNext();
      else handleBack();
    }
  }, []);
  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Do you have additional income?" />
        <div className="py-2">
          <InputWrapper customClass={"!items-stretch"}>
            <SelectBox
              multiStyle={"!mt-0 flex-grow"}
              placeholder="Add Additional Income"
              customClasses={"!py-[9px] bg-white !text-black"}
              label="Additional Income"
              options={additionalIncome}
              id="year"
              name="additionalIncomeType"
              value={values.additionalIncomeType}
              error={errors.additionalIncomeType}
              touch={touched.additionalIncomeType}
              onchange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>
          {values?.additionalIncomeType === "business" && (
            <>
              <InputWrapper>
                <InputRequired
                  name="businessType"
                  label="Business Type"
                  placeholder="Enter business type"
                  type="text"
                  value={values.businessType}
                  error={errors.businessType}
                  touch={touched.businessType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputWrapper>
              <InputWrapper>
                <InputRequired
                  name="industry"
                  label="Industry"
                  placeholder="Industry"
                  type="text"
                  value={values.industry}
                  error={errors.industry}
                  touch={touched.industry}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </InputWrapper>
            </>
          )}

          <InputWrapper>
            <InputRequired
              label="Amount"
              placeholder="0"
              type="number"
              name="amount"
              value={values.amount}
              touch={touched.amount}
              error={errors.amount}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>

          <FooterButtons onNext={handleSubmit} onBack={handleBack} />
        </div>
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step5;
