import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import { IoMdAdd } from "react-icons/io";
import CardWeek from "../../components/Card/CardWeek";
import { useDispatch, useSelector } from "react-redux";
import { completeGoal, getGoalsInfo } from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import AddGoal from "../../components/Modal/AddGoal";
import EditGoal from "../../components/Modal/EditGoal";
import DeleteGoal from "../../components/Modal/DeleteGoal";
import UnavailableService from "../../components/Message/UnavailableService";
import useHasPermission from "../../Utils/permissionUtils";
import TextModal from "../../components/Modal/TextModal";

let Once = true;
const Goals = () => {
  const hasPermission = useHasPermission("Goals");
  const hasPermissionToManage = useHasPermission("Manage Goals");
  const hasPermissionToCreate = useHasPermission("Create new Goals");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { data, isInvoked } = useSelector((state) => state.goals);
  const [editData, setEditData] = useState({});
  const [editGoalModal, setEditModal] = useState(false);
  const [goalDelete, setGoalDelete] = useState({
    status: false,
    id: null,
  });
  const [textModal, setTextModal] = useState(false);

  function handleEditGoal(data) {
    if (hasPermissionToManage) {
      setEditData(data);
      setEditModal(true);
    } else {
      setTextModal(true);
    }
  }

  function handleCloseEditMode() {
    setEditData({});
    setEditModal(false);
  }

  function handleModal() {
    if (hasPermissionToCreate) {
      setOpenModal(!openModal);
    } else {
      setTextModal(true);
    }
  }

  function handleDeleteGoal(status = false, goalId = null) {
    if (hasPermissionToManage) {
      setGoalDelete({
        status: status,
        id: goalId,
      });
    }
  }

  function handleCompletegoal(id) {
    setLoading(true);
    dispatch(completeGoal(id)).then(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (!isInvoked) {
        setLoading(true);
        dispatch(getGoalsInfo()).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);

  return hasPermission || hasPermission ? (
    <>
      {textModal && (
        <TextModal
          open={textModal}
          isPermission={true}
          handleClose={() => {
            setTextModal(false);
          }}
        />
      )}
      {openModal ? (
        <AddGoal open={openModal} handleClose={handleModal} />
      ) : null}
      {editGoalModal ? (
        <EditGoal
          data={editData}
          handleClose={handleCloseEditMode}
          open={editGoalModal}
        />
      ) : null}
      {goalDelete.status && (
        <DeleteGoal
          open={goalDelete.status}
          data={goalDelete}
          handleModal={() => {
            handleDeleteGoal();
          }}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderData text="Goals">
            <ButtonIcon
              onclick={handleModal}
              icon={<IoMdAdd />}
              text="Add new goal"
            />
          </HeaderData>
          {data.length ? (
            <CardWrapper extraClasses="!items-stretch">
              {data
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((item, index) => {
                  return (
                    <CardWeek
                      key={index}
                      goalData={item}
                      handleEdit={() => {
                        handleEditGoal(item);
                      }}
                      onDeleteGoal={() => {
                        handleDeleteGoal(true, item._id);
                      }}
                      handleMarkComplete={() => {
                        handleCompletegoal(item?._id);
                      }}
                    />
                  );
                })}
            </CardWrapper>
          ) : (
            <p>You have no goal</p>
          )}
        </>
      )}
    </>
  ) : (
    <UnavailableService />
  );
};

export default Goals;
