const initialState = {
  data: [],
  totalIncome: 0,
  totalExpense: 0,
  isInvoked: false,
};

const budgetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_BUDGETS":
      return {
        ...state,
        data: payload.data,
        isInvoked: true,
      };
    case "ADD_INCOME":
      return {
        ...state,
        totalIncome: payload,
      };
    case "ADD_EXPENSE":
      return {
        ...state,
        totalExpense: payload,
      };
    case "ADD_BUDGET":
      return {
        ...state,
        data: payload.data,
      };
    default:
      return state;
  }
};

export default budgetReducer;
