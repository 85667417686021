import React from "react";

const CheckBoxLarge = ({
  id,
  name,
  value,
  onChange,
  icon,
  text,
  iconStyle,
  selected,
  customClasses,
}) => {
  return (
    <div
      className={`flex-grow basis-36 max-w-[350px] border flex justify-center items-center rounded-xl text-center ${customClasses} ${
        selected
          ? "bg-blue-200"
          : "hover:bg-gray-100 transition-all ease-in-out duration-300"
      }`}
    >
      <input
        type="radio"
        id={id}
        name={name}
        className="w-full h-full"
        onChange={onChange}
        hidden
        value={value}
      />
      <label
        htmlFor={id}
        className="w-full h-full flex justify-center items-center flex-col  cursor-pointer py-7"
      >
        <span className={`text-5xl mb-2 ${iconStyle}`}>{icon}</span>
        <h6 className="w-[95%]  satoshi-500 text-[--primary] text-[17px] mt-3">
          {text}
        </h6>
      </label>
    </div>
  );
};

export default CheckBoxLarge;
