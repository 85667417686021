import React from "react";

const TypoGray = ({ text, customClasses }) => {
  return (
    <h6 className={`text-[--gray-dark] text-[14px] ${customClasses}`}>
      {text}
    </h6>
  );
};

export default TypoGray;
