import React, { useState } from "react";
import Modal from "./Modal";
import InputRequired from "../Input/InputRequired";
import { IoMdAdd } from "react-icons/io";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useFormik } from "formik";
import { accountSchema } from "../../Schema";
import { useDispatch } from "react-redux";
import { addAccountInfo } from "../../Redux/Actions";
import ButtonLoading from "../Buttons/ButtonLoading";
import useHasPermission from "../../Utils/permissionUtils";

const options = [
  {
    name: "accountType",
    label: "Checking",
    value: "checking",
    id: "checking",
  },
  {
    name: "accountType",
    label: "Savings",
    value: "saving",
    id: "saving",
  },
  {
    name: "accountType",
    label: "Investment",
    value: "investment",
    id: "investment",
  },
  {
    name: "accountType",
    label: "Retirement",
    value: "retirement",
    id: "retirement",
  },
];
const labelClassess = "satoshi-500 !mt-3 !text-black !text-[16px]";

const AddEditAccount = ({ open, handleClose, isEdit, editData }) => {
  const hasPermissionToAddChecking = useHasPermission("Add Checking account");
  const hasPermissionToAdInvestment = useHasPermission(
    "Add Investment Account"
  );
  const hasPermissionToAddRetirement = useHasPermission(
    "Add Retirement Account"
  );
  const hasPermissionToAddSaving = useHasPermission("Add Saving Account");
  const [otherAccType, setOtherAccType] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const initialState = {
    title: isEdit ? editData.title : "",
    accountNo: isEdit ? editData.accountNo : "",
    institutionName: isEdit ? editData.institutionName : "",
    accountType: isEdit ? editData.accountType : "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: accountSchema,
      onSubmit: (values, action) => {
        setLoading(true);
        dispatch(addAccountInfo(values)).then(() => {
          setLoading(false);
          action.resetForm();
          handleClose();
        });
      },
    });

  function handleOtherType() {
    values.accountType = "";
    setOtherAccType(!otherAccType);
  }

  return (
    <Modal
      open={open}
      headerText={isEdit ? "Update Account" : "Add an account"}
      onclick={handleClose}
    >
      <InputRequired
        name="institutionName"
        label="Institution Name"
        placeholder="12th century Bank"
        type="text"
        labelStyle={labelClassess}
        value={values.institutionName}
        error={errors.institutionName}
        touch={touched.institutionName}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <InputRequired
        name="accountNo"
        label="Last 4 digit of Account (optional)"
        placeholder="4214"
        type="number"
        labelStyle={labelClassess}
        value={values.accountNo}
        error={errors.accountNo}
        touch={touched.accountNo}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <InputRequired
        name="title"
        label="Account Title"
        placeholder="John Doe"
        type="text"
        labelStyle={labelClassess}
        value={values.title}
        error={errors.title}
        touch={touched.title}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <div className="flex-grow basis-52">
        <p
          className={`block mb-1 pl-[2px]  text-[16px] text-[#333333] capitalize ${labelClassess}`}
        >
          Account Type
        </p>
        {options.map((item, index) => {
          if (
            (item.value === "checking" && hasPermissionToAddChecking) ||
            (item.value === "saving" && hasPermissionToAddSaving) ||
            (item.value === "investment" && hasPermissionToAdInvestment) ||
            (item.value === "retirement" && hasPermissionToAddRetirement)
          ) {
            return (
              <div
                key={index}
                className="flex justify-start items-center pl-1 mb-1"
              >
                <input
                  name={item.name}
                  type="radio"
                  id={item.id}
                  checked={values.accountType === item.value}
                  onChange={handleChange}
                  className={`w-4 h-4 cursor-pointer`}
                  value={item.value}
                />
                <label
                  htmlFor={item.id}
                  className={`ml-2  cursor-pointer satoshi-400 text-[16px]`}
                >
                  {item.label}
                </label>
              </div>
            );
          }
        })}
      </div>

      {/* <RadioInputWrapper
        label="Account Type"
        type="radio"
        labelClasses={labelClassess}
        inputClassess=""
        inputLabelStyle=""
        options={[
          {
            name: "accountType",
            label: "Checking",
            value: "checking",
            id: "checking",
          },
          {
            name: "accountType",
            label: "Savings",
            value: "saving",
            id: "saving",
          },
          {
            name: "accountType",
            label: "Investment",
            value: "investment",
            id: "investment",
          },
          {
            name: "accountType",
            label: "Retirement",
            value: "retirement",
            id: "retirement",
          },
        ]}
      /> */}

      <div
        onClick={handleOtherType}
        className="flex justify-start w-max items-center gap-1 mt-3 cursor-pointer text-[--primary] transition-all ease-in-out duration-300 hover:text-blue-800 "
      >
        <IoMdAdd className="satoshi-700 text-xl" />
        <p className="satoshi-700 text-[16px]">Add new account type</p>
      </div>
      {otherAccType ? (
        <InputRequired
          name="accountType"
          label="Account Type"
          placeholder="Add new account type"
          type="text"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.accountType}
          labelStyle={labelClassess}
        />
      ) : (
        ""
      )}

      {touched.accountType && errors.accountType ? (
        <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
          {errors.accountType}
        </span>
      ) : null}

      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              text="Cancel"
              type="button"
              onClick={handleClose}
              customClasses={
                "!w-1/2 !bg-white !text-[--primary] !border-[--primary]"
              }
            />
            <ButtonSmall
              onClick={handleSubmit}
              text={isEdit ? "Update" : "Add"}
              customClasses={"!w-1/2"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddEditAccount;
