import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import ButtonSmall from "../Buttons/ButtonSmall";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { deleteBudgetCategory, getBudgetTypes } from "../../Redux/Actions";
import Loader from "../Loading/Loader";
import { isEmpty } from "lodash";
import RowWrapper from "../Wrapper/RowWrapper";
import ButtonLoading from "../Buttons/ButtonLoading";
import { MdDelete } from "react-icons/md";
import DeleteSubBudget from "./DeleteSubBudget";

let Once = true;

const BudgetDetail = ({ open, handleModal, data }) => {
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const budgetTypes = useSelector((state) => state.types.data);
  const dispatch = useDispatch();
  const [subModalData, setSubModalData] = useState({
    header: "",
    id: "",
    modal: false,
  });

  function findName(subCatId, typ = "subCategory", budgetId = null) {
    const targetBudget = budgetTypes.find((item) => item?.name === "Expense");
    const targetCategory = targetBudget.Categories.find(
      (cat) => cat?.name === data.categoryName
    );

    const targetSubCategory = targetCategory?.Subcategories.find(
      (subCat) => subCat._id == subCatId
    );

    if (typ === "subCategory") {
      return targetSubCategory?.name || "Removed";
    } else {
      if (targetSubCategory) {
        const targetPurpose = targetSubCategory?.Purpose.find(
          (purpose) => purpose?.id == budgetId
        );
        return targetPurpose?.name;
      } else {
        return "Deleted";
      }
    }
  }

  function handleDelete() {
    setBtnLoading(true);
    dispatch(deleteBudgetCategory(data.categoryName)).then(() => {
      setBtnLoading(false);
      handleModal();
    });
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(budgetTypes)) {
        setLoading(true);
        dispatch(getBudgetTypes()).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);

  return (
    <Modal open={open} onclick={handleModal} headerText={data.categoryName}>
      {loading ? (
        <Loader />
      ) : budgetTypes.length ? (
        <>
          {subModalData.modal ? (
            <DeleteSubBudget
              open={subModalData.modal}
              handleClose={() => {
                setSubModalData({ header: "", id: "", modal: false });
              }}
              data={subModalData}
              closeParentModal={handleModal}
            />
          ) : null}
          <div className="w-full flex justify-between gap-4 items-stretch flex-wrap px-2 py-2">
            {data.transactions.map((item, index) => {
              return (
                item.amount > 0 && (
                  <div
                    key={index}
                    className="flex-grow basis-60 boxShadow p-3 rounded-md max-w-[700px]"
                  >
                    {/* <div className="py-2 flex justify-end items-center">
                    <MdDelete
                      onClick={() => {
                        setSubModalData({
                          header: findName(item.subCategory),
                          id: item._id,
                          modal: true,
                        });
                      }}
                      className="text-[20px] text-red-600 cursor-pointer"
                    />
                  </div> */}
                    <RowWrapper
                      title="Sub-Category"
                      value={findName(item.subCategory)}
                    />
                    <RowWrapper
                      title="Purpose"
                      value={findName(
                        item.subCategory,
                        "purpose",
                        item.budgetPurpose
                      )}
                    />
                    <RowWrapper title="Amount" value={`$ ${item.amount}`} />
                    <RowWrapper
                      title="Date"
                      value={moment(parseInt(item.createdAt)).format(
                        "DD MMM, YYYY"
                      )}
                    />
                  </div>
                )
              );
            })}
          </div>
          <div className="flex justify-end items-center mt-2 pr-3">
            <h6>
              <span className="font-semibold">Total Amount :</span> ${" "}
              {data.totalRecordsAmount}
            </h6>
          </div>
        </>
      ) : null}

      <div className="flex justify-center items-center gap-4 pt-8">
        {btnLoading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2"}
              onClick={handleModal}
            />
            {/* <ButtonSmall
              onClick={handleDelete}
              type="button"
              text="Delete"
              customClasses={"!w-1/2 !bg-red-800 !text-white !border-red-600"}
            /> */}
          </>
        )}
      </div>
    </Modal>
  );
};

export default BudgetDetail;
