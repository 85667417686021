import React, { useContext, useEffect } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import TypoGray from "./TypoGray";
import InputWrapper from "../Wrapper/InputWrapper";
import FooterButtons from "./FooterButtons";
import stepImage from "../../Assets/sm-15.png";
import InputRequired from "../Input/InputRequired";
import SelectBox from "../Input/SelectBox";
import { generateYears } from "../../Data/InputData";
import { IoMdAdd } from "react-icons/io";
import ButtonIcon from "../Buttons/ButtonIcon";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import { useFormik } from "formik";
import { step8Schema } from "../../Schema";
// import { removeElementByIndex } from "../../Utils";
import { MdDeleteForever } from "react-icons/md";

const Step8 = ({ handleNext, handleBack, direction }) => {
  const { step7Data, step8Data, setStep8Data } = useContext(SmartPlanContext);
  const initialState = step8Data;

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: step8Schema,
    onSubmit: (values) => {
      setStep8Data(values);
      console.log(values);
      handleNext();
    },
  });

  const appendField = () => {
    formik.setValues([...formik.values, { name: "", age: "" }]);
  };

  const removeInput = (targetIndex) => {
    const updatedValues = formik.values.filter(
      (_, index) => index !== targetIndex
    );
    formik.setValues(updatedValues);
  };

  function handleChange(e, index) {
    const { value, name } = e.target;
    let updatedData = formik.values.map((item, i) => {
      return i === index ? { ...item, [name]: value } : item;
    });
    formik.setValues(updatedData);
  }

  useEffect(() => {
    if (step7Data === "no") {
      setStep8Data([
        {
          name: null,
          age: null,
        },
      ]);
      if (direction === "next") handleNext();
      else handleBack();
    }
  }, []);
  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <div className="w-full py-2 pl-10 pr-4">
        <TypoGray
          customClasses={"max-w-[80%] mt-2 !uppercase  md:text-[16px]"}
          text="COMPLETE YOUR DEPENDENTS DETAILS."
        />
        <TypoPrimary text="How many dependents do you have and what are their ages?" />

        <div className="py-4">
          {formik.values.map((item, index) => {
            return (
              <InputWrapper key={index} customClass={"!items-stretch"}>
                <InputRequired
                  label="Name"
                  placeholder="Enter name"
                  type="text"
                  name="name"
                  value={item.name}
                  error={formik.errors[index]?.name}
                  touch={formik.touched[index]?.name}
                  onChange={(e) => {
                    handleChange(e, index);
                  }}
                  onBlur={formik.handleBlur}
                />

                <SelectBox
                  multiStyle={"!mt-0 min-w-[30%]"}
                  placeholder="Select Age "
                  customClasses={"!py-[9px] bg-white !text-black"}
                  label="Age (Years)"
                  id="year"
                  name="age"
                  options={generateYears(1, 70)}
                  value={item.age}
                  error={formik.errors[index]?.age}
                  touch={formik.touched[index]?.age}
                  onchange={(e) => {
                    handleChange(e, index);
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik?.values?.length > 1 ? (
                  <div
                    className={`text-center flex justify-center ${
                      formik.errors?.[index]?.age ||
                      formik.errors?.[index]?.name
                        ? "items-end"
                        : "items-center"
                    } mt-0`}
                  >
                    <button
                      onClick={() => {
                        removeInput(index);
                      }}
                      className="flex justify-center items-center px-[6px] py-[8px] border border-red-600 text-red-600 transition-all ease-in-out duration-300 hover:bg-[--primary] hover:border-[--priamry] hover:text-white"
                    >
                      <MdDeleteForever className="text-[20px]" />
                    </button>
                  </div>
                ) : null}
              </InputWrapper>
            );
          })}

          <div className="w-full flex justify-center items-center mt-4">
            <ButtonIcon
              onclick={() => {
                appendField("dummy");
              }}
              icon={<IoMdAdd />}
              text="Add"
            />
          </div>

          <FooterButtons onNext={formik.handleSubmit} onBack={handleBack} />
        </div>
      </div>
    </div>
  );
};

export default Step8;
