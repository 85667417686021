import React, { useEffect, useState } from "react";
import InputRequired from "../Input/InputRequired";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import Modal from "./Modal";
import { useFormik } from "formik";
import { goalSchema } from "../../Schema";
import { useDispatch, useSelector } from "react-redux";
import { addGoal, getAssignedProgram } from "../../Redux/Actions";
import { isEmpty } from "lodash";
import Loader from "../Loading/Loader";
const labelClassess = "satoshi-500 !mt-3 !text-black !text-[16px]";
let Once = true;

const AddGoal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.programs.data);

  const initialState = {
    goalType: "",
    name: "",
    duration: "",
    durationType: "",
    amount: "",
    programs: [],
    clientId: null,
  };
  const [loading, setLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [error, setError] = useState(false);

  const {
    values,
    setValues,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: initialState,
    validationSchema: goalSchema,
    onSubmit: (values, action) => {
      if (values.goalType === "Financial" && values.amount < 1) {
        setError(true);
        return;
      }
      setLoading(true);
      dispatch(addGoal(values)).then(() => {
        action.resetForm();
        setLoading(false);
        handleClose();
      });
    },
  });

  useEffect(() => {
    if (Once) {
      Once = false;
      if (isEmpty(programData)) {
        setPageLoader(true);
        dispatch(getAssignedProgram()).then(() => {
          setPageLoader(false);
        });
      }
    }
  }, []);

  return (
    <Modal open={open} headerText={"Add Goal"} onclick={handleClose}>
      {pageLoader ? (
        <Loader />
      ) : (
        <>
          <div className="w-full flex justify-start items-start flex-col ">
            <div className="w-full">
              <label
                htmlFor="Category"
                className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
              >
                Goal Type
              </label>
              <select
                className="w-full rounded-md  border outline-none py-2 px-2"
                name="goalType"
                id="programType"
                onBlur={handleBlur}
                value={values.goalType}
                onChange={(e) => {
                  setValues((pre) => {
                    return {
                      ...pre,
                      amount: 0,
                      [e.target.name]: e.target.value,
                    };
                  });
                }}
              >
                <option value="">Select Goal Type</option>
                <option className="text-black" value="Financial">
                  Financial
                </option>
                <option className="text-black" value="Nonfinancial">
                  Non Financial
                </option>
              </select>
            </div>
            {touched?.goalType && errors?.goalType && (
              <p className="text-red-600 text-[14px] mt-1 text-center">
                {errors?.goalType}
              </p>
            )}
          </div>
          <InputRequired
            name="name"
            label="Title"
            placeholder="Goal Title "
            type="text"
            labelStyle={labelClassess}
            value={values.name}
            touch={touched.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.name}
          />
          {values.goalType === "Financial" && (
            <InputRequired
              name="amount"
              label="Goal Amount"
              placeholder="Goal Amount"
              type="number"
              labelStyle={labelClassess}
              value={values.amount}
              touch={touched.amount}
              onChange={(e) => {
                setError(false);
                handleChange(e);
              }}
              onBlur={handleBlur}
              error={error ? "Amount must be atleast 1" : null}
            />
          )}

          <div>
            <div className="mt-3">
              <label
                htmlFor="duration"
                className={`mb-1 pl-[2px] capitalize ${labelClassess}`}
              >
                Duration
              </label>
            </div>
            <div className="flex justify-start items-center">
              <input
                name="duration"
                value={values.duration}
                onBlur={handleBlur}
                onChange={handleChange}
                className="border min-w-max w-[20%] border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg focus:border-gray-800 block p-2"
                type="number"
                placeholder="Duration Value"
              />
              <select
                className="ml-3 border min-w-[120px] w-[20%] border-gray-300 outline-none text-gray-900 sm:text-sm rounded-lg focus:border-gray-800 block p-2"
                name="durationType"
                value={values.durationType}
                onChange={handleChange}
              >
                <option disabled value="">
                  Duration
                </option>
                <option value="days">Days</option>
                <option value="weeks">Weeks</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </select>
            </div>
            {touched.duration && errors.duration ? (
              <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
                {errors.duration}
              </span>
            ) : touched.durationType && errors.durationType ? (
              <span className="text-[12px] md:text-[14px] text-red-500 ml-3 mt-1">
                {errors.durationType}
              </span>
            ) : null}
          </div>

          {/*
          {programData?.length ? (
            <div className="mt-2">
              <h1>Please select program</h1>
              <div className="flex justify-start items-stretch flex-wrap gap-3 py-2">
                {programData.map((programInfo, index) => {
                  return (
                    <div
                      key={index}
                      className="mediaCardShadow rounded-lg w-[200px]"
                    >
                      <div className="flex justify-end items-center py-2">
                        <input
                          type="checkbox"
                          value={programInfo._id}
                          className="h-[15px] w-full cursor-pointer"
                          // checked={values.programs.includes(programInfo._id)}
                          onClick={handleProgram}
                        />
                      </div>
                      <div className="w-full">
                        <div className="flex justify-center items-center">
                          <img
                            className="h-32 w-full"
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              programInfo?.photo
                            }
                            alt="Blog cover"
                          />
                        </div>
                        <div className="w-full pt-2">
                          <h4 className="satoshi-700 text-[16px] pl-2">
                            {programInfo?.title}
                          </h4>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {errors.programs && touched.programs ? (
                <span className="text-red-600 text-[14px] my-2">
                  {errors.programs}
                </span>
              ) : null}
            </div>
          ) : null}

            */}

          <div className="flex justify-center items-center gap-4 pt-8">
            {loading ? (
              <ButtonLoading />
            ) : (
              <>
                <ButtonSmall
                  text="Cancel"
                  type="button"
                  onClick={handleClose}
                  customClasses={
                    "!w-1/2 !bg-white !text-[--primary] !border-[--primary]"
                  }
                />
                <ButtonSmall
                  onClick={handleSubmit}
                  text={"Add"}
                  customClasses={"!w-1/2"}
                />
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default AddGoal;
