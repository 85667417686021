import React, { useEffect, useState } from "react";
import PackageCard from "../../components/Card/PackageCard";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoachPackages,
  getStripeKey,
  subscribeToPackage,
} from "../../Redux/Actions";
import Loader from "../../components/Loading/Loader";
import HeaderData from "../../components/Header/HeaderData";
import { loadStripe } from "@stripe/stripe-js";
import { errorToast } from "../../Utils/index";
import api from "../../Redux/Actions/axiosInstance";

let Once = true;

const UpgradePackage = () => {
  const [loading, setLoading] = useState(false);
  const packagesInfo = useSelector((state) => state.packages.adminPackages);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const { data, isInvoked } = useSelector((state) => state.stripe);
  const { packagesData, isPackageInvoked } = useSelector(
    (state) => state.packages
  );

  async function handleUpgrade(id, name, price) {
    console.log("publicKey", data);
    try {
      if (!data?.publicKey) {
        return;
      }
      setLoading(true);
      // admin public key here
      const stripe = await loadStripe(data?.publicKey);
      const body = {
        packageName: name,
        id: id,
        price: price,
        userId: userData._id,
        role: "Client",
        paymentType: "package",
      };
      localStorage.setItem("programBody", JSON.stringify(body));
      const response = await api.post("/createPaymentIntent", body);
      localStorage.setItem("sessionId", response?.data?.sessionId);
      const result = stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });
    } catch (error) {
      console.log(error);
      errorToast("Something went wrong");
    } finally {
      setLoading(false);
    }
  }

  async function handleFreeUpgrade(packageId) {
    setLoading(true);
    subscribeToPackage({ packageId: packageId })
      .then((res) => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        errorToast("Something went wrong");
        setLoading(false);
      });
  }

  function getCoachStripeKeys() {
    if (!isInvoked) {
      setLoading(true);
      dispatch(getStripeKey(userData.clientInfo.coachId)).then(() => {
        setLoading(false);
      });
    }
  }

  async function getCoachAllPackages() {
    if (!isPackageInvoked) {
      setLoading(true);
      dispatch(getCoachPackages(userData.clientInfo.coachId)).then(() => {
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      setLoading(true);
      getCoachStripeKeys();
      getCoachAllPackages();
    }
  }, []);

  return (
    <>
      <HeaderData text="Upgrade Package" />
      {loading ? (
        <Loader />
      ) : (
        <section>
          {packagesData?.length ? (
            <CardWrapper extraClasses={"!items-stretch !justify-start"}>
              {packagesData.map((packageData, index) => {
                return (
                  <PackageCard
                    key={index}
                    packageName={packageData.name}
                    description={packageData.description}
                    price={
                      packageData.packageType === "Sell"
                        ? `$ ${packageData?.amount}`
                        : "Free"
                    }
                    subscriptionTime={
                      packageData.duration.durationType.toLowerCase() === "year"
                        ? "1 Year"
                        : `${packageData.duration.duration} month`
                    }
                    susbcriptionDetail={packageData.included}
                    self={false}
                    isActive={
                      userData.clientInfo?.Package &&
                      userData.clientInfo.Package.packageId === packageData?._id
                    }
                    packageStatus={true}
                    onHandleSubscribe={() => {
                      console.log("Package Data ==>", packageData);
                      if (packageData?.packageType === "Sell") {
                        handleUpgrade(
                          packageData._id,
                          packageData.name,
                          packageData.amount
                        );
                      } else {
                        handleFreeUpgrade(packageData._id);
                      }
                    }}
                  />
                );
              })}
            </CardWrapper>
          ) : (
            <p>
              We're sorry for the inconvenience! It seems that your coach hasn't
              added any packages yet. Your interest is highly valued, and we
              appreciate your patience. Please check back shortly, and we'll
              have exciting packages ready for you to explore!
            </p>
          )}
        </section>
      )}
    </>
  );
};

export default UpgradePackage;
