import React, { useContext, useState } from "react";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import CheckBoxLarge from "../Input/CheckBoxLarge";
import FooterButtons from "./FooterButtons";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import FormWrapper from "./FormWrapper";
import Errortxt from "./Errortxt";

const Step19 = ({ handleNext, handleBack }) => {
  const { step19Data, setStep19Data } = useContext(SmartPlanContext);
  const [error, setError] = useState(false);

  function handleChange(e) {
    setError(false);
    setStep19Data(e.target.value);
  }

  function submitStep() {
    if (!step19Data) {
      setError(true);
      return;
    }

    handleNext();
  }
  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Do you have any debts?" />
        <div className="py-4">
          <InputWrapper customClass={"!gap-6 !justify-start"}>
            <CheckBoxLarge
              icon={<MdCancel />}
              iconStyle={"text-red-400"}
              id={"no"}
              value="no"
              name="debt"
              selected={step19Data === "no"}
              onChange={handleChange}
              text="No"
            />
            <CheckBoxLarge
              icon={<FaCheckCircle />}
              id={"yes"}
              value="yes"
              name="debt"
              selected={step19Data === "yes"}
              onChange={handleChange}
              iconStyle={"text-green-400"}
              text="Yes"
            />
          </InputWrapper>
          {error && <Errortxt />}

          <FooterButtons onNext={submitStep} onBack={handleBack} />
        </div>
      </FormWrapper>
      {/* <StepImage image={stepImage} /> */}
    </div>
  );
};

export default Step19;
