import React, { useContext, useState } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import FooterButtons from "./FooterButtons";
import smartPlanImage from "../../Assets/sm-2.png";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import FormWrapper from "./FormWrapper";
import { useFormik } from "formik";
import { step26Schema } from "../../Schema";
import { structureAddSmartPlan } from "../../Utils/smartPlanGenerate";
import InputWrapper from "../Wrapper/InputWrapper";
import SelectBox from "../Input/SelectBox";
import { generateYears } from "../../Data/InputData";
import { useDispatch } from "react-redux";
import { createSmartPlan, updateSmartPlan } from "../../Redux/Actions";
import Loader from "../Loading/Loader";

const Step26 = ({ handleBack, isUpdate }) => {
  const { step26Data, setStep26Data } = useContext(SmartPlanContext);
  const data = useContext(SmartPlanContext);
  const [loading, setLoading] = useState(false);
  const initialState = step26Data;

  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: step26Schema,
      onSubmit: (values) => {
        setLoading(true);
        setStep26Data(values);
        const structuredData = structureAddSmartPlan(data, values);
        if (isUpdate) {
          updateSmartPlan(structuredData).then(() => {
            window.location.reload();
          });
        } else {
          createSmartPlan(structuredData).then(() => {
            window.location.reload();
          });
        }
      },
    });

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  // Format the max date to be compatible with HTML date input format (YYYY-MM-DD)
  const maxDateString = maxDate.toISOString().split("T")[0];

  return loading ? (
    <Loader />
  ) : (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Tell us a little about yourself" />

        <div className="py-4">
          <InputWrapper customClass={"!items-stretch"}>
            <SelectBox
              multiStyle={"!mt-0 flex-grow"}
              placeholder="Select Type"
              customClasses={"!py-[9px] bg-white !text-black "}
              label="Retirement Age"
              id="year"
              options={generateYears(18, 130)}
              name="personalInfo.retirementAge"
              value={values.personalInfo.retirementAge}
              onchange={handleChange}
              onBlur={handleBlur}
              error={errors?.personalInfo?.retirementAge}
              touch={touched?.personalInfo?.retirementAge}
            />
          </InputWrapper>
          <InputWrapper>
            <div className={`flex-grow basis-52`}>
              <label
                htmlFor="dob"
                className="block mb-1 pl-[2px] font-medium text-[14px] capitalize"
              >
                Date of birth
              </label>
              <input
                type="date"
                name="personalInfo.dob"
                id="dob"
                max={maxDateString}
                value={values.personalInfo.dob}
                className="border border-gray-300 outline-none text-gray-900 sm:text-sm rounded-md focus:border-gray-800 block w-full px-2.5 py-2 "
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.personalInfo &&
                touched.personalInfo.dob &&
                errors.personalInfo &&
                errors.personalInfo.dob ? (
                <p className="text-[14px] text-red-600">
                  {errors.personalInfo.dob}
                </p>
              ) : null}
            </div>
          </InputWrapper>

          <FooterButtons
            onNext={handleSubmit}
            onBack={handleBack}
            isLast={true}
          />
        </div>
      </FormWrapper>
      <StepImage image={smartPlanImage} />
    </div>
  );
};

export default Step26;
