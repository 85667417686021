import React from "react";
import HeaderData from "../../components/Header/HeaderData";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import FluidCard from "../../components/Card/FluidCard";

const Saving = () => {
  return (
    <>
      <HeaderData text="Savings" />
      <CardWrapper>
        <FluidCard
          withGraph={false}
          withDropdown={false}
          text="Total Savings"
          value="$ 300M"
        />
        <FluidCard
          withGraph={false}
          withDropdown={false}
          text="Emergency"
          value="3"
        />
        <FluidCard
          withGraph={false}
          withDropdown={false}
          text="Funds"
          value="$ 12K"
        />
        <FluidCard
          withGraph={false}
          withDropdown={false}
          text="Goals"
          value="5"
        />
      </CardWrapper>
    </>
  );
};

export default Saving;
