import React from "react";

const TypoPrimary = ({ text, customClasses }) => {
  return (
    <h5 className={`satoshi-500 text-[--primary] text-[23px] ${customClasses}`}>
      {text}
    </h5>
  );
};

export default TypoPrimary;
