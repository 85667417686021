import React, { useEffect, useState } from "react";
import HeaderData from "../../components/Header/HeaderData";
import CardWrapper from "../../components/Wrapper/CardWrapper";
import MediaCard from "../../components/Card/MediaCard";
import MediaCardHeader from "../../components/Card/MediaCardHeader";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loading/Loader";
import { getAssignedProgram } from "../../Redux/Actions";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
let Once = true;

const Programs = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.programs.data);

  useEffect(() => {
    if (Once) {
      Once = false;

      if (isEmpty(programData)) {
        setLoading(true);
        dispatch(getAssignedProgram()).then(() => {
          setLoading(false);
        });
      }
    }
  }, []);

  return (
    <>
      <HeaderData text="Subscribed Courses" />
      {loading ? (
        <Loader />
      ) : programData?.length ? (
        <CardWrapper extraClasses="!justify-start !item-center !gap-3">
          {programData?.map((programInfo, index) => {
            return (
              <MediaCard key={index}>
                <MediaCardHeader
                  image={programInfo?.photo?.startsWith("https://") ? programInfo?.photo : programInfo?.photo !== "" ? process.env.REACT_APP_IMAGE_URL.concat(programInfo?.photo) : null}
                  text={programInfo?.title}
                />
                <div className="px-2">
                  <div className="w-full pl-[2px] text-[10px] sm:text-[12px] h-[110px] overflow-hidden md:text-[14px] text-[--gray-dark] pb-3">
                    {parse(parse(programInfo?.description))}
                  </div>
                </div>
                <div className="w-full text-right pb-2 pt-1 pr-4">
                  <Link
                    to={`/program-detail/${programInfo?._id}`}
                    className="text-[--primary] text-[13px] border px-6 py-1 transition-all ease-in-out duration-500 hover:text-white hover:bg-[--primary] border-[--primary] satoshi-500"
                  >
                    View Detail
                  </Link>
                </div>
              </MediaCard>
            );
          })}
        </CardWrapper>
      ) : (
        <p>You don't have any program</p>
      )}
    </>
  );
};

export default Programs;
