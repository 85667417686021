import React, { useContext, useState } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import TypoGray from "./TypoGray";
import InputWrapper from "../Wrapper/InputWrapper";
import CheckBoxLarge from "../Input/CheckBoxLarge";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import FooterButtons from "./FooterButtons";
import stepImage from "../../Assets/sm-15.png";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import FormWrapper from "./FormWrapper";
import Errortxt from "./Errortxt";

const Step15 = ({ handleNext, handleBack }) => {
  const { step15Data, setStep15Data } = useContext(SmartPlanContext);
  const [error, setError] = useState(false);

  function handleChange(e) {
    setError(false);
    setStep15Data(e.target.value);
  }

  function submitStep() {
    if (!step15Data) {
      setError(true);
      return;
    }

    handleNext();
  }

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoGray
          customClasses={"max-w-[80%] mt-2 !uppercase  md:text-[16px]"}
          text="Are you interested in discovering how to buy your own property?"
        />
        <TypoPrimary text="Would you like to find out how to buy your own property?" />
        <div className="py-4">
          <InputWrapper customClass={"!gap-6"}>
            <CheckBoxLarge
              icon={<MdCancel />}
              iconStyle={"text-red-400"}
              text="No, I don't"
              id={"no"}
              value="no"
              name="property"
              selected={step15Data === "no"}
              onChange={handleChange}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
            />
            <CheckBoxLarge
              icon={<FaCheckCircle />}
              iconStyle={"text-green-400"}
              text="Yes, I want "
              id={"yes"}
              value="yes"
              name="property"
              selected={step15Data === "yes"}
              onChange={handleChange}
              customClasses={
                "hover:bg-gray-100 transition-all ease-in-out duration-300"
              }
            />
          </InputWrapper>
          {error && <Errortxt />}

          <FooterButtons onNext={submitStep} onBack={handleBack} />
        </div>
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step15;
