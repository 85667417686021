import React, { useContext, useEffect } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import FooterButtons from "./FooterButtons";
import SelectBox from "../Input/SelectBox";
import stepImage from "../../Assets/sm-4.png";
import { frequency } from "../../Data/InputData";
import InputRequired from "../Input/InputRequired";
import ButtonIcon from "../Buttons/ButtonIcon";
import { IoMdAdd } from "react-icons/io";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import FormWrapper from "./FormWrapper";
import { useFormik } from "formik";
import { step4Schema } from "../../Schema";

const Step4 = ({ handleNext, handleBack, direction }) => {
  const { step1Data, step3Data, step4Data, setStep4Data } =
    useContext(SmartPlanContext);

  const initialState = step4Data;

  const { values, handleChange, handleBlur, errors, touched, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: step4Schema,
      onSubmit: (values) => {
        setStep4Data(values);
        handleNext();
      },
    });

  function handleAdditionalIncome() {
    values.additionalIncome = true;
    handleSubmit();
  }

  useEffect(() => {
    if (step3Data === "no") {
      setStep4Data({
        companyName: null,
        incomeAmount: null,
        frequency: null,
        additionalIncome: null,
      });
      if (direction === "next") handleNext();
      else handleBack();
    }
  }, []);

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary text="Tell Us About Your Net Income" />
        <div className="py-4">
          <InputWrapper>
            <InputRequired
              label="Company Name"
              placeholder="Company name here"
              name="companyName"
              value={values.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
              touch={touched.companyName}
              error={errors.companyName}
            />
          </InputWrapper>
          <InputWrapper customClass={"!items-stretch"}>
            <InputRequired
              type="number"
              label="Income Amount"
              placeholder="Amount here"
              name="incomeAmount"
              value={values.incomeAmount}
              onChange={handleChange}
              onBlur={handleBlur}
              touch={touched.incomeAmount}
              error={errors.incomeAmount}
            />
          </InputWrapper>
          <InputWrapper customClass={"!items-stretch"}>
            <SelectBox
              multiStyle={"!mt-0 flex-grow"}
              placeholder="Frequency"
              customClasses={"!py-[9px] bg-white !text-black"}
              label="Frequency"
              id="year"
              name="frequency"
              options={frequency}
              value={values.frequency}
              onchange={handleChange}
              onBlur={handleBlur}
              touch={touched.frequency}
              error={errors.frequency}
            />
          </InputWrapper>

          <div className="w-full flex justify-center items-center mt-4">
            <ButtonIcon
              onclick={handleAdditionalIncome}
              icon={<IoMdAdd />}
              text="Additional Income"
            />
          </div>

          <FooterButtons onNext={handleSubmit} onBack={handleBack} />
        </div>
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step4;
