import React, { useContext } from "react";
import StepImage from "./StepImage";
import TypoPrimary from "./TypoPrimary";
import InputWrapper from "../Wrapper/InputWrapper";
import FooterButtons from "./FooterButtons";
import stepImage from "../../Assets/sm-12.png";
import InputRequired from "../Input/InputRequired";
import TypoGray from "./TypoGray";
import FormWrapper from "./FormWrapper";
import { useFormik } from "formik";
import { SmartPlanContext } from "../../Pages/SmartPlan/SmartPlan";
import { step24Schema } from "../../Schema";

const Step24 = ({ handleNext, handleBack }) => {
  const { step24Data, setStep24Data } = useContext(SmartPlanContext);

  const initialState = step24Data;

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialState,
      validationSchema: step24Schema,
      onSubmit: (values) => {
        setStep24Data(values);
        handleNext();
      },
    });

  return (
    <div className="flex justify-between items-stretch gap-4 ">
      <FormWrapper>
        <TypoPrimary customClasses={"!mt-4"} text="Expenses" />
        <TypoGray
          customClasses={"max-w-[80%]  md:text-[16px]"}
          text="Give us an Average of your Total Monthly Expense"
        />

        <div className="py-4">
          <InputWrapper>
            <InputRequired
              label="Avg Monthly Expense"
              placeholder="$ 0"
              type="number"
              name="avgMonthlyExpense"
              value={values.avgMonthlyExpense}
              error={errors.avgMonthlyExpense}
              touch={touched.avgMonthlyExpense}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </InputWrapper>
          <FooterButtons onNext={handleSubmit} onBack={handleBack} />
        </div>
      </FormWrapper>
      <StepImage image={stepImage} />
    </div>
  );
};

export default Step24;
