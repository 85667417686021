import React, { useState } from "react";
import Modal from "./Modal";
import ButtonLoading from "../Buttons/ButtonLoading";
import ButtonSmall from "../Buttons/ButtonSmall";
import { useDispatch } from "react-redux";
import { deleteSmartPlan } from "../../Redux/Actions";

const DeleteSmartPlan = ({ open, handleModal, data }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  function handleDelete() {
    setLoading(true);
    dispatch(deleteSmartPlan(data)).then(() => {
      setLoading(false);
      handleModal();
    });
  }

  return (
    <Modal headerText="Delete Smart Plan" open={open} onclick={handleModal}>
      <div className="w-full text-center">
        <h4 className="satoshi-700 w-[70%] mx-auto text-red-500">
          Deleting the smart plan will also delete the financial report. Do you
          want to delete this smart plan?
        </h4>
      </div>
      <div className="flex justify-center items-center gap-4 pt-8">
        {loading ? (
          <ButtonLoading />
        ) : (
          <>
            <ButtonSmall
              type="button"
              text="Cancel"
              customClasses={"!w-1/2"}
              onClick={handleModal}
            />
            <ButtonSmall
              onClick={handleDelete}
              type="button"
              text="Delete"
              customClasses={"!w-1/2 !border-red-700 !bg-red-700"}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteSmartPlan;
