import { RouterProvider } from "react-router-dom";
import Routes from "./Routes";
import { useDispatch, useSelector } from "react-redux";
import { checkLogin } from "./Redux/Actions";
import Loader from "./components/Loading/Loader";
import { useEffect, useState } from "react";
import { useSocket } from "./context/socketContext";
import UnSeenGoal from "./components/Modal/UnSeenGoalModal";

function App() {
  const [loading, setLoading] = useState(false);
  const clientData = useSelector((state) => state.auth.userData);
  const socket = useSocket();
  const dispatch = useDispatch();
  const [goalsSeenModal, setGoalSeenModal] = useState({
    status: false,
    count: 0,
  });
  useEffect(() => {
    setLoading(true);
    dispatch(checkLogin())
      .then((res) => {
        if (socket.connected) {
          const data = {
            userId: res.data._id,
            socketId: socket.id,
          };
          socket.emit("create_connection", data);
        }
        if (res?.data?.unseenGoals > 0) {
          setGoalSeenModal({
            status: true,
            count: res.data.unseenGoals,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        dispatch({ type: "LOGOUT" });
        setLoading(false);
      });
  }, []);

  if (clientData && socket.connected) {
    window.addEventListener("load", () => {
      socket.emit("disconnectEvent", clientData._id);
    });
  }
  return (
    <>
      {loading ? (
        <section className="w-screen h-screen flex justify-center items-center">
          <Loader />
        </section>
      ) : (
        <>
          {goalsSeenModal.status && (
            <UnSeenGoal
              open={goalsSeenModal.status}
              count={goalsSeenModal.count}
              handleClose={() => {
                setGoalSeenModal({ status: false, count: 0 });
              }}
            />
          )}
          <RouterProvider router={Routes} />
        </>
      )}
    </>
  );
}

export default App;
