import React, { useEffect, useState } from "react";
import Loader from "../../components/Loading/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getBudgetInfo, getCustomerBudget } from "../../Redux/Actions";
import BudgetForm from "../../Screens/BudgetForm";
import BudgetCharts from "../../Screens/BudgetCharts";

let Once = true;
const Budget = () => {
  const [openBudgetForm, setOpenBudgetForm] = useState(false);
  const { isInvoked } = useSelector((state) => state.budget);
  const [loading, setLoading] = useState(false);
  const [btnVisible, setBtnVisible] = useState(true);
  const dispatch = useDispatch();

  function toggleBudgetForm() {
    setOpenBudgetForm(!openBudgetForm);
  }

  useEffect(() => {
    if (Once) {
      Once = false;
      if (!isInvoked) {
        setBtnVisible(false);
        setLoading(true);
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;
        dispatch(getBudgetInfo(currentMonth, currentYear)).then(() => {
          setLoading(false);
          dispatch(getCustomerBudget()).then(() => {
            setBtnVisible(true);
          });
        });
      }
    }
  }, []);

  return loading ? (
    <Loader />
  ) : openBudgetForm ? (
    <BudgetForm closeForm={toggleBudgetForm} />
  ) : (
    <>
      <BudgetCharts btnVisiblity={btnVisible} onClick={toggleBudgetForm} />
    </>
  );
};

export default Budget;
