const initialState = {
  data: [],
  isInvoked: false,
};

const accountReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_ACCOUNTS":
      return {
        ...state,
        data: payload.data,
        isInvoked: true,
      };
    case "ADD_ACCOUNT":
      return {
        ...state,
        data: [...state.data, payload.data],
      };
    case "DELETE_ACCOUNTS":
      return {
        ...state,
        data: state.data.filter((item) => item.id !== payload),
      };
    default:
      return state;
  }
};

export default accountReducer;
